@use 'style/variables';

.not-found {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: variables.$gray700;
  font-size: 2rem;
}
