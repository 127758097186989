@use 'style/variables';
@use 'style/zindex';

.context-menu {
  z-index: zindex.$zIndexFocus;
  .icon,
  .lp-icon {
    margin-right: 0.75rem;
    width: 1rem;
  }

  .lp-icon {
    margin-right: 1rem;
  }

  &--item-panel {
    z-index: zindex.$zIndexItemPanelModal;
  }
}

.ui.dropdown .menu .ellipsis-action-dropdown--disabled.item {
  opacity: 0.4;
  cursor: default;
}

.ui.dropdown.upward .menu.ellipsis-action-dropdown {
  flex-direction: column-reverse !important;
}

.ui.dropdown .menu.ellipsis-action-dropdown {
  position: static !important;
  display: flex !important;
  flex-direction: column;

  .item {
    padding: 0.5rem 1rem 0.5rem 0.5rem !important;
  }

  .item-link-button {
    padding: 0;
    background: none;
    height: 100%;
    width: 100%;
    color: variables.$slate700;
    font-size: 1rem;
    text-align: left;
  }
}

.package_to_template_modal {
  &.ui.modal {
    width: 400px !important;
    .actions {
      display: flex;
      justify-content: space-between;
      button,
      .button {
        margin: 0;
      }
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    text-align: center;
    p {
      margin: 0.5rem 0;
    }
  }

  &--error,
  &--error.ui.modal > .header {
    color: variables.$red500;
  }
}
