.intake {
  &__information_message_modal {
    &.ui.modal {
      .actions {
        text-align: center;
      }
    }

    &-message {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}
