@use 'style/mixins';
@use 'style/variables';

.base-v2-widget {
  .v2-widget-linked-note {
    .base-v2-widget__body {
      @include mixins.readonlyNotesScss();
      overflow: hidden;
      box-shadow: none;
      border: none;
    }
  }

  .v2-widget-linked-note--not-configured {
    color: variables.$gray600;
  }
}
