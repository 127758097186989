@use 'style/mixins';
@use 'style/variables';

.upgrade-page-feature-trial {
  &__banner {
    background: variables.$slate200;
    color: variables.$gray700;
    height: min-content;
    padding: 1rem;
    row-gap: 0.5rem;
    border-radius: 3px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 15rem;
    grid-template-areas: 'titleText bannerActions' 'descriptionText bannerActions';
  }

  &__banner-title-text {
    grid-area: titleText;
    @include mixins.textWeightSemiBold();
    font-size: 1.2rem;
  }

  &__banner-description-text {
    grid-area: descriptionText;
  }

  &__banner-actions {
    grid-area: bannerActions;
    justify-self: end;
    align-self: center;
    display: grid;
    grid-auto-flow: column;
    column-gap: 1rem;

    &-starting {
      margin-right: 1rem;
    }

    &-button {
      &--slate.ui.button {
        margin: 0;
        @include mixins.buttonColorAndHoverColor(variables.$slate700, variables.$slate600);
      }

      &--orange.ui.button {
        margin: 0;
        @include mixins.buttonColorAndHoverColor(variables.$orange500, variables.$orange400);

        a {
          color: variables.$lpBrandWhite;
        }
      }
    }
  }
}
