@use 'style/mixins';
@use 'style/variables';

.logged-work-warning-text {
  color: variables.$red500;
}

.jira-project-warning-text {
  padding-top: 0.5rem;
}
