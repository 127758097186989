@use 'style/variables';

.invite-unsuccessful-header {
  display: flex;
  align-items: center;
  column-gap: 1rem;

  > h3 {
    margin: 0;
  }
}

.invite-error-modal {
  .invite-unsuccessful-header {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  li:last-of-type {
    margin-bottom: 1rem;
  }
}
