@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.multi-select-list-modal {
  width: 800px !important;

  .ui.table {
    margin: 0;
    border-collapse: collapse;
    border-bottom: none;

    &:first-of-type {
      border-top: none;
    }
  }

  &__popup {
    z-index: zindex.$zIndexItemPanelModalAction;
    max-width: 20rem;

    &-trigger {
      width: 100%;
      opacity: 0.3;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__search {
    width: 15rem;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    &-list {
      &-body {
        $self: &;
        height: 20rem;
        overflow-y: auto;
        border: 1px solid variables.$gray500;
        border-top: 0;

        .ui.table:last-of-type {
          border-bottom: 1px solid variables.$gray500;
        }

        &--empty {
          @extend #{$self};
          align-items: center;
          display: flex;
          font-size: 1.1rem;
          justify-content: center;
          user-select: none;
          vertical-align: middle;
        }
      }

      &-header {
        thead tr th {
          border: 1px solid variables.$gray500 !important;
        }
      }
    }

    &-table-option-icon {
      min-width: 1.5rem;
      margin-right: 0.2rem;
    }

    &-table-cell {
      display: flex;
      justify-content: space-between;
      height: 3rem;
      align-items: center;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;

      &--disabled {
        background-color: variables.$gray100;
      }

      &-icon {
        cursor: pointer;

        &--disabled {
          cursor: unset;
          opacity: 0.5;
        }
      }
    }

    &-table-cell-name {
      @include mixins.overflowEllipsis;
      width: 20rem;
    }
  }

  &__actions-section {
    display: flex;
    justify-content: space-between;

    &-add-all.ui.button,
    &-cancel.ui.button {
      margin-right: 0.5rem;
    }

    &-save.ui.button {
      margin-right: 0;
    }
  }
}

.ui.modal.multi-select-list-modal {
  > .header {
    font-size: 1rem !important;
  }
}

.ui.table.multi-select-list-modal {
  &__content {
    &-list {
      .ui.table {
        border-bottom: none;
      }

      thead tr:first-child > th:only-child {
        font-weight: normal;
        height: 3rem;
        border-radius: 0;
        border-bottom: 1px solid variables.$gray500;
      }
    }
  }
}
