@use 'style/mixins';
@use 'style/variables';

.pm-email-confirmation-error {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__email-sent {
    color: #202020;
    text-align: center;

    /* Headers/H3 */
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 40.25px */
    letter-spacing: -1.4px;
  }

  &__log-in-link {
    margin-top: 54px;
    text-align: center;

    color: variables.$tempoCharcoal;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.28px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;

    &:hover {
      color: variables.$tempoCharcoal;
      text-decoration-line: underline;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 260px;
    align-items: center;
    gap: 32px;
  }

  &__title {
    text-align: center;
    max-width: 700px;
  }

  &__submit-button {
    height: auto !important;
    width: 80%;
    background: variables.$tempoCharcoal !important;
    color: #fff !important;
    padding: 16px 32px !important;
    gap: 8px !important;
    font-size: 1.29rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important; /* 25.2px */
    letter-spacing: -0.72px !important;
  }
}
