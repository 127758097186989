@use 'style/mixins';
@use 'style/variables';

.premium-features-trial-buttons {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;

  &__premium-features-trial {
    &.ui.button {
      @include mixins.buttonColorAndHoverColor(variables.$slate700, variables.$slate600);
    }
  }

  &__see-plans {
    &.ui.button {
      @include mixins.buttonColorAndHoverColor(variables.$orange500, variables.$orange400);
    }
  }

  &__contact-us {
    &.ui.button {
      @include mixins.buttonColorAndHoverColor(variables.$orange500, variables.$orange400);

      a {
        color: variables.$lpBrandWhite;
      }
    }
  }

  a {
    color: variables.$lpBrandWhite;
  }
}

.dynamic-text-to-upgrade-page-button {
  &.ui.button {
    @include mixins.buttonColorAndHoverColor(variables.$slate700, variables.$slate600);
  }
}
