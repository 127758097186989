@use 'style/variables';

.board-hide-empty {
  padding: 0.5rem 1rem !important;

  &--active {
    background: variables.$slate700 !important;
    color: variables.$lpBrandWhite !important;
    border: 1px solid variables.$gray300 !important;
  }
}
