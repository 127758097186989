@use 'style/variables';

.lp-email-confirmation-banner {
  background-color: variables.$relightBlue;
  color: variables.$lpBrandWhite;

  &__button-link {
    color: variables.$lpBrandWhite;
    display: inline-block;
    position: relative;
    margin-left: 1rem;
    text-decoration: underline;

    &:hover {
      color: variables.$lpBrandWhite;
      text-decoration: none;
    }

    &::before {
      content: '|';
      left: -0.75rem;
      position: absolute;
    }
  }
}
