@use 'style/mixins';
@use 'style/variables';

.insights-widget {
  align-items: center;
  column-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
  row-gap: 2rem;

  &__expected {
    font-weight: normal;
  }

  &__insight-block {
    display: grid;
    grid-row-gap: 0.7rem;
    width: 16rem;

    &-progress-bars {
      width: 16rem;
      padding-right: 4rem;
      align-self: baseline;
      display: grid;
      grid-row-gap: 1rem;
    }
  }

  &__insight {
    display: grid;
    grid-template-columns: 3rem 1fr;
    grid-column-gap: 0.5rem;
    color: variables.$gray700;

    &-value {
      font-weight: bolder;
      line-height: 1.2rem;
    }

    &-name {
      font-size: variables.$small;
    }

    &-icon {
      justify-self: center;
      align-self: center;
      color: variables.$gray600;
      svg {
        font-size: 2.3rem;
      }
    }

    &--disabled {
      opacity: 0.3;
    }

    &--risk {
      color: variables.$red500;
      svg {
        color: variables.$red500;
      }
    }
  }

  &__navigation {
    display: grid;
    grid-column-gap: 0.3rem;
    grid-column: 2/4;
    grid-row-gap: 0.3rem;
    grid-template-columns: min-content min-content;
    grid-template-rows: min-content min-content;

    &-button {
      &.ui.button:not(.dropdown) {
        font-size: variables.$small;
        height: 3rem;
        width: 7rem;
        margin-right: 0rem;

        svg {
          margin-right: 0;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
      }
    }
  }
}
