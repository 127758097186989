@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

@mixin unavailable {
  height: 2px;
  width: 2px;
  margin-right: 6px;
  margin-left: 4px;
  border-radius: 0px;
}

.workload-date-range {
  display: flex;
  align-items: flex-end;
  column-gap: 2px;

  &__nub {
    display: inline-flex;
    width: 10px;
    border-radius: 0px;
    margin-right: 2px;
    cursor: pointer;
    align-items: center;
    text-align: center;

    &-hover {
      @include mixins.overflowEllipsis;
      max-width: 22rem;
      z-index: zindex.$zIndexHeader;
    }

    &-risk-icon {
      width: 100%;
    }

    &--work-scheduled {
      height: 1.2rem;
    }

    &--time-off {
      background-color: variables.$gray400;
      height: 1.2rem;
    }

    &--roll-up {
      background-color: variables.$gray700;
    }

    &--roll-up-rendered-as-late {
      background-color: variables.$red500;
    }

    &--empty {
      height: 2px;
      background-color: variables.$gray500;
    }

    .lp-nub &--unavailable {
      @include unavailable;
      background-color: variables.$gray500;
    }

    .lp-nub &--unavailable-with-late-parent {
      @include unavailable;
      background-color: variables.$red400;
    }

    &--empty-with-late-parent {
      height: 2px;
      background-color: variables.$red400;
    }

    &--work-scheduled-beyond-range {
      height: 1.2rem;

      &-icon {
        font-size: 1.2rem;
      }
    }

    &--zero-work-scheduled {
      height: 0.4rem;
    }
  }
}
