@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.sidebar-outpost {
  background-color: variables.$slate200;
  border-radius: 3px;
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: min-content min-content;
  margin: 1rem 1rem 2rem;
}

.outpost-header {
  &__name {
    @include mixins.overflowEllipsis();
    max-width: 16.5rem;
    padding-left: 0.7rem;
    padding-top: 0.4rem;

    &-icon {
      color: variables.$slate800;
      margin-right: 0.3rem;
    }

    &-link,
    &-link:hover {
      color: variables.$slate800;
    }
  }
}

.default-outpost-slider-image {
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: variables.$vinicioViolet;
  color: variables.$lpBrandWhite;
}

.outpost-slider {
  display: grid;
  padding: 0.4rem 0.7rem 0.7rem 0.7rem;
  color: variables.$slate700;
  grid-template-columns: max-content min-content;

  &__animation-container {
    overflow: hidden;
    border-radius: 3px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 2rem;
    row-gap: 2rem;

    &-icon {
      margin-left: 1rem;
      cursor: pointer;
      font-size: 1.5rem;
      color: variables.$slate800;

      &--disabled {
        opacity: 0.4;
      }
    }
  }
}
