@use 'layout';
@use 'mixins';
@use 'variables';
@use 'zindex';

.ui.page.modals.dimmer {
  background-color: rgba(0, 0, 0, 0.3);
  @include mixins.minSizeMediaQuery {
    padding: 0 !important;
    overflow: hidden;
    contain: strict;
    justify-content: center;
  }
}

.ui.progress,
.ui.tiny.progress,
.ui.small.progress,
.ui.medium.progress,
.ui.large.progress {
  margin-bottom: 0;
  margin-top: 0;
  background-color: white;
}

.lp-tile-wrap {
  overflow: auto;
}

.nowrap {
  white-space: nowrap;
}

.clickable {
  @include mixins.clickable;
}

.clickable-only-cursor {
  cursor: pointer;
}

.clickable-only-hover {
  cursor: pointer;

  &:hover {
    color: variables.$blue400 !important;
  }
}

.help-center-link {
  color: variables.$green600;
}

.evicted-user {
  @include mixins.evictedUser();
}

a.secondary {
  color: variables.$gray600 !important;
  font-style: italic;
}

.ui.button.small-button {
  padding: 0.5rem 1rem;
}

.ui.button {
  &:not(.icon) {
    .svg-inline--fa {
      @include mixins.marginRight('xs');
    }
  }
}

a[type='button'] {
  // fix button appearance in safari across the board
  // this does not affect other browsers because they've implemented the unprefixed version
  // https://developer.mozilla.org/en-US/docs/Web/CSS/appearance
  -webkit-appearance: unset;
}

html {
  overflow: hidden;
  contain: strict;
}

body {
  position: relative;
}

body > .ui.dropdown {
  div[role='alert'] {
    display: none;
  }
}

body {
  .buorg {
    position: absolute;
    width: auto;
    z-index: 111111;
    top: 1rem;
    right: 1rem;
    border: none;
    text-align: center;
    color: variables.$gray800;
    background-color: variables.$lpBrandWhite;
    font-size: 1.1rem;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.25);
    animation: 0.5s variables.$decelerateCubicBezier 0.5s slide-in forwards;
    border-radius: 0.3rem;
    opacity: 0;
  }

  .buorg-pad {
    padding: 9px;
    line-height: 1.7em;
  }

  .buorg-buttons {
    display: block;
    text-align: center;
  }

  #buorgig,
  #buorgul,
  #buorgpermanent {
    color: variables.$lpBrandWhite;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    padding: 1px 7px;
    border-radius: 4px;
    font-weight: normal;
    background: variables.$slate700;
    white-space: nowrap;
    margin: 4px 2px 0 2px;
    display: inline-block;
  }

  #buorgig {
    background-color: variables.$red400;
  }

  @media only screen and (max-width: 700px) {
    .buorg div {
      padding: 5px 12px 5px 9px;
      line-height: 1.3em;
    }
  }

  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateY(-25%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .buorg-fadeout {
    transition: visibility 0s 8.5s, opacity 8s ease-out 0.5s;
  }

  .buorg-icon {
    display: none;
  }
}
