@use 'style/mixins';
@use 'style/variables';

.add-organization-modal {
  .ui.celled.table {
    height: min-content;
  }

  &.ui.modal {
    .content {
      display: grid;
      row-gap: 1rem;
      height: min-content;
    }
  }

  &__project-desc-checkboxes {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-template-rows: 2rem 2rem 2rem;
    column-gap: 1rem;
    margin-bottom: 1rem;

    .field {
      .ui.checkbox label {
        padding-left: 1.5rem;
      }
    }
  }
}
