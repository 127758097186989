@use 'style/mixins';
@use 'style/variables';

.manage-account__pending-changes {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: min-content min-content;
  row-gap: 0.75rem;
  grid-template-areas: 'renewalDate cancel' 'changedTo cancel';
  background: variables.$red400;
  color: variables.$lpBrandWhite;
  padding: 0.75rem;
  margin-bottom: 0.5rem;

  &-canceled {
    background: variables.$slate500;
  }

  &__renewal-date {
    font-weight: variables.$weightSemiBold;
    grid-area: renewalDate;
  }

  &__changed-to {
    grid-area: changedTo;
  }

  &__cancel,
  &__cancel-pending {
    height: min-content;
    align-items: center;
    font-weight: variables.$weightSemiBold;
    white-space: nowrap;
    grid-area: cancel;

    &:hover:not(.manage-account__pending-changes__cancel-pending) {
      cursor: pointer;
      text-decoration: underline;
    }

    &-icon {
      @include mixins.marginRight('xs');
    }
  }
}
