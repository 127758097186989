@use 'style/mixins';

.slack-integration-administration {
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  padding: 1rem;

  &__avatar-row {
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__action-button,
  .ui.small#{&}__action-button {
    @include mixins.smallUiButtonDefaultBackgroundColor;
    margin: 0 0 0 1rem;
    padding: 0;

    & + & {
      margin: 0 0 0 0.5rem;
    }
  }
}
