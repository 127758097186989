
.tracking-bounding-box {
  contain: size;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
}