@use 'style/mixins';
@use 'style/variables';

.single-sign-on-form {
  &.ui.form {
    max-width: 60rem;
  }

  &__step {
    padding: 1rem;
    border: 1px solid variables.$gray500;
  }

  &__label {
    height: 1.4rem;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &--larger-text {
      font-size: 1.4rem;
    }

    &-popup {
      background: variables.$lpBrandWhite;
      padding: 0.5rem 0.7rem;
      border: 1px solid variables.$gray200;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }

  &__issuer-url {
    margin-top: 2rem !important;
  }

  &__issuer-url,
  &__sign-on-url,
  &__logout-landing-url,
  &__certificate {
    margin-bottom: 2.5rem !important;
  }

  &__help-icon {
    margin-left: 0.5rem;
    color: variables.$gray600;
  }

  &__test-button.ui.button {
    width: 6rem;
  }

  &__next-step-button.ui.button {
    margin-top: 0.5rem;
  }

  &__two-column-grid {
    background-color: variables.$gray200;
    outline: 1px solid variables.$gray400;
    display: grid;
    padding: 0.5rem;
    grid-gap: 1rem;
    grid-template-columns: 12rem 1fr;
  }

  &__two-column-grid-column-one,
  &__two-column-grid-column-two {
    display: grid;
    grid-template-rows: auto;
    row-gap: 0.3rem;
  }

  &__two-column-grid-column-two {
    &-link {
      display: flex;
      overflow: hidden;
      justify-content: space-between;
    }

    &--ellipsis {
      width: inherit;
      align-self: center;
      @include mixins.overflowEllipsis;
    }
  }

  &__error-status.ui.label {
    margin-bottom: 1rem;
    margin-left: 0;
  }

  &__test-link--disabled {
    pointer-events: none;
    cursor: default;
  }

  &__success-icon {
    margin-left: 0.5rem;
    color: variables.$green500;
  }
}
