@use 'style/variables';

.base-v2-widget--extra-small-width .intake-widget-input {
  flex-direction: column;
  row-gap: 1rem;
}

.v2-widget-intake {
  .intake-widget-input {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;

    &__item-name {
      flex: 1 1 auto;
      min-width: 0;

      &.ui.input {
        > input {
          width: 100%;
        }

        &.disabled > input {
          background: variables.$gray400;

          &::placeholder {
            color: variables.$slate800;
          }
        }
      }

      &--in-design {
        &.ui.input.disabled > input {
          background: variables.$lpBrandWhite;
        }
      }
    }

    &__button {
      &.ui.button {
        margin: 0;
        width: 6rem;
      }

      &-content {
        display: flex;
        column-gap: 0.5rem;
      }
    }
  }
}
