@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.organization-directory {
  @include mixins.mainContentStyle;
  margin: 1rem;

  &__users-profile-table-checkbox {
    margin-right: 0.5rem;
    top: 0.25rem;
  }

  &__org-admins {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  &__workspaces {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.25rem;
  }

  .lp-navigation-buttons {
    max-width: 76rem !important;
  }

  &__workspaces-card-header {
    background-color: variables.$slate600;
    display: flex;
    height: 4.1rem;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 100;
    font-size: 2.2rem;
    text-transform: uppercase;

    span > span {
      margin-left: 0.7rem;
    }
  }

  &__workspaces-card-header--active {
    background-color: variables.$blue400;
  }

  &__workspaces-card-content.center.aligned.content {
    border-top: 0;
    padding: 0.5rem 0 0.5rem;
    text-transform: capitalize;
    color: variables.$slate800;
  }

  &__workspaces-card-content.description {
    margin-top: 1rem;
  }

  &__workspaces-card.ui.card {
    width: 17rem;
    height: 7.5rem;
    margin: 1rem 1rem 0 0 !important;
  }

  &__add-workspace {
    width: 17rem;
    height: 7.5rem !important;
  }

  &__add-workspace.ui.button {
    margin: 1rem 2rem 0 0;
  }

  &__admins-footer {
    padding: 1rem 0;
    color: variables.$gray600;
  }

  &__actions-section.ui.menu {
    display: flex;
    flex-wrap: nowrap;
    z-index: zindex.$zIndexMenu;
    margin-top: 0;
  }

  &__actions-section-add-user-button.ui.button {
    margin-left: 1rem !important;
    align-items: center;
    display: flex;
  }

  &__users-table.ui.table {
    margin-bottom: 0 !important;
  }

  &__users-table-spinner.ui.loader.text,
  &__admins-table-spinner.ui.loader.text,
  &__users-profile-spinner.ui.loader.text {
    margin-top: 5rem;
  }

  &__users-table-header,
  &__admins-table-header {
    th {
      background: inherit;
      @include mixins.stickyHeader;
    }
  }

  &__admins-top-controls.ui.menu {
    z-index: zindex.$zIndexMenu;
    position: relative;
    margin-top: 0;
  }

  &__users-table-area,
  &__admins-table-area {
    grid-area: users-table;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    max-width: mixins.calculateRemRounded(1000px);
    position: relative;
  }

  &__admins-table {
    overflow: auto;
  }

  &__users-table,
  &__admins-table,
  &__admins-top-controls,
  &__actions-section {
    max-width: mixins.calculateRemRounded(1000px);
    min-height: 1rem;
  }

  &__users-table.ui.table {
    margin-top: 0;
  }

  &__users-table.ui.table:last-child {
    margin-bottom: 1rem;
  }

  &__users-table.ui.table td,
  &__admins-table.ui.table td {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  &__users-profile-information-table-row,
  &__users-profile-information-table-email {
    color: variables.$gray700;
  }

  &__users-profile-information-avatar-its-me {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .ui.table &__users-profile-workspace-memberships-cell {
    display: inline-flex;
    min-width: 32.6rem;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    align-items: center;

    &-disconnected-or-not-found {
      color: variables.$lpRed;
    }
    &-breadcrumb {
      margin-left: 0.5rem;
      &:before {
        content: '[';
        padding-right: 0.25rem;
      }
      &:after {
        content: ']';
        padding-left: 0.25rem;
      }
    }
  }

  &__users-profile-workspace-memberships-name {
    max-width: 28rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }

  &__users-profile-workspace-memberships-name--disconnected {
    color: variables.$riskyRed;
  }

  &__users-profile-workspace-memberships-name--disconnected-meta {
    color: variables.$slate700;
  }

  &__users-profile-workspace-memberships-disconnect {
    cursor: pointer;
    margin-left: auto;
    color: variables.$slate500;
  }

  &__users-profile-workspace-memberships-tri-state-buttons,
  &__users-profile-workspace-memberships-tri-state-buttons.ui.button:not(.dropdown) {
    margin-left: auto;
    margin-right: 0;
    height: 2rem;
    min-width: 4.5rem;
    max-width: 9rem;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &__users-profile-workspace-memberships-disconnect--is-admin.ui.button:not(.dropdown) {
    background-color: variables.$gray300;
    cursor: unset;
  }

  &__users-profile-workspace-memberships-disconnect--is-admin.ui.button:not(.dropdown):hover {
    background-color: variables.$gray300;
  }

  &__users-profile-information-header.ui.header,
  &__users-profile-workspace-memberships-header.ui.header {
    font-size: variables.$infoTableHeaderFontSize;
    font-weight: variables.$infoTableHeaderFontWeight;
  }

  &__users-profile-workspace-memberships-header.ui.header {
    margin-top: 1rem;
  }

  &__users-profile-information-header-icon {
    @include mixins.marginRight('xs');
  }

  &__users-profile-information-header--evicted-user-alert {
    margin-left: 1rem;
    font-style: italic;
    font-weight: normal;
  }

  &__users-profile-information-table-input.ui.input {
    width: 100%;

    input {
      border: none;
      padding-left: 0.7rem;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;

      color: variables.$gray700;
      border: none;

      &:hover,
      &:focus {
        border: none;
      }
    }
  }

  .ui.celled.table &__users-profile-information-table-dropdown-cell {
    padding: 0;

    .item,
    .active.selected.item,
    .text,
    .ui.selection.dropdown {
      color: variables.$gray700;
      border: none;

      &:hover {
        border: none;
      }
    }
  }

  .ui.celled.table &__users-profile-information-table-input-cell {
    padding: 0;
  }

  &__users-profile-record-not-found {
    flex: 1;
  }
}
