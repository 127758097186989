$grey1: #f6f6f6;
$grey2: #e4e4e4;
$grey3: #757574;
$grey4: #dce2e6;
$grey5: #939392;

$blue1: #1896ef;
$blue2: rgba(48, 179, 255, 0.1);
$blue3: #0061b2;

$box-shadow: 0px 8px 16px 0px rgba(29, 29, 27, 0.14), 0px 0px 2px 0px rgba(29, 29, 27, 0.12);
$scenario-card-width: 434px;
$scenario-card-height: 600px;
