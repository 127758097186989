@use 'style/mixins';
@use 'style/variables';

.item-link {
  pointer-events: all;

  &__name {
    @include mixins.overflowEllipsis();

    &--assignment {
      color: variables.$orange500;
    }

    &--done-assignment {
      @include mixins.doneAssignment();
    }
  }

  &--disabled {
    pointer-events: none;
  }
}
