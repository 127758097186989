@use 'style/mixins';
@use 'style/variables';

$addWidgetModalPadding: 0.8rem;

.add-widget-modal {
  width: unset !important;

  &__content {
    display: grid;
    grid-template-columns: repeat(4, 300px);
    grid-column-gap: $addWidgetModalPadding;
    grid-row-gap: $addWidgetModalPadding;

    border: 1px solid variables.$gray300;
    padding: $addWidgetModalPadding;

    overflow: auto;
    max-height: 45rem;

    @include mixins.smallSizeMediaQuery {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.widget-preview {
  cursor: pointer;
  background-color: variables.$lpBrandWhite;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  border: 2px solid variables.$lpGray200;
  border-radius: 5px;
  &__name {
    font-weight: variables.$weightSemiBold;
  }
  &--selected {
    border: 2px solid variables.$slate400;
    box-shadow: 0 0 4px variables.$gray600;
  }
}
