@use 'style/mixins';

.lp-change__card-change-property-user {
  float: revert !important;
}

.lp-change__card-change-property-priority-rush {
  &.ui.label {
    font-size: 0.6rem;
  }
}

.lp-change__card-change-property-picklist-choice,
.lp-change__card-change-property-item-icon,
.lp-change__card-change-property-parent-icon,
.lp-change__card-change-property-priority-rush,
.lp-change__card-change-property-status-icon,
.lp-change__card-change-property-user,
.lp-change__card-change-property-color {
  @include mixins.marginRight('xs', !important);
}
