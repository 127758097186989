@use 'style/variables';

.ui.menu .dropdown.item .menu.lp-avatar-menu {
  min-width: 150px;
}

.lp-organization-top-controls {
  &__workspace-name {
    color: variables.$tempoLightGray;
    margin-top: 16px;
    display: block;
    font-size: 0.9rem;

    &:hover {
      color: variables.$tempoLightGray;
    }
  }
}

.ui.table.lp-organization-top-controls {
  td {
    padding: 10px 0;

    > button,
    .icon.button.compact {
      padding: 7px 0;
      margin: 0;

      &.lp-add-button,
      &.lp-search-button {
        min-width: 50px;
      }
    }
  }

  .lp-organization-top-controls__logo {
    padding-left: 1rem;
    text-align: left;

    a > img {
      width: 180px;
      height: 29px;
    }

    span {
      color: variables.$slate500;
      font-size: 12px;
    }
  }
  .lp-organization-top-controls__chevron {
    text-align: center;
    vertical-align: top;
  }
}

.ui.secondary.menu .item.lp-workspace-picker {
  transition: height calc(variables.$leftNavAnimationTime / 4) ease-in-out,
    opacity calc(variables.$leftNavAnimationTime / 2) ease-in-out,
    padding calc(variables.$leftNavAnimationTime / 4) ease-in-out;

  &.collapsed {
    height: 0;
    opacity: 0;
    overflow-y: hidden;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.ui.secondary.menu .item.lp-workspace-picker.collapsed {
  display: none;
}

.lp-workspace-picker .dropdown,
.lp-left-nav-links .item {
  text-overflow: ellipsis;
  white-space: nowrap;
}
