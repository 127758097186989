$zIndexBottom: -1;
$zIndexNone: 0;
$zIndexBase: 1;
$zIndexHeader: 10;
$zIndexFocus: 10;
$zIndexMenu: 50;
$zIndexAction: 100;
$zIndexHelperMenu: 110;
$zIndexItemPanelModal: 1000;
$zIndexItemPanelModalAction: 1100;
$zIndexItemPanelModalActionError: 1101;
