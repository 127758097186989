@use 'style/mixins';
@use 'style/variables';

.navigation-section {
  margin-top: 1rem;
  max-width: 60rem;

  &__header {
    &.ui.header {
      color: variables.$gray700;
      font-weight: normal;
    }
  }

  &__sub-header-text {
    margin: 0.5rem 0 0.5rem 0.5rem;
    color: variables.$gray600;
    font-size: variables.$baseFontSize;
  }
}

.navigation-section-groups,
.navigation-section-members {
  display: inline-flex;
  margin: 0;

  &__header {
    &.ui.header {
      margin-top: 0.8rem;
      color: variables.$gray700;
      font-weight: normal;
    }
    .nav-section-icon {
      color: variables.$slate700;
    }
  }
}
