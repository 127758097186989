@use 'style/variables';

.v2-widget-image {
  .base-v2-widget__body {
    width: unset;
  }
  .image-widget {
    &--empty {
      text-align: center;
      color: variables.$gray600;
    }

    &--is-design-layout {
      cursor: pointer;
    }
  }
}
