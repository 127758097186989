@use 'style/mixins';
@use 'style/variables';

.lp-search {
  @include mixins.mainContentStyle;
  overflow-x: hidden;
  padding: 1rem;

  &__top-controls {
    display: grid;
    grid-template-columns: 24rem 6rem 11rem;
    column-gap: 1rem;
    max-width: 1200px;
  }

  &__helpful-info {
    margin-top: 1.5rem;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 4rem auto;
    grid-template-rows: auto auto;

    &-icon {
      grid-row-start: 1;
      grid-row-end: span 2;
      grid-column-start: 1;
    }

    &-header {
      margin: 0;
      grid-row-start: 1;
      grid-column-start: 2;
    }

    &-text {
      grid-row-start: 2;
      grid-column-start: 2;
    }
  }

  &__search-input {
    width: 100%;
  }

  .ui.pointing.secondary.menu {
    gap: 2rem;
    max-width: 1200px;
    @include mixins.stickyHeader(-1rem);
    background: variables.$gray100;

    .item {
      margin: 0;
    }
  }

  &__tab.ui.segment.tab {
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    max-width: 1200px;
  }

  &__results {
    margin-top: 1rem;
    border: 1px solid variables.$gray300;
  }

  &__results-files,
  &__results-items {
    background: variables.$lpBrandWhite !important;

    td {
      padding-left: 1rem !important;
    }

    &-cell {
      &--count {
        margin-left: 0.5rem;
        margin-right: 0.75rem;
        font-size: 1.2rem;
        font-weight: 700;
        color: variables.$gray500;
      }
    }

    .divider {
      font-size: 1.1rem;
      font-weight: 600;
      color: variables.$gray700 !important;
      opacity: 1 !important;
    }
  }

  &__results-files {
    &-row {
      display: grid !important;
      grid-template-columns: repeat(5, auto) 1fr;
      column-gap: 0.5rem;
      padding-top: 0.76rem !important;
      padding-bottom: 0.76rem !important;
      align-items: center;
      justify-content: start;

      &--on {
        color: variables.$gray600;
      }

      &--menu {
        display: flex;
        justify-content: flex-end;
      }

      .item-link {
        display: flex;
        column-gap: 0.25rem;
      }
    }
  }

  &__results-row {
    background: variables.$lpBrandWhite !important;
    display: flex !important;
    align-items: center;
    column-gap: 0.3rem;
    padding-top: 0.76rem !important;
    padding-bottom: 0.76rem !important;

    td {
      padding-left: 1rem !important;
    }

    &-count {
      margin-left: 0.5rem;
      margin-right: 0.75rem;
      font-size: 1.2rem;
      font-weight: 700;
      color: variables.$gray500;
    }

    &-icons {
      align-items: center;
      display: flex;

      &-name-text {
        margin-left: 0.3rem;
      }
    }

    &-on {
      color: variables.$gray600;
    }

    .lp-item-icon {
      margin-right: 0.3rem;
    }
  }

  &__results-items {
    td {
      padding-left: 1rem !important;
    }

    &-row {
      display: grid !important;
      grid-template-columns: auto auto 1fr;
      column-gap: 0.5rem;
      padding-top: 0.76rem !important;
      padding-bottom: 0.76rem !important;
      align-items: center;

      &-icons {
        display: flex;
        column-gap: 0.5rem;
      }
    }

    &-cell {
      &--breadcrumb {
        &.ui.breadcrumb {
          line-height: normal;
        }
        display: grid !important;
        grid-auto-flow: column;
        grid-auto-columns: fit-content(100%);
        align-items: center;

        &-section {
          font-size: 1.1rem;
          font-weight: 500 !important;
          color: variables.$blue400;
          white-space: nowrap;
          align-self: flex-end;

          &--ancestor {
            color: variables.$gray600 !important;
            align-self: flex-end;
          }
        }

        &-section:last-child {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
