@use 'style/mixins';
@use 'style/variables';

.status-filter-grid {
  display: block !important;
}

.status-filter,
.selection-dropdown {
  &-grid {
    .status-filter__button {
      height: 2rem !important;
    }
  }

  &__button {
    width: 1.7rem;
    height: 2.4rem !important;
    margin-left: auto !important;
    padding: 0.2rem !important;
  }

  .menu.transition.visible {
    min-width: 10rem !important;

    .item {
      padding: 0.5rem !important;
    }

    .divider {
      margin: 0;
    }

    svg {
      color: variables.$gray700;
      @include mixins.marginRight('xs');
      width: 1rem;
    }

    .active.selected.item[aria-selected='true']:not(:hover) {
      background-color: variables.$lpBrandWhite;
      font-weight: normal;
    }
    .active.selected.item[aria-selected='true'] {
      font-weight: normal;
    }
  }
}
