@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.lp-add-edit-data-grid {
  &--restrict-size {
    max-height: 400px;
    .rdg {
      overflow-x: hidden;
    }
  }
  .rdg-row {
    &:hover {
      background-color: variables.$blue50;
    }

    .rdg-cell {
      .DayPickerInput-Overlay {
        line-height: 1.4rem;
      }
    }
  }
  &__dropdown.rdg-editor-container {
    overflow: visible !important;

    .ui.dropdown {
      width: 100%;
      min-width: 0 !important;
    }
  }

  &__assigned-to {
    padding: 0 8px;
    text-align: right;
  }

  &__checkbox {
    text-align: center;
  }

  &__name {
    &--unnamed {
      font-style: italic;
      color: variables.$gray500;
    }
  }

  &__assignment-name {
    display: grid;
    position: relative;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    column-gap: 0.3rem;

    .assignment-name {
      color: variables.$orange500;
    }
    .done-assignment-name {
      @include mixins.doneAssignment();
    }
  }

  &--disabled {
    span,
    svg {
      opacity: 0.4;
    }
  }

  .rdg {
    height: 100%;

    &-row {
      contain: none;
    }

    &-cell {
      &.invalid-cell {
        background: variables.$red100;
      }

      &.disabled-cell > span {
        opacity: 0.3;
      }

      &-dragged-over {
        background-color: variables.$tempoCellHoverHighlightColor;
      }

      .multi-value-field {
        &--empty {
          height: 100%;
        }
      }
    }

    /** editor cells **/
    &-editor-container {
      .DayPickerInput {
        width: 100%;

        input {
          max-width: 100%;
          width: 100%;
        }
      }

      &.rdg-cell.day-picker-cell {
        contain: size;
      }

      .ui.checkbox.edit-custom-field {
        &__checkbox {
          display: block;
          margin: 0.4rem auto;
        }
      }
    }
  }
}
