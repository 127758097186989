@use 'style/mixins';
@use 'style/variables';

.jira-project-modal {
  &__loader {
    &-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
    &.ui.loader::before {
      border-color: variables.$gray500 !important;
      color: variables.$gray500 !important;
    }
    &.ui.text.loader {
      color: variables.$gray700 !important;
    }
  }
  &__picklist {
    .ui.dropdown,
    &.ui.dropdown {
      &.error {
        outline: 1px solid variables.$red500;
        background: variables.$red50;

        .default.text {
          color: variables.$red500;
          opacity: 0.2;
        }
      }
    }
    &.error > .ui.dropdown {
      outline: 1px solid variables.$red500;
      background: variables.$red50;

      .default.text {
        color: variables.$red500;
        opacity: 0.2;
      }
    }
  }
  &__api-error {
    padding-bottom: 1rem;
  }
  &__tabs {
    background: variables.$gray100;
    height: 100%;
    min-height: mixins.calculateRemDeprecated(400px);
  }

  &__learn-more {
    position: absolute;
    bottom: 1rem;
    color: variables.$lpBrandWhite;
    gap: 0.5rem;

    &.ui.button {
      display: flex;
      align-items: center;
      background: transparent;
      font-weight: 700;

      &:hover,
      &:active,
      &:focus {
        background: transparent;
      }
    }
  }

  &__tab {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: normal;
    &-name {
      display: flex;
      align-items: center;
      gap: 1.25rem;
    }
    &-label {
      &-warning {
        background: variables.$orange500 !important;
      }
    }
  }

  $jiraProjectModal: &;
  &.ui.modal.tabbed-modal .ui.grid .four.wide.column .ui.fluid.vertical.menu {
    border-bottom: 1px solid variables.$slate600;
    .disabled {
      color: variables.$slate600 !important;
    }
  }
  &.ui.modal.tabbed-modal .ui.grid .four.wide.column {
    max-width: none;
  }
  &.ui.modal.tabbed-modal .ui.grid .stretched.twelve.wide.column {
    border-bottom: none;
  }
  & .header {
    display: grid !important;
    grid-template-columns: auto 1fr auto;

    .lp-svg {
      font-size: 1.25rem;
      margin-right: 0.5rem;
    }
  }
  #{$jiraProjectModal}__breadcrumb.ui.big {
    line-height: 2rem;
    @include mixins.overflowBreadcrumbGradient(variables.$gray100);
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding: 1rem;
    gap: 1rem;
    background: variables.$gray100;
    border-top: 1px solid variables.$gray500;
    border-right: 1px solid variables.$gray500;
    box-shadow: inset -1px 0px 3px 2px variables.$gray300;
    &-info {
      display: flex;
      flex-direction: column;
      &-title {
        &-container {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        &-popup-container {
          max-width: 30rem;
        }
        font-size: 15px;
        font-weight: variables.$weightBold;
        color: variables.$slate700;
      }
      &-helper {
        &-container {
          display: flex;
          gap: 2rem;
          justify-content: space-between;
          &-collapsible {
            background: variables.$gray200;
            padding: 0.5rem;
            border-bottom: 1px solid variables.$gray300;
            cursor: pointer;
            &-collapsed {
              border-bottom: none;
            }
            &-last {
              border-top: 1px solid variables.$gray300;
            }
          }
        }
        &-text {
          font-size: variables.$baseFontSize;
          font-weight: variables.$weightRegular;

          &-italic {
            font-style: italic;
            color: variables.$gray600;
          }
        }
        &-button {
          &-container {
            padding-top: 0.5rem;
          }
          &-red {
            background: variables.$red500 !important;
            color: variables.$lpBrandWhite !important;
          }
        }
      }
      &-credentials-error {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: variables.$red500;
        font-size: variables.$baseFontSize;
        font-weight: variables.$weightRegular;
      }
    }
    &-bordered-container {
      border: 1px solid variables.$gray300;
    }
    &-legend {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      &-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 12px;
      }
    }
    &-table {
      &-container {
        &-expanded {
          padding: 0 0.5rem 0.5rem 0.5rem;
        }
      }
      &--story-points-scheme {
        width: 43.25rem !important;
      }
      &--empty {
        border: none !important;
      }
      margin: 0 !important;
      border: 1px solid variables.$gray300 !important;
      &-action {
        &-button {
          margin: 0.5rem 0;
        }
      }
      &-title {
        color: variables.$slate700;
        font-weight: 400;
      }
      &-estimate-mapping {
        &-title {
          display: grid;
          grid-template-columns: 18rem auto;
          padding-top: 0.5rem;
          padding-bottom: 1rem;
          padding-left: 0.5rem;
          &-text {
            font-size: 1rem;
            font-weight: 600;
            color: variables.$slate700;
          }
        }
      }
      &-name {
        &-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &-multiple-rows {
          background: variables.$lpBrandWhite !important;
        }
        &-content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
      &-picklist {
        display: flex;
        align-items: center;
        .ui.search.dropdown > input.search {
          caret-color: transparent;
        }
        &-secondary {
          &-container {
            display: flex;
            align-items: center;
            gap: 1rem;
            &-column {
              gap: 0;
            }
          }
        }
        &-column {
          align-items: flex-start;
          flex-direction: column;
          gap: 0.25rem;
        }
        &-option {
          display: flex !important;
          align-items: center;
          gap: 0.5rem;
        }
        &-checkbox {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .ui.checkbox {
            display: block;
          }
        }
      }
    }
    &__welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: center;
      gap: 24px;
      color: variables.$gray900;

      &-title {
        font-weight: variables.$weightBold;
      }

      &-text {
        font-size: 1.75rem;
        color: variables.$gray700;
      }

      .lp-svg {
        height: 75px !important;
      }
    }

    &-estimate-mapping {
      display: grid;
      gap: 1rem;

      &-input {
        width: 15rem;
      }

      &-invalid-scheme-warning {
        margin: 0 !important;

        &-icon {
          margin-right: 1rem;
        }
        &-content {
          font-size: 1.1rem;
        }
      }

      &-editable-story-points {
        &__list {
          padding: 0.25rem 0.5rem;
          background: white !important;
          display: grid;
          grid-template-columns: 14rem auto;
          border-left: none !important;

          &-value {
            width: 15rem;

            &.error {
              input {
                background: variables.$red50 !important;
                border-color: variables.$riskyRed !important;
                color: variables.$riskyRed !important;
                .text {
                  color: variables.$riskyRed !important;
                }
              }
            }
          }

          &-high-low-effort {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            .ui.input {
              input {
                width: 9rem !important;
              }

              &.error {
                input {
                  background: variables.$red50;
                  border-color: variables.$riskyRed;
                  color: variables.$riskyRed;
                  .text {
                    color: variables.$riskyRed;
                  }
                }
              }
            }
          }

          .ui.input {
            margin-top: 0.1rem;
          }
        }
      }

      &-dropdown {
        display: flex;
        gap: 2rem;
        align-items: center;

        &-help-text {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          max-width: 18rem;
          padding: 0.5rem;
        }

        &-schemes-link {
          font-size: 12px;
          font-weight: 600;
        }

        .ui.selection.dropdown {
          padding-right: 30px;
          width: 18rem;
          background-color: variables.$gray200;
          border-color: variables.$gray200;
          height: 2rem;
          min-height: 2rem;
          &.error {
            outline: 1px solid variables.$red500;
            background: variables.$red50;

            .default.text {
              color: variables.$red500;
              opacity: 0.2;
            }
          }
          .text {
            display: block;
            padding-top: 0.1rem;
          }
          .icon {
            padding-top: 0.5rem !important;
          }
          .menu {
            max-height: 21rem;
          }
        }
      }

      &-story-point-scheme-name {
        &-popup {
          background: variables.$red50 !important;
          border-color: variables.$riskyRed !important;
          color: variables.$riskyRed !important;

          &.ui.right.center.popup::before {
            background: variables.$red50;
            box-shadow: -1px 1px 0px 0px variables.$riskyRed;
          }
        }

        .ui.input {
          width: 18rem;

          &.error {
            input {
              background: variables.$red50 !important;
              border-color: variables.$riskyRed !important;
              color: variables.$riskyRed !important;
              .text {
                color: variables.$riskyRed !important;
              }
            }
          }
        }
      }
    }

    &-sync-settings {
      &-warning-alert {
        .lp-svg {
          margin-right: 1.5rem;
        }
      }
      &-toggle-container {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      &-costcode {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        &-popup {
          max-width: 20rem;
        }
      }
      &-subfolder-organization {
        padding-left: 0 !important;
        padding-bottom: 0.75rem !important;

        &-help-text {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          max-width: 25rem;
          padding: 0.5rem;
        }

        &-option {
          display: flex !important;
          gap: 1rem !important;
          line-height: 1.4rem !important;
        }

        &-description-text {
          font-style: italic;
          color: variables.$slate500;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }

        &-example-modal {
          &-description {
            @include mixins.fontSize(15px);
            font-weight: normal;
          }
          &-images {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }

      &-push-setting {
        padding-top: 0.75em !important;
        padding-left: 0 !important;
        padding-bottom: 0.75rem !important;

        &-option {
          display: flex !important;
          gap: 1rem !important;
          line-height: 1.4rem !important;
        }

        &-description-text {
          font-style: italic;
          color: variables.$slate500;
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }
      }
    }

    &-summary {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 2rem;

      &-text {
        font-size: variables.$importantHeaderFontSize;
        font-weight: variables.$weightSemiBold;
        color: variables.$gray700;
      }
      &-info {
        font-size: variables.$baseFontSize;
        color: variables.$slate700;
      }
    }
    &-tag {
      background: variables.$slate100;
      border-radius: 4px;
      padding: 0.2rem 0.5rem;
      font-size: 12px;
      cursor: pointer;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-count {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      &-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: variables.$slate700;
        font-style: italic;
      }
    }
    &-actions {
      margin-left: auto;
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 1rem;

      &-close {
        color: variables.$slate700;
      }

      &-next,
      &-back {
        display: flex !important;
        gap: 0.5rem;
        align-items: center;
        color: variables.$lpBrandWhite !important;
        background-color: variables.$slate700 !important;

        &.svg {
          font-size: 0.8rem;
        }
      }
    }
  }
}
