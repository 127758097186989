.invitation-expired,
.invitation-already-accepted {
  font-size: 1.4rem;
  max-width: 40% !important;
  position: absolute;
  top: 10%;
  left: 40%;
  margin-top: 2rem;
}

.invitation-expired-or-revoked {
  max-width: 40rem;
  margin: 0 auto 2rem auto;
  text-align: center;

  &__wrapper {
    display: grid;
    grid-auto-rows: 1fr auto;
    height: 100%;
    overflow-y: auto;

    .lp-legal-terms-footer {
      margin-bottom: 1rem;
    }
  }

  &__content {
    align-self: center;
  }

  &__icon {
    font-size: 8rem;
  }

  &__text {
    font-size: 1.3rem;
  }
}
