@use 'style/variables';

.lp-favorites-list {
  height: 100%;
  width: 100% !important;
  padding-top: 0.25rem !important;

  &__header {
    margin-bottom: 15px;

    svg {
      position: relative;
      left: 5px;
    }

    span {
      position: absolute;
      left: 50px;
      padding-top: 3px;
    }
  }

  &__body {
    .item {
      padding: 0.4rem 1rem !important;
    }

    .favorite-item {
      padding: 5px 20px;
      width: 100%;
      margin-bottom: 0;
      border-radius: 0 !important;

      &__line {
        display: inline-block;
        height: 30px;
        border-left: 6px solid #d3d3d3;
        margin: 0 0 -1px 10px;
      }

      &__info {
        width: calc(100% - 25px);
        display: inline-block;
        padding-left: 14px;

        color: variables.$tempoLightGray;

        p:first-child {
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p:last-child {
          font-size: 12px;
          opacity: 0.9;
          font-weight: 100;
        }
      }
    }
  }

  .item {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    &:hover {
      background-color: variables.$gray100 !important;
    }

    &:not(.header) {
      text-overflow: ellipsis;
    }
  }
}
