@use 'style/mixins';
@use 'style/variables';

.lp-draggable-item {
  transition: background-color 200ms ease-in-out;

  &.dragging.active,
  &.dragging-active {
    background-color: var(--lp-draggable-item-hover-color) !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);

    .drag-handle {
      color: variables.$trueBlack;
    }
  }
  &.dragging-active {
    display: inline-table;
  }

  .drag-handle {
    transition: 200ms ease-in-out;
  }

  &:hover {
    background-color: var(--lp-draggable-item-hover-color);

    .drag-handle {
      color: variables.$trueBlack;
    }
  }
}

.lp-drag-and-drop-context {
  &__drag-active {
    .lp-action-ellipsis {
      opacity: 0 !important;
    }

    .lp-draggable-item {
      &:hover {
        background-color: variables.$lpBrandWhite !important;

        .drag-handle {
          color: variables.$gray400 !important;
        }
      }
    }
  }
}
