@use 'style/variables';

.lp-invitation {
  &__header-block &__sub-header.ui.header {
    font-style: normal;
  }

  &__tos-checkbox-row {
    width: 100% !important;
  }

  &__project-desc-checkboxes {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-template-rows: 2rem 2rem 2rem;
    column-gap: 1rem;
    margin-bottom: 1rem;

    .field {
      .ui.checkbox label {
        padding-left: 1.5rem;
      }
    }
  }

  .ui.form .field &__input-field-sub-label {
    font-style: italic;
    font-weight: variables.$weightRegular;
  }

  & .ui.disabled.input {
    opacity: 1;
  }

  &__input-field .ui.input {
    height: 2.6rem;
  }

  &__form.ui.form {
    width: 100%;
  }

  &__api-error {
    width: 100%;
  }
}
