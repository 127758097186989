@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

$collapsed-size: 65px;
$expanded-size: 275px;
$min-expanded-size: 13rem;

@keyframes notification-dot {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.lp-left-nav {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  background: variables.$lpBrandWhite;
  transition: all variables.$leftNavAnimationTime ease-in-out;
  width: $expanded-size;
  min-width: $min-expanded-size;
  overflow: hidden;

  & + .lp-main__content {
    width: calc(100% - #{$expanded-size});
  }

  &--collapsed {
    width: $collapsed-size !important;
    min-width: $collapsed-size;

    & + .lp-main__content {
      width: calc(100% - #{$collapsed-size});
    }

    &__pop-up {
      left: mixins.calculateRemDeprecated(50px) !important;
      top: mixins.calculateRemDeprecated(25px) !important;
    }

    .item .new-item {
      transform: scale(0.5, 0.5) translate(-8px, -6px);
    }
  }

  &__menu {
    &.ui.menu.inverted.secondary {
      width: 100%;
      margin-top: 0;

      .item {
        align-content: center;
        align-items: center;
        column-gap: 0.25rem;
        display: grid;
        grid-template-columns: 30px 1fr 30px;
        grid-template-rows: 1fr;
        margin-bottom: 0;
        padding: 0.5rem 1rem;

        &:hover {
          background-color: variables.$gray100;
        }
        &.active {
          background-color: variables.$slate200;
        }

        .icon {
          margin: 0 auto;
        }

        .menu-label {
          white-space: nowrap;
        }

        .icon,
        .menu-label {
          color: variables.$tempoCharcoal;
        }

        .new-item {
          animation: notification-dot calc(variables.$leftNavAnimationTime + 60ms) ease-in;
        }

        &.collection {
          padding-left: 3rem;
        }

        &.premium-features-trial,
        &.ultimate-trial,
        &.people,
        &.administration {
          .menu-label {
            align-items: center;
            column-gap: 0.5rem;
            display: grid;
            grid-auto-flow: column;
            justify-content: start;

            .ui.label {
              border-radius: 10px;
              padding-bottom: 0.25rem;
              padding-top: 0.29rem;
              text-align: center;
            }
          }
        }
        &.premium-features-trial,
        &.people,
        &.administration {
          .menu-label .ui.label {
            background-color: variables.$orange500;
            color: variables.$lpBrandWhite;
          }
        }
        &.ultimate-trial {
          .menu-label .ui.label {
            background-color: variables.$blue200;
            color: variables.$gray800;
          }
        }
      }
    }
  }

  &__favorites-list-container {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: sticky;
      top: 0;
      left: 0;
      height: 0;
      width: 100%;
      z-index: zindex.$zIndexBase;
      box-shadow: 0 0 5px 8px variables.$lpBrandWhite;
    }
  }

  .ui.vertical.menu {
    width: auto;

    &.padded-right {
      margin-right: 5px;
    }
  }
}
