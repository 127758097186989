@use 'style/mixins';
@use 'style/variables';

.feature-trial-banner-with-icon {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    'bannerIcon bannerTitle bannerActionsText'
    'bannerIcon bannerDescription bannerActionsButtons';
  padding: 1rem;
  row-gap: 0.5rem;
  column-gap: 1rem;
  border-radius: 3px;
  background: variables.$slate200;
  color: variables.$gray700;
  height: min-content;

  &__icon {
    grid-area: bannerIcon;
    @include mixins.textWeightSemiBold();
    font-size: 1.7rem;
    margin-top: 0.3rem;
  }

  &__title {
    grid-area: bannerTitle;
    @include mixins.textWeightSemiBold();
    font-size: 1.2rem;
    max-width: 30rem;
  }

  &__description {
    grid-area: bannerDescription;
    max-width: 30rem;
  }

  &__actions-text {
    grid-area: bannerActionsText;
  }

  &__actions-buttons {
    grid-area: bannerActionsButtons;
  }
}
