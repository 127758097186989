@use 'style/mixins';
@use 'style/variables';

.organization-directory__users-profile {
  &-content {
    padding-right: 1rem;
    overflow: auto;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: minmax(20rem, 65rem) 15.2rem;
    grid-auto-rows: max-content;

    .disconnected-user-warning {
      margin-bottom: 0.8rem;
    }
  }

  &-information-header {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span col2;
  }

  &-error {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span col2;
  }

  &-information-table {
    grid-row-start: 2;
    grid-column-start: 1;
    margin: 0 !important;
    .disconnected {
      @include mixins.evictedUser();
    }
  }

  &-membership-table {
    grid-row-start: 3;
    grid-column-start: 1;
    &-header {
      display: flex;
      &__error {
        font-weight: normal;
        margin-left: auto;
        color: variables.$gray700;
        font-style: italic;
      }
    }
    &--workspace-passports {
      grid-row-start: 4;
    }
  }

  &-information-avatar {
    &-section {
      padding: 1rem;
      background: variables.$lpBrandWhite;
      border: 1px solid rgba(variables.$tableBorder, 0.15);
      grid-row-start: 2;
      grid-column-start: 2;
      &-change-email-password {
        button.ui.button {
          margin: 1rem 0;
        }
      }
    }

    &-guest {
      height: 13rem;
      width: 13rem;

      &-button {
        text-align: center;
        margin-top: 0.5rem;
        .ui.button {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
