$nova-left-nav-width: 40px;

.nova-left-nav {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  align-items: center;
  gap: 1rem;
  padding: 12px;
  overflow: hidden;
  background: white;
  width: $nova-left-nav-width;

  &+.lp-main__content {
    width: calc(100% - #{$nova-left-nav-width});
  }
}
