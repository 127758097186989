@use 'variables';
@use 'zindex';

/* -----------------
Font Sizing
------------------*/
@function calculateRemDeprecated($size) {
  // @warn "Calculate Rem is deprecated in favor of a rounded version called calculateRemRounded";
  $remSize: calc($size / variables.$baseFontSize);
  @return #{$remSize}rem;
}

@function calculateRemRounded($size) {
  $remSize: calc($size / variables.$baseFontSize); // 1.42857...
  $roundedRemSize: calc(ceil($remSize * 10) / 10); // 1.5
  @return #{$roundedRemSize}rem; // 1.5rem
}

@mixin clickable {
  color: variables.$blue400 !important;
  cursor: pointer;
}

@mixin evictedUser {
  color: variables.$lpRed;
}

@mixin iconListViewSizing {
  font-size: 1.2rem !important;
  margin: 0 calculateRemDeprecated(4px);
}

@mixin smallUiButtonDefaultBackgroundColor {
  font-size: 1.1rem;
  margin-right: 0.5rem;
  width: 2.8rem;
  height: 2rem;
}

@mixin smallUiButton {
  @include smallUiButtonDefaultBackgroundColor;
  background: variables.$lpButtonColor;
}

@mixin mainContentStyle {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2rem;
  overflow: auto;
}

@mixin simplePageContent {
  display: flex;
  flex-direction: unset;
  height: unset;
  padding: 1rem 1rem 1rem 1rem;
  padding-bottom: unset;
  overflow: auto;
}

@mixin taskScheduleDirectiveButton {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  font-weight: 700;
  font-size: 1rem;
  width: 3.4rem;
}

@mixin taskIterationScheduleDirectiveButton {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  font-weight: 700;
  font-size: 1rem;
  width: 5rem;
}

@mixin fontSize($pxSize, $important: null) {
  font-size: calculateRemRounded($pxSize) $important; // convert px size to rem
}

@mixin overflowEllipsis($important: null) {
  overflow: hidden $important;
  white-space: nowrap $important;
  text-overflow: ellipsis $important;
}

@mixin linearGradientFadeOut($color) {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 90%, $color 100%);
}

@mixin overflowBreadcrumbGradient($color: rgba(255, 255, 255, 1)) {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  &:before {
    @include linearGradientFadeOut($color);
    content: ' ';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@mixin multiLineOverflow($lineCount: 10) {
  // todo, when css overflow module level 3 is certified and rolled out into browsers change this
  // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineCount;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  align-self: start;
}

@mixin cellWidthInRems($remSize, $important: null) {
  max-width: #{$remSize}rem $important;
  min-width: #{$remSize}rem $important;
  width: #{$remSize}rem $important;
}

@mixin doneAssignment() {
  font-style: italic;
  color: variables.$gray500;
}

@mixin cellWidthInPercent($percentageSize, $important: null) {
  max-width: $percentageSize $important;
  min-width: $percentageSize $important;
  width: $percentageSize $important;
}

@mixin cellHidden {
  display: none !important;
}
@mixin cellCollapsed {
  width: 20% !important;
  min-width: 25rem !important;
  max-width: 0 !important;
}
@mixin cellExpanded {
  width: 80% !important;
  min-width: 25rem !important;
  max-width: 0 !important;
}

@mixin scheduleHeaderDatesExpanded {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  span {
    flex-grow: 1;
    position: relative;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 100%;
      bottom: -60%;
      left: 50%;
      right: 50%;
      border-left: 1px solid variables.$slate700;
    }

    &:first-of-type {
      text-align: left;
      &:after {
        left: 0;
        right: auto;
      }
    }
    &:last-of-type {
      text-align: right;
      &:after {
        left: auto;
        right: 0;
      }
    }
  }
}

@mixin textWeightLight($important: null) {
  font-weight: lighter $important;
}

@mixin textWeightNormal($important: null) {
  font-weight: normal $important;
}

@mixin textWeightSemiBold($important: null) {
  font-weight: 600 $important;
}

@mixin textWeightBold($important: null) {
  font-weight: bold $important;
}

@mixin textWeightBolder($important: null) {
  font-weight: bolder $important;
}

@mixin marginRight($size, $important: null) {
  @if $size == 'xs' {
    margin-right: 0.3rem $important;
  }
  @if $size == 'sm' {
    margin-right: 0.5rem $important;
  }
  @if $size == 'lg' {
    margin-right: 1rem $important;
  }
}

@mixin marginLeft($size, $important: null) {
  @if $size == 'xs' {
    margin-left: 0.3rem $important;
  }
  @if $size == 'sm' {
    margin-left: 0.5rem $important;
  }
  @if $size == 'lg' {
    margin-right: 1rem $important;
  }
}

@mixin maxWidth($size, $important: null) {
  @if $size == 'xs' {
    max-width: calculateRemDeprecated(800px);
  }
  @if $size == 'sm' {
    max-width: calculateRemDeprecated(1000px);
  }
  @if $size == 'sm_1200' {
    max-width: calculateRemDeprecated(1200px);
  }
  @if $size == 'med' {
    max-width: calculateRemDeprecated(1500px);
  }
  @if $size == 'lg' {
    max-width: calculateRemDeprecated(1800px);
  }
}

@mixin marginLeftAndRight($size, $important: null) {
  @include marginRight($size, $important);
  @include marginLeft($size, $important);
}

@mixin total-work-range {
  padding-left: 0.1rem !important;
  padding-right: 0.1rem !important;
  overflow: visible;
  white-space: normal;
}

@mixin total-work-range--item-list {
  @include total-work-range;
  max-width: 10rem !important;
}

@mixin total-work-range--ancestry-list {
  @include total-work-range;
  max-width: 10rem !important;
}

@mixin stickyHeader($top: 0, $zIndex: zindex.$zIndexFocus) {
  position: sticky;
  top: $top;
  z-index: $zIndex;
}

@mixin helpTextColorAndFontStyle {
  color: variables.$gray600;
  font-style: italic;
}

@mixin avatarSizeInRems($remSize, $important: null) {
  max-width: #{$remSize}rem $important;
  min-width: #{$remSize}rem $important;
  width: #{$remSize}rem $important;
  max-height: #{$remSize}rem $important;
  min-height: #{$remSize}rem $important;
  height: #{$remSize}rem $important;
}

@mixin modalActionButtonSpacing($important: null) {
  margin-right: 0 $important;
  margin-left: 0.5rem $important;
}

@mixin easeInOutHoverEffect {
  cursor: pointer;
  transition: 200ms variables.$defaultCubicBezier;
}

@mixin headerHoverEffect {
  @include easeInOutHoverEffect();
  background-color: variables.$gray400 !important;
}

@mixin InputError {
  background-color: variables.$red100 !important;
  border: 1px solid rgba(variables.$lpRed, 0.15) !important;
  &:hover,
  &:focus-within {
    border: 1px solid rgba(variables.$lpRed, 0.15) !important;
  }
}

@mixin fadeOutAnimation {
  animation: fadeOut 200ms linear;
  opacity: 0;
  visibility: hidden;
}

@mixin fadeInAnimation {
  animation: fadeIn 200ms linear;
  opacity: 1;
  visibility: visible;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@mixin buttonColorAndHoverColor($color, $hoverColor) {
  background: $color;

  &:hover {
    background: $hoverColor;
  }
}

@mixin tableRowHoverStyles($color: variables.$blue50) {
  transition: background-color 250ms variables.$defaultCubicBezier;
  background-color: $color;
}

@mixin orientationLandscape() {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin orientationPortrait() {
  @media screen and (orientation: portrait) {
    @content;
  }
}

@mixin minSizeMediaQuery() {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin aboveMinSizeMediaQuery() {
  @media screen and (min-width: 1201px) {
    @content;
  }
}

@mixin smallSizeMediaQuery() {
  @media screen and (max-width: 1350px) {
    @content;
  }
}

@mixin mediumSizeMediaQuery() {
  @media screen and (max-width: 1500px) {
    @content;
  }
}

@mixin maxSizeMediaQuery() {
  @media screen and (min-width: 1800px) {
    @content;
  }
}

@mixin regPageMinSizeMediaQuery($maxWidth: 550px) {
  @media screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin inputErrorColor {
  background: variables.$red100 !important;
  border: 1px solid variables.$red500 !important;
}

@mixin dropdownWithNonDefaultSelection {
  background: variables.$slate700 !important;
  color: variables.$lpBrandWhite !important;
  border: 1px solid variables.$gray300 !important;
}

@mixin workloadViewRowHover {
  &:hover {
    background-color: variables.$blue50;

    .lp-action-ellipsis__icon {
      color: variables.$trueBlack;
    }
  }
}

@mixin transitionOpacity {
  transition: opacity 200ms ease-in-out !important;
}

@mixin baseBoxShadow($spread: 10px) {
  box-shadow: 0 0 $spread rgba(0, 0, 0, 0.2);
}
@mixin readonlyNotesScss() {
  box-shadow: 0 0 2px 1px variables.$gray300;
  border: 1px solid #ccc;
  height: 100%;
  padding: 1em;
  overflow: auto;
  color: variables.$slate700;
  background: variables.$lpBrandWhite;

  strong {
    @include textWeightSemiBold();
  }

  img {
    max-width: 100%;
    height: auto !important;
  }

  hr {
    border: 0;
    border-top: 2px solid variables.$gray300;
  }

  p {
    line-height: 1.8rem;
  }

  table,
  td,
  th {
    border: 1px solid variables.$gray300;
  }

  table {
    margin-bottom: 1rem;

    th,
    thead tr {
      background-color: variables.$gray300;
    }

    thead {
      @include textWeightSemiBold();
    }

    td,
    th {
      padding: 0.4rem;
    }

    th,
    thead {
      @include textWeightSemiBold();
    }
  }

  ul,
  ol {
    line-height: 1.4;
    margin: 1rem 0;

    li {
      margin-bottom: 1rem;
    }
  }

  blockquote {
    border-left: 4px solid variables.$gray300;
    margin-left: 1.5rem;
    padding-left: 1rem;
  }

  code {
    background-color: variables.$gray300;
    border-radius: 3px;
    padding: 0.1rem 0.2rem;
  }
  details {
    padding: 1rem;
    margin: 1rem;
    margin-right: 0;
    background: variables.$slate50;
  }
  summary {
    color: variables.$relightBlue;
    padding-left: 0;
    padding-bottom: 0.5rem;
  }
}
