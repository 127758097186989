@use 'style/variables';

.v2-widget-task-status-distribution {
  grid-template-rows: auto 1fr;
}

.task-board-widget {
  &__body {
    display: flex;
    justify-content: center;
    min-height: 8rem;
    max-height: 12rem;
    height: 100%;

    &-column {
      display: grid;
      grid-template-rows: auto 1fr;
      row-gap: 0.25rem;
      margin: 0 0.5rem;
      justify-content: center;
      justify-items: center;

      &-progress {
        display: flex;
        flex-direction: column-reverse;
        margin: 0 auto;
        background: variables.$gray300;
        width: 1rem;
        border-radius: 2px;
        overflow: hidden;

        &-bar {
          border-radius: inherit;
          transition: transform 250ms;
          transform-origin: top;
          height: 100%;
          transform: translateY(-100%);
        }
      }

      &-icon {
        height: 1.1rem;
        width: 1.1rem !important;
      }
    }
  }
}
