@use 'style/variables';
@use 'style/mixins';

.jira-configuration {
  max-width: 1000px;
  height: 100%;
  padding: 1rem;

  .ui.table {
    thead tr > th {
      font-weight: variables.$weightRegular;
    }
  }

  &-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    &-icons {
      display: flex;
      align-items: center;
      gap: 2rem;
      color: variables.$gray700;
    }
    &-text {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: variables.$gray700;
      &-header {
        font-size: variables.$importantHeaderFontSize;
        font-weight: variables.$weightSemiBold;
      }
      &-message {
        font-size: variables.$importantMessageFontSize;
        font-weight: variables.$weightRegular;
      }
    }
  }

  &__table {
    &-cell {
      &-project-name {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
      &-authorized-by {
        &--evicted {
          @include mixins.evictedUser();
        }
      }
    }
  }
}
