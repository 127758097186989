@use 'style/mixins';
@use 'style/variables';

.user-profile {
  overflow-x: hidden;
  margin: 1rem;
  padding-right: 1rem;

  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: minmax(26rem, 65rem) 15.2rem;
  grid-auto-rows: max-content;

  &__errors {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  &__about-table {
    grid-row-start: 2;
    grid-column-start: 1;
  }

  &__no-data-customization {
    @include mixins.helpTextColorAndFontStyle();
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  &__workspace-attributes-table {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  &__evicted-user-alert {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &__avatar {
    grid-row-start: 2;
    grid-column-start: 2;
    margin: 0 !important;
    display: grid;
    row-gap: 1rem;
    grid-auto-rows: min-content;
  }

  &__about,
  &__workspace-attributes {
    &-table.ui.table.definition {
      &.ui.table {
        margin: 0;
      }
      tr td:first-child:not(.ignored) {
        width: 15rem;
        @include mixins.textWeightNormal;
        color: variables.$gray700;
      }
    }

    .avatar-edit-menu.ui.dropdown {
      margin: 0;
    }
  }

  &__about-table {
    &-row {
      color: variables.$gray700;

      .ui.selection.dropdown {
        color: variables.$gray700;
      }
      .item {
        color: variables.$gray700;
      }
    }

    &-row-email,
    &-row-user-since {
      color: variables.$gray500;
    }

    &-timezone.ui.search.selection.dropdown {
      border: none;
    }

    &--evicted {
      opacity: variables.$readOnlyOpacity;
      color: variables.$slate700 !important;
    }
    &--evicted-email {
      color: variables.$lpRed;
    }
  }

  &__workspace-attributes-table {
    .custom-field__row {
      .note-user-actions {
        float: right;
        margin-top: 1rem;
      }
    }
  }

  &__table {
    &-checkbox.ui.checkbox {
      margin-right: 0.5rem;
    }

    &-cell-input {
      padding: 0.05rem 0.7rem !important;
    }

    &-input {
      width: 100%;

      & > input {
        color: variables.$gray700 !important;
        width: 100%;
      }
    }

    &-dropdown {
      padding: 0.05rem 0.7rem 0.05rem 0 !important;
      .ui.selection.dropdown {
        border: none;

        &:hover {
          border: none;
        }
      }

      .lp-color-picker-dropdown {
        width: 18.5rem;

        &:hover {
          border: none;
        }
      }
    }
  }

  .ui.header.navigation-section__header {
    text-transform: capitalize;
  }
}

.update-login-modal,
.change-password-modal {
  &.ui.modal {
    max-width: 40rem;

    .content {
      display: grid;
      row-gap: 1rem;
    }

    .ui.label.lp-form-error {
      margin: 0;
    }
  }

  &__input-section {
    display: flex;
    flex-direction: column;

    label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }
}
