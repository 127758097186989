@use 'style/mixins';
@use 'style/variables';

.feature-trial-simple-banner {
  background: variables.$slate200;
  color: variables.$gray700;
  height: min-content;
  padding: 1rem;
  row-gap: 0.5rem;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'bannerText bannerAction';
  margin: 1rem;

  &__text {
    grid-area: bannerText;
    @include mixins.textWeightSemiBold();
    align-self: center;
  }
}
