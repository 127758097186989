@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

@mixin imageUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  color: variables.$gray500;
  text-align: center;
}

.image-upload {
  border: 1px dashed variables.$gray300;
  background-color: variables.$lpBrandWhite;
  min-height: 100px;

  &__label {
    width: 100%;
  }

  &--empty {
    @include imageUpload();
  }

  &--update {
    @include imageUpload();

    .image-upload__preview {
      position: relative;
    }

    .image-upload__dropdown.ui.dropdown {
      position: absolute;
      text-align: center;
    }

    .image-upload__icon {
      position: absolute;
      z-index: zindex.$zIndexBase;
      visibility: hidden;
    }

    &:hover {
      background-color: rgba(variables.$slate700, 0.8);
      transition: background-color 0.45s ease;

      .image-upload__preview {
        opacity: 0.2;
        transition: opacity 0.45s ease;
      }

      .image-upload__icon {
        visibility: visible;
        color: variables.$lpBrandWhite;
      }
    }
  }
}
