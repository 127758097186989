.advanced-filters-modal {
  &-content {
    height: 200px;
    overflow-y: auto;
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-right {
      display: flex;
      gap: 1rem;
    }
  }
}
