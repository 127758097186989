.email_notification_preferences {

  &__form {
    display: grid;
    grid-template-columns: 13rem 18rem;
    column-gap: 1rem;
    grid-template-areas: 'dropdownLabel dropdown' 'checkboxFollow checkboxFollow' 'checkboxPost checkboxPost' 'checkboxNewAssignment checkboxNewAssignment';
    row-gap: 0.5rem;

    &__dropdown {
      grid-area: dropdown;
    }

    &__dropdown-label {
      align-self: center;
      grid-area: dropdownLabel;
    }

    &__checkbox-follow {
      grid-area: checkboxFollow;
      padding-left: 1em;
    }

    &__checkbox-post {
      grid-area: checkboxPost;
      padding-left: 1em;
    }

    &__checkbox-new-assignment {
      grid-area: checkboxNewAssignment;
      padding-left: 1em;
    }
  }

  &__notices {
    padding-left: 0;
    margin-left: 0;
    list-style-position: inside;

    ul {
      padding-left: 1em;
    }
  }

}
