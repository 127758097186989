@use 'style/variables';
@use 'style/zindex';

$arrow-width: 10px;
$arrow-offset: calc($arrow-width / 2);

.lp-popup {
  pointer-events: none;

  &__default-styles {
    background: variables.$lpBrandWhite;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.4));
    font-size: 0.95rem;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    z-index: zindex.$zIndexItemPanelModalAction;
  }

  &[data-popper-placement^='top'] > .lp-popup__arrow {
    bottom: -$arrow-offset;
  }

  &[data-popper-placement^='bottom'] > .lp-popup__arrow {
    top: -$arrow-offset;
  }

  &[data-popper-placement^='left'] > .lp-popup__arrow {
    right: -$arrow-offset;
  }

  &[data-popper-placement^='right'] > .lp-popup__arrow {
    left: -$arrow-offset;
  }

  &__arrow,
  &__arrow::before {
    position: absolute;
    width: $arrow-width;
    height: $arrow-width;
    background: inherit;
  }

  &__arrow {
    z-index: zindex.$zIndexBottom;
    visibility: hidden;
  }

  &__arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
}
