@use 'style/mixins';
@use 'style/variables';

.archived {
  opacity: 0.45;
}

.checkbox-row {
  &__content-and-checkbox {
    display: flex;
    align-items: center;
  }
}

.checkbox-row,
.other-properties-options-table {
  &__header {
    display: flex;
    text-align: center;
    margin-bottom: 0.5rem;
    align-items: center;
  }

  &__header-name {
    font-size: 1.3rem;
    @include mixins.textWeightBold();
  }

  &__scroll-container {
    width: 100%;
    height: 26rem;
    overflow: auto;
    display: inline-block;
    border: 1px solid variables.$gray500;
  }

  &__row {
    &-cell-header {
      width: 15rem;
      background: variables.$gray100;
    }

    &-cell-header-content {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-cell-checkbox {
      margin-right: 0.5rem;
    }

    .ui.table &-cell-body {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }
}
