.lp-banner {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  width: 100%;

  &__content {
    width: 100%;
    word-wrap: break-word;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__close-icon {
    cursor: pointer;
  }
}
