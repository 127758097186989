.copy-text-button.ui.button {
  margin-right: 0;
  height: unset !important;
  font-size: 1rem;
  padding: 0.35rem;

  .svg {
    margin-right: 0;
  }
}
