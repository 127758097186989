@use 'style/mixins';
@use 'style/variables';

@mixin ellipsis-base {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.lp-action-ellipsis {
  @include ellipsis-base;
  color: variables.$gray700;
  width: 1.7rem;
  height: 1.7rem;

  &__icon {
    transition: ease-in-out 150ms;
    color: variables.$gray400;
  }

  &.timesheet {
    width: mixins.calculateRemDeprecated(15px);
  }

  &:hover {
    .lp-action-ellipsis__icon {
      color: variables.$trueBlack;
    }
  }
}
