@use 'style/mixins';
@use 'style/variables';

.upgrade-page {
  padding: 1rem;
  background: variables.$gray100;
  overflow: auto;
  height: 100%;

  &__max-width {
    height: inherit;
    max-width: 76rem;
  }

  &__content {
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    row-gap: 2rem;
    padding-bottom: 4rem;

    &-section-header {
      @include mixins.textWeightBold();
      height: min-content;
      padding: 1rem;
      font-size: 1.1rem;
      color: variables.$gray700;
      background: variables.$gray200;
    }

    &-section-grid {
      padding: 1rem;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'featureItem1 featureItem2'
        'featureItem3 featureItem4'
        'featureItem5 featureItem6'
        'featureItem7 featureItem8'
        'featureItem9 featureItem10';
    }

    &-section-grid-feature-row0-col0 {
      grid-area: featureItem1;
    }
    &-section-grid-feature-row0-col1 {
      grid-area: featureItem2;
    }
    &-section-grid-feature-row1-col0 {
      grid-area: featureItem3;
    }
    &-section-grid-feature-row1-col1 {
      grid-area: featureItem4;
    }
    &-section-grid-feature-row2-col0 {
      grid-area: featureItem5;
    }
    &-section-grid-feature-row2-col1 {
      grid-area: featureItem6;
    }
    &-section-grid-feature-row3-col0 {
      grid-area: featureItem7;
    }
    &-section-grid-feature-row3-col1 {
      grid-area: featureItem8;
    }
    &-section-grid-feature-row4-col0 {
      grid-area: featureItem9;
    }
    &-section-grid-feature-row4-col1 {
      grid-area: featureItem10;
    }

    &-section-grid-feature {
      display: grid;
      padding: 1rem;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
      grid-template-rows: max-content auto;
      grid-template-areas: 'icon title' 'icon description';

      &-icon {
        font-size: 1.5rem;
        grid-area: icon;
      }

      &-title {
        @include mixins.textWeightBold();
        grid-area: title;
        color: variables.$gray700;

        &-not-active {
          @include mixins.marginLeft('sm');
          color: variables.$gray600;
          font-size: 0.9rem;
        }
      }
      &-description {
        grid-area: description;
        color: variables.$gray600;
        line-height: 1.5;
      }
    }

    &-section-included {
      display: grid;
      padding: 1rem;
      grid-template-areas: 'includedLeft includedCenter includedRight';
    }

    &-section-included-left {
      grid-area: includedLeft;
      margin-left: 3rem;
    }
    &-section-included-center {
      grid-area: includedCenter;
      margin-left: 3rem;
    }
    &-section-included-right {
      grid-area: includedRight;
      margin-left: 3rem;
    }

    &-section-included-feature {
      display: block;
      color: variables.$gray700;
      margin-bottom: 0.2rem;
    }
  }
}
