@use 'style/variables';

.drag-handle {
  display: inline-block;
  color: variables.$gray500;
  margin-right: 0.5rem;
  user-select: none;
  touch-action: none !important;

  &--disabled {
    opacity: 0;
  }
}
