@use 'style/mixins';
@use 'style/variables';

.lp-form {
  .ui.form .field &__label,
  &__label {
    margin-bottom: 0.3rem;
    color: variables.$gray700;

    &--bold {
      font-weight: variables.$weightSemiBold;
    }
    &--italic {
      font-style: italic;
      font-weight: variables.$weightRegular;
    }
  }

  &__input .ui.input input {
    line-height: normal !important;
  }

  &__input,
  &__dropdown {
    > label {
      color: variables.$gray700 !important;
      font-weight: variables.$weightSemiBold !important;
    }
  }
}
