.top-level-error {
  max-width: 40rem;
  margin: 0 auto 2rem auto;
  text-align: center;
  font-size: 1.3rem;

  &__wrapper {
    display: grid;
    grid-auto-rows: 1fr auto;
    height: 100%;
    overflow-y: auto;

    .lp-legal-terms-footer {
      margin: auto auto 2rem auto;
    }
  }

  &__content {
    align-self: center;
  }

  &__icon {
    width: 100% !important;
    margin-bottom: 1rem;
  }

  &__label {
    margin-bottom: 1rem;
  }

  &__try-reloading {
    margin-top: 1rem;
  }
}
