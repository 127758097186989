@use 'style/mixins';
@use 'style/variables';

.disconnected-user {
  height: 100%;
  width: 100%;

  &__welcome-back {
    background-color: variables.$gray100;
    padding: 2rem;
    @include mixins.baseBoxShadow();
  }

  &__account-information-header.ui.header {
    text-align: left;
    font-size: variables.$infoTableHeaderFontSize;
    font-weight: variables.$infoTableHeaderFontWeight;
  }

  &__account-table-actions {
    margin-top: 3rem;
  }

  &__done-button {
    width: 100%;
  }

  &__content-section {
    grid-area: contentSection;
    display: grid;
    grid-template-rows: 1fr min-content;
    grid-template-columns: auto;
    grid-template-areas: 'content' 'copyright';
    height: 100%;
  }

  &__content {
    grid-area: content;
    max-width: 50rem;
    justify-self: center;
    align-self: center;
  }

  &__header-section {
    text-align: center;
  }

  &__restart-account {
    margin-top: 5rem;
    background-color: variables.$gray100;
    padding: 2rem;
    @include mixins.baseBoxShadow();
  }

  &__header.ui.header,
  &__header-subtext,
  .lp-legal-terms-footer {
    color: variables.$gray900;
  }

  &__add-organization.ui.mini.modal {
    width: 30rem;
  }

  &__navigation-section {
    display: flex;
    justify-content: center;
  }

  &__frame {
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: auto;
    grid-template-areas: 'topBar' 'contentSection';
    width: 100%;
    height: 100%;
    border-color: variables.$slate800;
    border-style: solid;
    border-left-width: 3px;
    border-bottom-width: 3px;
    border-right-width: 3px;
    border-top-width: 0;
    overflow-y: auto;
  }

  &__top-bar {
    grid-area: topBar;
    display: inline-flex;
    width: 100%;
    height: 3rem;
  }

  &__logo-container {
    display: flex;
    height: 100%;
    width: 10rem;
    margin-left: 1rem;
    align-items: center;
  }

  &__logo {
    width: 100%;
    height: 1.4rem;
    display: flex;
    align-self: center;
  }

  &__logout.ui.button:not(.dropdown) {
    display: flex;
    width: 8rem;
    height: 2rem;
    margin-left: auto;
    margin-right: 1rem;
    align-self: center;
    justify-content: center;
  }

  .ui.form .field.disabled,
  .ui.form .field.disabled > label {
    opacity: 1;
  }
}
