@use 'style/mixins';
@use 'style/variables';

.dashboard-location-filter.ui.button.basic {
  @include mixins.overflowEllipsis;

  display: flex;
  align-items: center;
  border: 1px solid variables.$gray400;
  background-color: variables.$lpBrandWhite !important;
  border-radius: 3px;
  width: 100%;
  text-align: start;
  color: variables.$slate700 !important;
  padding: 0.5rem;

  &:hover,
  &:focus {
    color: variables.$slate700 !important;
  }

  &:focus {
    border: 1px solid variables.$lpFocusColor;
    background-color: variables.$lpBrandWhite !important;
  }

  .dashboard-location-filter__content {
    display: grid;
    align-items: center;
    column-gap: 0.3rem;
    grid-template-columns: min-content minmax(0, 1fr) min-content;
    line-height: 1.5;
    width: 100%;

    &--disabled {
      grid-template-columns: minmax(0, 1fr) min-content;
      opacity: 0.5;
    }
  }

  .item-name {
    @include mixins.overflowEllipsis;
  }
}
