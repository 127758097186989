@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.base-v2-widget-settings {
  min-width: 37rem;
  max-width: 40rem;

  .header {
    @include mixins.overflowEllipsis;
  }

  .actions {
    .ui.button {
      margin-right: 0;
    }
  }

  .v2-widget-settings-button {
    &.ui.button {
      padding: 0.5rem 1rem;
      text-align: left;
      width: 12rem;
    }

    &--filtered {
      @include mixins.dropdownWithNonDefaultSelection;
    }
  }

  .v2-widget-settings-checkbox.ui.checkbox label {
    margin-left: 1rem;
    padding-left: 1.5rem;
  }

  &__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 1fr);
    grid-auto-rows: max-content;
    column-gap: 0.5rem;
    row-gap: 1rem;

    .tox.tox-tinymce {
      grid-column: span 2;
    }

    &-options-label {
      padding-top: 0.4rem;
      justify-self: end;
      white-space: nowrap;
    }

    &-options-field {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-start;
      align-self: flex-start;

      .v2-widget-settings-button {
        &.ui.button {
          padding: 0.5rem 1rem;
          text-align: left;
          width: 12rem;
        }

        &--filtered {
          @include mixins.dropdownWithNonDefaultSelection;
        }
      }

      &-widget-title {
        width: 100%;

        .ui.input input {
          line-height: 1;
        }
      }

      &-inherited-location {
        @include mixins.overflowEllipsis;
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        column-gap: 0.2rem;

        .inherited-location__item {
          @include mixins.marginLeft('xs');
        }
      }

      &-on-click-view {
        .ui.selection.dropdown {
          padding: 0.3rem 0.5rem;
          line-height: 1.3rem;

          .dropdown.icon {
            margin: 0;
            padding: 0;
          }

          .text {
            padding: 0;
          }
        }

        .on-click-view-option__icon {
          @include mixins.marginRight('sm');
        }
      }

      &-metrics-multi-select,
      &-task-status-multi-select {
        display: grid;
        grid-auto-rows: auto;
        align-content: start;
        row-gap: 0.4rem;
        height: 200px;
        width: 100%;
        border: 1px solid variables.$gray300;
        border-radius: 3px;
        overflow: auto;
        padding: 0.5rem;
        background: variables.$lpBrandWhite;
        &-row {
          display: grid;
          grid-template-columns: auto auto auto;
          justify-content: start;
          align-items: center;
          column-gap: 0.4rem;
          padding: 0.25rem;
          cursor: pointer;

          &:last-of-type {
            padding-bottom: 0.5rem;
          }
        }
      }


      &-width-and-depth {
        display: flex;
        column-gap: 1rem;
        align-items: center;

        &-dropdown {
          margin-bottom: 0 !important;

          .ui.selection.dropdown {
            min-width: 12rem;
          }
        }

        &-help {
          color: variables.$gray600;

          &-icon {
            @include mixins.marginRight('sm');
          }
        }
      }

      &-column-sort {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;

        &-dropdown {
          width: 100%;
          margin-right: 0.5rem;
        }

        &-button.ui.icon.button {
          margin: 0;
          width: 3rem;
        }
      }

      &-custom-item-filter {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;

        .ui.button {
          min-width: 12rem;
        }

        &-text {
          line-height: 1.2;
        }
      }
    }
  }
}
