@use 'style/mixins';
@use 'style/variables';

.lp-invitation {
  width: 40rem;
  padding: 1rem;
  background: variables.$gray100;

  @include mixins.regPageMinSizeMediaQuery() {
    width: 90%;
    margin: 0 auto;
  }

  &__logo {
    margin: 0 auto;
    padding: 1.75rem 1.5rem 1.5rem 1.5rem;
  }

  &__form.ui.form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__invitation-details {
    width: 100%;
    margin: 0 auto 2rem auto;
  }

  &__logo-block {
    margin-bottom: 1rem;
    min-height: 6rem;
    display: flex;
    align-items: center;

    > a {
      width: 100%;
    }
  }

  &__sso-accept {
    background-color: variables.$lpBrandWhite;
    padding: 2rem;
    border-radius: 5px;
    text-align: center;

    > h3 {
      @include mixins.textWeightSemiBold();
    }
  }

  &__submit-button.ui.button {
    align-self: center;
    background: variables.$orange500;
    color: variables.$lpBrandWhite;
    border: 1px solid transparent;
    width: 36%;
  }
}
