@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.lp-academy-modal {
  max-width: mixins.calculateRemDeprecated(1200px) !important;

  &.ui.modal.tabbed-modal .ui.grid .four.wide.column .ui.fluid.vertical.menu {
    border-bottom: 1px solid variables.$slate600;
  }
  &.ui.modal.tabbed-modal .ui.grid .four.wide.column {
    max-width: none;

    @include mixins.minSizeMediaQuery {
      @include mixins.orientationPortrait {
        width: min-content !important;
        min-width: unset;
        max-width: unset;
      }
    }
  }
  &.ui.modal.tabbed-modal .ui.grid .stretched.twelve.wide.column {
    border-bottom: none;
  }

  &__header {
    &-breadcrumbs {
      display: flex;
      align-items: center;
      color: variables.$gray700;

      .svg-inline--fa {
        height: 1.75rem;
        width: auto;
      }

      .ui.breadcrumb {
        @include mixins.fontSize(18px);
        line-height: initial;
        padding: 0;
        margin-left: 0.5rem;

        .section:last-child {
          font-weight: 600;
        }
      }
    }

    &-actions {
      .small {
        color: variables.$lpBrandWhite !important;
        background-color: variables.$slate700 !important;
        @include mixins.smallUiButton;
        font-size: 1.1rem !important;
      }
    }
  }

  &__menu-item {
    display: grid !important;
    grid-template-columns: auto 1fr 1rem;
    align-items: center !important;
    column-gap: 0.5rem;

    &-title {
      @include mixins.overflowEllipsis;
      line-height: 1.3rem;
    }
  }

  .ui.modal.tabbed-modal .ui.grid .four.wide.column .ui.fluid.vertical.menu &__menu-item.item::before {
    background: variables.$slate600;
  }

  &__content {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    .read-only-panel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mixins.readonlyNotesScss();

      &--video {
        .academy-video {
          box-shadow: 0 0 4px 2px variables.$slate600;
        }
      }
    }

    &--beacon {
      height: 1rem;
      width: 1rem;
      flex: 0 0 1rem;
      background: transparent;
    }

    .ui.basic.segment {
      padding: 0;
    }
  }

  &-sections {
    background: variables.$gray100;
    height: 100%;
    min-height: mixins.calculateRemDeprecated(400px);

    .ui.grid {
      .four.wide.column {
        background: variables.$slate700 !important;

        .ui.fluid.vertical.menu {
          background: variables.$slate700 !important;

          .item {
            &:hover {
              background: variables.$slate600 !important;
            }

            &.active {
              background: variables.$slate600 !important;
            }
          }
        }
      }
    }
  }

  &__footer {
    &-actions {
      margin-left: auto;
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 1rem;

      &-close,
      &-next,
      &-finished {
        color: variables.$lpBrandWhite !important;
        background-color: variables.$slate700 !important;
      }
    }
  }
}
