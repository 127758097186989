@use 'style/variables';
@use 'style/zindex';

.lp-inline-loader {
  &.ui.inline.loader {
    margin-left: 0.5rem;
  }

  &.ui.loader {
    z-index: zindex.$zIndexNone;
  }

  &.ui.inline.loader.active {
    display: inline-flex;
    align-self: center;
  }

  &.ui.loader::after {
    border-top-color: variables.$relightBlue;
  }

  &.ui.loader::before {
    border-color: variables.$gray500;
  }
}
