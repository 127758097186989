@use 'style/variables';

.ancestry-breadcrumb {
  color: variables.$gray600;
  font-size: 0.9rem;
  line-height: 0.9rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  column-gap: 0.2rem;
  align-items: center;

  transition: opacity 200ms ease-in-out;

  &__list.ui.breadcrumb {
    padding-top: 0.2rem;

    & a {
      color: variables.$gray600;
    }

    & a:hover {
      color: variables.$blue400;
    }
  }
}
