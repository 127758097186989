@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.lp-data-grid {
  .rdg {
    height: 100%;
  }

  .rdg-header-row {
    background: variables.$gray200;
    font-weight: variables.$weightRegular;
    contain: unset;
    color: variables.$slate700;

    .rdg-cell {
      text-align: center;

      &.rdg-text-left-align {
        text-align: left;
      }
    }

    .rdg-cell.rdg-cell-frozen {
      z-index: zindex.$zIndexHelperMenu;
    }

    .rdg-cell {
      z-index: zindex.$zIndexAction;
    }
  }

  .rdg-bottom-summary-row {
    background: variables.$gray200;

    .rdg-cell {
      border-block-start: 0px;
      --rdg-summary-row-top: 0px;

      &.grid-total-summary {
        text-align: right;
      }
    }
  }

  .rdg-row {
    color: variables.$gray700;
    .rdg-cell {
      font-weight: normal;
      text-align: right;

      &.rdg-text-left-align {
        text-align: left;
      }
    }

    .rdg-cell-frozen {
      z-index: zindex.$zIndexMenu;
    }

    &:hover {
      background-color: variables.$blue50;
    }
  }
  .rdg-cell-copied {
    background-color: unset;
  }

  .rdg-cell {
    &.error {
      background-color: variables.$red100;
    }
  }

  &__name-header {
    .header-text {
      @include mixins.marginRight('xs');
    }
  }

  &__select {
    padding-top: 0.2rem;
    padding-left: 0.65rem;
    text-overflow: unset;
  }

  &__select-header.rdg-cell {
    padding-left: 0.35rem;
    padding-top: 0.2rem;

    &:first-child {
      contain: unset;
      overflow: visible;
    }
  }

  &__select-header--select-all {
    .ui.dropdown.selection-dropdown .selection-dropdown__button {
      height: 2rem !important;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    .icon {
      @include mixins.marginRight('xs');
    }

    &-breadcrumb,
    &-name {
      @include mixins.overflowEllipsis;
      cursor: pointer;
      flex-grow: 2;
    }

    &-name {
      display: grid;
      position: relative;
      grid-template-rows: 1fr;
      grid-auto-flow: column;
      grid-auto-columns: min-content;
      column-gap: 0.3rem;

      .assignment-name {
        color: variables.$orange500;
      }
      .done-assignment-name {
        @include mixins.doneAssignment();
      }
    }
  }

  &--inherited {
    opacity: 0.45;
  }

  &__file-name {
    display: block;

    &--new {
      animation-name: showNewFile;
      animation-duration: 500ms;
      animation-timing-function: variables.$defaultCubicBezier;
    }
  }

  .custom-column__schedule-bar-cell {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 1.25rem;
  }
}

@keyframes showNewFile {
  0% {
    background: transparent;
  }
  50% {
    background: variables.$green200;
  }
  100% {
    background: transparent;
  }
}
