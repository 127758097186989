@use 'style/zindex';

.lp-schedule-target-finish-dropdown {
  &.ui.selection.dropdown {
    min-width: 11.5rem;

    &.visible {
      z-index: zindex.$zIndexMenu;
    }

    .dropdown.icon {
      padding-top: 0.5rem;
    }
  }
}
