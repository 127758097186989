@use 'style/mixins';
@use 'style/variables';

.lp-widget-project-template-picker,
.lp-project-template-picker {
  &.ui.modal {
    min-width: 35rem;
    .header {
      @include mixins.textWeightSemiBold();
      border-bottom: 0;
      padding-bottom: 0;
      background-color: variables.$gray100;
    }

    .actions {
      padding-top: 0;
      border-top: 0;

      .ui.button {
        margin-right: 0;
      }
    }

    .content {
      background-color: variables.$gray100;
    }
  }

  &__project {
    display: flex !important;
    align-items: center;
    padding: 0.2rem 0 !important;
  }

  &__package {
    @include mixins.overflowEllipsis;
    background: variables.$gray200;
    color: variables.$lpGray800;
    line-height: 1.4rem;
    padding: 0.5rem;
  }

  &__project-checkbox,
  &__project-picker,
  &__project-radio {
    margin-right: 0.6rem;
  }
  &__project-icon {
    margin-right: 0.2rem;
  }

  &__list {
    border: 1px solid variables.$gray300;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    background-color: variables.$lpBrandWhite;
    height: 26rem;
  }

  &__loader {
    min-height: 26rem;
    display: flex;
    flex-direction: column;
  }
}

.lp-package-template-picker {
  &__loader {
    min-height: 26rem;
    display: flex;
    flex-direction: column;
  }

  &.ui.modal {
    min-width: 35rem;
    .header {
      @include mixins.textWeightSemiBold();
      border-bottom: 0;
      padding-bottom: 0;
      background-color: variables.$gray100;
    }

    .actions {
      padding-top: 0;
      border-top: 0;

      .ui.button {
        margin-right: 0;
      }
    }

    .content {
      background-color: variables.$gray100;
    }
  }

  .ui.list {
    border: 1px solid variables.$gray300;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    background-color: variables.$lpBrandWhite;
    height: 26rem;

    .header {
      @include mixins.overflowEllipsis;
      background: variables.$gray200;
      color: variables.$lpGray800;
      line-height: 1.4rem;
      padding: 0.5rem;
      font-weight: normal;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 0.75rem 0 0.25rem 0.5rem;
      .ui.checkbox {
        margin-right: 0.6rem;
      }
      .icon {
        margin-right: 0.2rem;
      }
    }
  }
}
