@use 'style/mixins';

.inherited-location {
  &__icon {
    @include mixins.marginRight('xs');
  }

  &__item {
    @include mixins.overflowEllipsis;

    &-icon {
      @include mixins.marginRight('xs');
    }
  }
}
