@use 'style/variables';

.beyond-limit-modal.ui.modal {
  width: 24rem !important;

  .header {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .actions {
    .ui.button {
      margin-right: 0;
      margin-left: 0;
      &.administration {
        float: left;
      }
    }
  }

  .header,
  .content,
  .actions {
    background: variables.$slate500;
  }

  .header,
  .content p {
    color: variables.$lpBrandWhite;
  }
}
