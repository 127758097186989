@use 'style/mixins';
@use 'style/variables';

.lp-main__organization--task-board {
  padding: 0 !important;
}

$backgroundColorFadeFilter: rgba(255, 255, 255, 90%);

.lp-task-board-page {
  padding: 1rem;
  background-color: variables.$gray400 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  &__no-content {
    background-color: variables.$lpBrandWhite !important;
    height: 100%;
  }
}

.lp-task-board {
  flex: 1;
  padding: 0 !important;
  display: flex;
  margin-right: 0 !important;
  overflow-y: hidden;
  overflow-x: auto;
  width: 0;

  &::-webkit-scrollbar-track {
    border-radius: mixins.calculateRemDeprecated(10px);
    background-color: variables.$gray200;
  }

  &::-webkit-scrollbar-thumb {
    background-color: variables.$gray500;
  }

  &__scheduling-limits-label,
  &__dependency-notification-label {
    background-color: variables.$blue100 !important;
    border-color: variables.$blue100 !important;
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: row;
    min-height: 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
    /* --- Column Sizes--- */
    /*
     * This pseudo element is preventing weird 'squashy' issues on the columns
     * Note: These height/width values can be 'whatever',
     * but currently work with the 'Small' dropdown setting
     * (aka fits without creating overflow scrolling).
     */
    .ui.card:first-child {
      margin-top: 1px;
    }

    &--empty {
      &::after {
        content: '';
        position: absolute;
        height: 50%;
        width: 80%;
        opacity: 0;
      }

      &.ui.segment {
        flex: 0 0 10rem !important;
      }
    }

    &--hide-empty {
      display: none;
    }

    /* --------------------- */

    /* --- Column Header --- */
    &-header {
      display: flex;
      justify-content: space-between;

      &-task-status-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        padding-left: mixins.calculateRemDeprecated(4px);
      }

      &-rollup {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 0.5rem;
        color: variables.$gray600;
      }
    }
    /* ---------------------- */

    &.ui.segment {
      margin: 0 1rem 0.7rem 0 !important;
      word-break: break-word;
      background: variables.$gray100;
      border: none;

      &:last-child {
        margin-right: 0;
      }
    }

    &-card-holder {
      overflow: auto;
      padding: 0 0.25rem 0.5rem 0.25rem;
      height: 100%;

      &::-webkit-scrollbar {
        width: 0.7rem;
        height: 0.5rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background-color: variables.$gray400;
      }

      &::-webkit-scrollbar-track {
        border-radius: mixins.calculateRemDeprecated(10px);
        background-color: transparent;
      }
    }

    &:hover {
      .lp-task-board__column-card-holder {
        &::-webkit-scrollbar-track {
          background-color: variables.$gray300;
        }

        &::-webkit-scrollbar-thumb {
          background-color: variables.$gray500;
        }
      }
    }
  }

  &__card {
    margin-right: 0.5rem !important;

    &-header {
      user-select: none;
      padding: 0.5rem !important;

      .description {
        display: flex;
        justify-content: space-between;
      }

      &-ancestry {
        display: flex;
        flex-direction: column;
        min-width: 0;

        &-row {
          @include mixins.overflowEllipsis;
          @include mixins.fontSize(12px);
          font-weight: 600;
        }
      }
    }

    &-task {
      cursor: pointer;
      background: $backgroundColorFadeFilter !important;

      &.content {
        .description {
          font-size: variables.$baseFontSize;
          color: variables.$gray700 !important;
          font-weight: 600;
        }

        .meta {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0.25rem;
        }
      }
      &-guest {
        cursor: default;
      }
    }

    &-assignees {
      display: grid;
      grid-auto-flow: column;
      column-gap: 0.3rem;
      margin-left: auto;

      button {
        &.ui.button {
          height: 2rem;
          width: 2rem;
          background-color: variables.$slate600;
          color: variables.$lpBrandWhite;
          vertical-align: bottom;
          &.guest {
            cursor: default;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      background: $backgroundColorFadeFilter;
      padding: 0.4rem;
      border-top: 1px solid variables.$gray400;

      &-labels {
        display: flex;
        column-gap: 0.2rem;

        &-icon {
          cursor: pointer;
        }
      }

      &-asap.ui.label.icon.notice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        min-width: 2.8rem;
        height: 1.54rem;
      }

      &-metrics {
        font-size: 1rem;
        display: flex;
        column-gap: 0.2rem;
        align-items: center;
      }
    }
  }
}
