@use 'style/mixins';
@use 'style/variables';

.api {
  overflow: auto;
  margin: 1.5rem;

  .top-section {
    display: flex;
    align-items: center;

    p {
      margin-left: 0.5rem;
    }
  }

  .ui.button.basic.icon {
    color: unset !important;
  }

  .ui.button.copy-text-button {
    background: none;
  }

  .help-icon {
    margin-left: 0.5rem;
    color: variables.$gray600;
  }

  .name-cell,
  .run-as-cell {
    width: mixins.calculateRemRounded(300px);
  }

  .run-as-cell {
    display: inline-flex;
    align-items: center;

    .disabled {
      &.ui.search.selection.dropdown {
        opacity: 0.8;
        .text {
          opacity: 0.8;
        }
      }
    }
  }

  .token-cell {
    &__trigger {
      float: left;
    }
    &__button {
      float: right;
    }
  }
}

.token-cell__popup {
  word-break: break-all;
}
