@use 'style/mixins';

.manage-sso-form {
  width: mixins.calculateRemRounded(830px);

  .ui.radio.checkbox > label {
    font-weight: 700;
  }

  .ui.radio.checked.checkbox > label::before {
    background-color: unset !important;
  }

  &__enable-sso,
  &__require-all-users-sso,
  &__new-user-default {
    margin-bottom: 2rem !important;
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__warning-text {
    display: inline-block;
    margin-bottom: 1rem;
  }

  &__text--disabled {
    opacity: 0.5;
  }

  &__two-column-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
    align-items: center;

    .field {
      margin-bottom: 0 !important;
    }
  }

  &__error-status.ui.label {
    margin-top: 1rem;
    margin-left: 0;
  }
}
