.multi-field-value-modal {
  &__dropdown {
    margin: 0.3rem;

    &--add-user {
      .divider.text {
        line-height: 1.2rem;
      }
    }
  }
}