@use 'style/mixins';
@use 'style/variables';

.email-confirmation-error {
  text-align: center;
  background-color: variables.$gray100;
  padding: 2rem;
  @include mixins.baseBoxShadow();

  &__logo-block .svg-inline--fa {
    width: 100%;
  }

  &__header-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem auto;
  }

  &__header.ui.header,
  &__sub-header.ui.header {
    width: 37.5rem;

    @include mixins.regPageMinSizeMediaQuery() {
      width: 90%;
    }
  }

  &__header.ui.header {
    font-size: 1.5rem;
    margin: auto auto 1rem auto !important;
  }

  &__sub-header.ui.header {
    font-size: 1.2rem;
    font-weight: normal;
    margin-top: 1rem;
  }

  &__sub-header--expired {
    display: block;
    margin-bottom: 1rem;
  }

  &__sub-header--expired:last-child {
    display: block;
    margin-bottom: 0;
  }

  &__submit-button.ui.button {
    align-self: center;
    width: max-content;
    margin-top: 1rem;
    color: variables.$lpBrandWhite;
    background: variables.$orange500;
    border: 1px solid transparent;
  }

  &__email-sent-text {
    padding: 1rem 0rem; // padding mimics submit button shape and prevents content shifting
  }
}
