@use 'style/mixins';
@use 'style/variables';

.set-password {
  background-color: variables.$gray100;
  padding: 4rem 4rem 2rem;
  width: 35rem;
  @include mixins.baseBoxShadow();

  .ui.form &__api-error.ui.error.message {
    margin-top: 1rem;
  }

  &__header-block {
    text-align: center;
    margin-bottom: 1rem;
  }

  &__header.ui.header {
    font-size: 1.5rem;
  }

  &__sub-header-text {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  &__initial-fetch-errors {
    font-size: 1.8rem;
  }
}
