.custom-data-modal {
  &__custom-field-input {
    margin-right: 1rem;

    .ui.selection.dropdown {
      margin-bottom: 0.2rem;
      margin-top: 0.2rem;
    }

    .ui.search.selection.dropdown {
      min-width: 14rem;
    }
  }
}
