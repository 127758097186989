@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.lp-edit-grid-modal {
  width: 80% !important;
  height: 70% !important;

  &--restrict_size {
    width: 27.5% !important;
    height: 30% !important;
  }

  &.ui.modal {
    .header {
      font-weight: 400 !important;
    }

    .content {
      display: grid !important;
    }
  }

  &__header {
    font-weight: 400 !important;
  }

  &__actions-save-and-close-button.ui.button {
    margin-right: 0;
  }

  &.visible.transition {
    display: grid !important;
    grid-template-rows: auto 1fr auto;
  }

  .bulk-help-text {
    line-height: 1.15rem;
    margin-top: 1rem;
    font-size: 1rem;
    color: variables.$slate700;
  }

  .actions.modal-actions {
    display: flex;
    justify-content: flex-end;

    .lp-form-error,
    .ui.small.button {
      padding: 0.75rem;
    }

    .lp-form-error {
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
