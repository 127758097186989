@use 'style/mixins';
@use 'style/variables';

@mixin gridAutoRowWithGapOfHalfARem {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 0.5rem;
}

.custom-field-table {
  &.ui.table {
    tr.custom-field__row {
      td {
        padding: 0.25rem 0.5rem;
      }
    }
    border: mixins.calculateRemDeprecated(2px) solid variables.$gray300;

    .custom-field__row {
      height: 1.5rem !important;
    }

    .field-value.item {
      @include gridAutoRowWithGapOfHalfARem;
    }
  }

  &__header {
    display: grid;
    grid-auto-rows: auto auto;
    row-gap: 1rem;
    color: variables.$gray700;
    font-weight: variables.$weightRegular;

    &-text {
      font-weight: variables.$weightSemiBold;
    }

    &-help-text {
      @include mixins.helpTextColorAndFontStyle;

      &-link {
        @include mixins.marginLeft('xs');
      }
    }
  }

  &__user-dropdown.ui.dropdown:not(.button) > .default.text {
    color: variables.$gray500;
  }

  &.ui.definition.table tr td:first-child:not(.ignored) {
    padding-left: 0.5rem;
    color: variables.$gray700;
    font-weight: variables.$weightRegular;
    width: mixins.calculateRemDeprecated(210px);
  }

  &__reference-table.ui.table:last-child {
    margin-bottom: 2rem;
  }

  &__user-dropdown {
    @include mixins.marginRight('xs');
  }

  &__ws-user-value {
    @include mixins.marginRight('sm');
    display: flex;
  }

  .field-value {
    .delete-icon {
      @include mixins.marginLeft('sm');
    }
  }

  .custom-field-input {
    @include mixins.marginRight('lg');
    display: inline-flex;
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;

    .ui.selection.dropdown {
      padding-right: 30px;
      width: 18rem;
      background-color: variables.$gray200;
      border-color: variables.$gray200;
      height: 2rem;
      min-height: 2rem;
      .text {
        display: block;
        padding-top: 0.1rem;
      }
      .icon {
        padding-top: 0.5rem;
      }

    }

    .remove-custom-field,
    .ui.button.delete-button.remove-custom-field {
      @include mixins.marginLeft('xs', !important);
    }
  }

  .field-value.item .multi,
  .field-value.link .multi {
    .ui.label {
      margin-right: 0.5rem;
    }
  }

  .field-value.link {
    .l_horizontal-spread {
      .custom-fields-link-span,
      .custom-fields-link-pencil {
        cursor: pointer;
      }
    }
  }

  .field-value.link {
    @include gridAutoRowWithGapOfHalfARem;
  }

  .field-value.textlist,
  .field-value.textlist.multi {
    .text-area {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas: 'input clearButton';

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .lp-custom-field-text {
        grid-area: input;
      }

      .delete-icon {
        margin-left: 0.5rem !important;
        grid-area: clearButton;
      }
    }

    .l_horizontal-spread.text {
      align-items: center;
      margin-bottom: 0.35rem;
    }
  }

  .field-value.textlist {
    .delete.icon {
      margin-top: 0.85rem;
    }
  }

  .field-value .pencil.icon,
  .field-value .delete.icon {
    margin: 0 0.5rem 0 0.5rem;
    opacity: 0.3;
    transition: opacity ease-in-out 0.5s;

    &:hover {
      opacity: 1;
    }
  }
}
