.unsupported-guest-widget-click-through {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  p {
    margin: 0;
  }

  .ui.secondary.menu {
    margin-top: 0;
  }
}
