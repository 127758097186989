@use 'style/variables';

.custom-field {
  &__row {
    &-field {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--archived {
        color: variables.$gray500;
        margin: 0.3rem;
      }

      &--sensitive {
        color: variables.$gray500;
      }
    }

    &.disabled {
      td:last-of-type {
        > div,
        span,
        button,
        form {
          opacity: 0.5;
        }
      }
    }
  }
}
