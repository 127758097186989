@use 'style/variables';

.ui.label.lp-form-error {
  color: variables.$red500;
  background-color: variables.$red100;
  border: 1px solid variables.$red500;
  font-weight: 400;
  width: 100%;
  padding: 0.75rem;
  display: flex;
  overflow-wrap: anywhere;
}
