@use 'style/mixins';
@use 'style/variables';

$animationDuration: 200ms;

@mixin initializeIconAnimation {
  transition: $animationDuration variables.$decelerateCubicBezier;
  transition-property: transform, opacity;
  transform-origin: center;
  transform: none;
  opacity: 1;
}
@mixin animateFilterIcon($lpSearchInput) {
  transition: padding $animationDuration variables.$decelerateCubicBezier;

  &:focus {
    & + #{$lpSearchInput}__filter-icon {
      transform: translateX(-25%) scale(0.8);
      opacity: 0 !important;
    }
  }
}

@mixin inputInUsePadding {
  padding-left: 0.6rem !important;
  padding-right: 3.9rem !important;
}

$lpSearchInput: '.lp-search-input';

#{$lpSearchInput} {
  grid-column: span 2 / -1;
  grid-row: 1;
  overflow: hidden;

  &.ui.left.icon.input > input {
    padding-left: 2.1rem !important;
    padding-right: 2.4rem !important;
  }

  &.ui.left.icon.input > input:focus {
    @include inputInUsePadding;
  }

  &.ui.left.icon.input > input {
    line-height: 1.5rem;
  }

  &.ui.input.dirty input {
    background-color: variables.$slate100;
  }

  &.ui.left.icon.input &__filter-icon {
    top: 7px;
    left: -1px;
    @include initializeIconAnimation;
  }

  &.ui.left.icon.input > input {
    @include animateFilterIcon($lpSearchInput);
  }

  &--has-content.ui.left.icon.input {
    :not(input:focus) {
      @include inputInUsePadding;
    }

    #{$lpSearchInput}__filter-icon {
      display: none;
    }
  }

  &-icon-container {
    display: grid;
    grid-template-columns: 1fr 1.7rem;
    align-items: center;
  }

  &__clear-icon {
    color: variables.$gray600;
    font-size: 1.2rem;

    &--no-content.svg-inline--fa {
      transform: scale(0.8) !important;
      opacity: 0 !important;
    }

    &.svg-inline--fa {
      grid-column-start: 2;
      z-index: 2;
      grid-row: 1;
      @include initializeIconAnimation;
    }

    &--disabled {
      opacity: variables.$readOnlyOpacity;
    }

    &--enabled {
      cursor: pointer;
    }
  }
}
