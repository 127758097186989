@use 'style/variables';

.unconfirmed-email {
  grid-area: unconfirmedEmail;
  background: variables.$orange500;
  padding: 0.5rem;
  color: variables.$lpBrandWhite;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'textArea sendButton' 'textArea sendButton';

  &__liquidplanner,
  &__to-email {
    font-weight: 700;
    grid-area: textArea;
  }

  &__status {
    grid-area: textArea;
    align-self: center;
  }

  &__button.ui.button {
    background: variables.$lpBrandWhite;
    color: variables.$orange500;
    grid-area: sendButton;
    align-self: center;
    white-space: nowrap;
  }
}
