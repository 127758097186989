@use 'style/mixins';
@use 'style/variables';

.invitation-header {
  display: grid;
  grid-template-rows: 2.5rem auto;
  grid-template-areas:
    'header-tile header-top header-top'
    'header-tile header-bottom header-bottom';
  margin-bottom: 1rem;
  grid-template-columns: min-content 1fr 1fr;

  &__tile {
    @include mixins.marginLeft('lg');

    margin-top: 0.5rem;
    width: 3rem;
    height: 5rem;
    background: variables.$slate700;
    border-radius: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: header-tile;

    &-icon {
      color: variables.$lpBrandWhite;
      width: 2rem !important;
    }

    &--org-dir {
      background: variables.$teal600;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    grid-area: header-top;

    &-text {
      font-size: 1.2rem;
      font-weight: variables.$weightSemiBold;
      margin-bottom: unset;
      display: inline-block;
    }
  }

  &__bottom {
    grid-area: header-bottom;
  }
}
