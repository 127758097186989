@use 'style/mixins';
@use 'style/variables';

.pm-signup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 65px;

    img {
      max-width: 185px;
    }
  }

  &__steps {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .steps {
      &__back-action {
        margin-right: 12px;
        margin-bottom: 12px;
        color: variables.$tempoCharcoal;
        font-size: 1.29rem;
        font-style: normal;
        font-weight: bold;
        line-height: 140%; /* 25.2px */
        letter-spacing: -0.72px;

        p {
          display: flex;
          align-items: center;
          height: 40px;
          cursor: pointer;
          white-space: nowrap;
        }
      }

      &__content {
        flex-grow: 1;
        min-width: min-content;
        max-width: 540px;
        margin: auto;
      }

      &__titles {
        margin-bottom: 32px;

        &--main,
        &--secondary {
          font-style: normal;
          font-weight: 400;
        }

        &--main {
          color: #202020;
          font-size: 2.5rem;
          line-height: 115%; /* 40.25px */
          letter-spacing: -1.4px;
        }

        &--secondary {
          color: var(--Neutrals-Gray-3, #343434);
          font-size: 1.29rem;
          line-height: 140%; /* 25.2px */
          letter-spacing: -0.72px;
        }
      }

      &__form {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .form {
          &__column {
            display: flex;
            flex-wrap: wrap;
            gap: 32px;
          }

          &__input-field {
            flex: 1;
            margin: 0 !important;
          }

          &__button {
            margin-top: 16px !important;
          }

          &__signup-cta {
            margin-top: 22px;
            text-align: center;
            color: variables.$tempoCharcoal;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.28px;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            text-underline-position: from-font;
          }

          &__signup-link {
            color: variables.$tempoCharcoal;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.28px;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            text-underline-position: from-font;
          }
        }
      }
    }
  }

  &__footer {
    margin-top: auto;
    color: variables.$tempoCharcoal;
  }
}
