@use 'style/mixins';
@use 'style/variables';

.ui.modal.lp-modal {
  > .header {
    border-bottom: none;
    padding-bottom: 0;
    background-color: variables.$gray100;
    color: variables.$gray700;
  }

  > .content {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: variables.$gray100;
  }

  > .actions {
    border-top: none;
    padding-top: 0;
    background-color: variables.$gray100;
  }

  &.lp-modal--centered {
    text-align: center;

    > .actions {
      text-align: center;
    }
  }
}
