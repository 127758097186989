@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';
@use 'features/dashboards_v2/mixins' as dashboardMixins;

.base-v2-widget {
  transition: all 250ms ease !important;
  transition-property: box-shadow, background-color;
  position: relative;
  will-change: transform;
  border-radius: 0.4rem;
  overflow: hidden;
  contain: layout paint;

  &:hover,
  &--overlay {
    box-shadow: 0 0 6px 1px variables.$tempoCellHoverHighlightColor, 0 0 6px 1px variables.$gray400;
  }

  &--dragging {
    opacity: 0.5 !important;
    z-index: zindex.$zIndexAction;
    pointer-events: none;
    .base-v2-widget__content {
      background-color: variables.$tempoCellHoverHighlightColor;
    }
  }

  .v2-error-widget {
    &__body {
      max-width: 30rem;
      text-align: center;
      grid-row: span 3;
      margin: 0 auto;
      border-radius: 3px;
      padding: 0.8rem;
      color: variables.$gray600;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    align-content: start;
    position: relative;
    row-gap: 0.8rem;
    background: variables.$lpBrandWhite;
    padding: 0.8rem;
    height: 100%;
    width: 100%;
    color: inherit;

    &:hover {
      color: inherit;
    }

    &-stretch {
      padding: 0;
    }
  }

  &__toolbar {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr) auto auto auto;
    align-items: center;
    column-gap: 0.75rem;
    contain: content;
    overflow: hidden;
    width: 100%;
    will-change: transform;

    &-drag-handle {
      cursor: grab;
      user-select: none;
      touch-action: none;
      @include dashboardMixins.buttonReset;

      svg {
        padding-right: 0.5rem;
      }
    }

    &-title .ui.input {
      width: 100%;

      input {
        font-weight: variables.$weightSemiBold;
        line-height: normal;
      }
    }

    &-dropdown-trigger,
    &-filter,
    &-settings {
      cursor: pointer;
      @include dashboardMixins.buttonReset;
    }

    svg {
      transition: color 250ms variables.$decelerateCubicBezier;
      color: variables.$gray500;
    }
  }

  &:hover {
    .base-v2-widget__toolbar svg {
      color: variables.$gray800;
    }
    .base-v2-widget__header-date-range,
    .base-v2-widget__header-settings-icon,
    .base-v2-widget__header-expand {
      transform: none;
      color: variables.$gray700;
    }
    .base-v2-widget__header-settings-icon--disabled {
      color: variables.$gray400;
    }
  }

  &__header {
    display: grid;
    align-items: center;
    column-gap: 0.5rem;
    font-size: 1.1rem;
    font-weight: variables.$weightSemiBold;
    grid-template-columns: 1fr min-content min-content min-content;
    pointer-events: all;
    text-align: left;
    width: 100%;

    &-date-range,
    &-expand {
      display: flex;
      transition: color 250ms variables.$decelerateCubicBezier;
      color: variables.$gray400;
    }
    &-settings {
      @include dashboardMixins.buttonReset;
      display: flex;
      cursor: pointer;
      line-height: inherit;
      &-icon {
        color: variables.$gray400;
        transition: color 250ms variables.$decelerateCubicBezier;
      }
    }

    &-title {
      @include mixins.overflowEllipsis();
    }
  }

  &__body {
    width: 100%;
    position: relative;
    contain: layout;
    overflow: hidden;
    max-height: 800px;

    .ui.image,
    img {
      max-width: 98%;
    }
  }
}
