@use 'style/mixins';
@use 'style/variables';

.create-project-in-package-modal {
  &.ui.modal {
    min-width: 35rem;

    .header {
      display: flex;
      align-items: center;
      column-gap: 0.4rem;

      &:not(.ui) {
        font-size: 1.2rem;
      }
    }

    .actions {
      > .button {
        margin-right: 0;

        &.create-project-in-package-modal__link-action {
          float: left;
          margin-left: 0;
        }
      }
    }
  }

  &__header-title {
    white-space: nowrap;
  }

  &__header-name {
    @include mixins.overflowEllipsis;
  }

  &__list {
    border: 1px solid variables.$gray300;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    background-color: variables.$lpBrandWhite;
    height: 26rem;
  }

  &__list-header {
    @include mixins.stickyHeader;
    background: variables.$gray200;
    color: variables.$lpGray800;
    line-height: 1.4rem;
    padding: 0.5rem;
  }

  &__package {
    @include mixins.overflowEllipsis;
    margin-left: 1rem;
  }

  &__package-checkbox {
    margin-right: 0.6rem;
  }

  &__package-icon {
    margin-right: 0.3rem;
  }

  &__no-packages-found {
    @include mixins.helpTextColorAndFontStyle();
  }

  &__loader {
    min-height: 26rem;
    display: flex;
    flex-direction: column;
  }
}
