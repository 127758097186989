@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.scheduling-limits-dropdown {
  z-index: zindex.$zIndexItemPanelModalAction;
  width: min-content;

  &.limit-type-dropdown .visible.menu .item {
    padding-left: 0.5rem !important;
  }

  &.ui.search.dropdown {
    display: flex;
    flex-direction: column;
  }

  &.ui.search.dropdown .visible.menu {
    position: static;
  }

  &.ui.search.dropdown.upward {
    flex-direction: column-reverse;
  }

  &.ui.search.dropdown input.search {
    position: static;
    height: 2rem;
    border-radius: 0.28571429rem;
    transition: opacity 0.1s ease;
    padding-left: 0.5rem;
    box-shadow: 0px 2px 3px 0px rgba(variables.$tableBorder, 0.15) !important;
    border-left: 1px solid rgba(variables.$tableBorder, 0.15) !important;
    border-right: 1px solid rgba(variables.$tableBorder, 0.15) !important;
    border-top: 1px solid rgba(variables.$tableBorder, 0.15) !important;
    background: variables.$lpBrandWhite !important;
  }

  &.ui.search.dropdown .divider.text {
    display: inline-flex;
    position: fixed;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}
