@use 'style/variables';
@use 'style/zindex';

vaadin-date-picker {
  --lumo-contrast-10pct: #{variables.$lpBrandWhite};
  --lumo-error-color-10pct: #{variables.$red50};
  --vaadin-field-default-width: 10rem;
  padding: 0;
  display: flex;
  border: 1px solid variables.$gray400;

  &[small] {
    --vaadin-field-default-width: 7rem;
  }

  &[inline] {
    display: inline-flex;
  }

  &[rounded] {
    border-radius: 0.3rem;
  }

  input[slot='input'] {
    border: none !important;
    line-height: revert !important;
    padding: 0 !important;
    background: transparent !important;
  }
}

vaadin-date-picker-overlay {
  z-index: zindex.$zIndexItemPanelModalAction;
}
