@use 'style/variables';

.unauthenticated__titles {
  margin-bottom: 32px;

  &--main {
    color: #202020;

    /* Headers/H3 */
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 40.25px */
    letter-spacing: -1.4px;
  }

  &--secondary {
    color: var(--Neutrals-Gray-3, #343434);

    /* Body/Body */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.72px;
  }

  &--italic {
    color: variables.$tempoCharcoal;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.72px;
  }
}
