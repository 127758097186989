@use 'style/mixins';

.base-v2-widget {
  .v2-widget-dashboard-note {
    .base-v2-widget__body {
      @include mixins.readonlyNotesScss();

      overflow: hidden;
      box-shadow: none;
      border: none;
    }
  }
}

.base-v2-widget-settings.ui.modal.v2-widget-settings-dashboard-note {
  width: 90vw;
  max-width: 1200px;
}
