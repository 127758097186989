@use 'style/mixins';
@use 'style/variables';

.base-v2-widget .v2-widget-list {
  table {
    table-layout: auto;
  }

  th,
  td {
    &:nth-of-type(6) {
      display: none;
    }
  }

  th:first-of-type {
    min-width: 250px;
  }

  th.collapsing,
  td.collapsing {
    max-width: 250px;
    @include mixins.overflowEllipsis;
  }

  .custom-column__schedule-bar {
    max-width: unset !important;
    background: variables.$gray100 !important;

    &-cell {
      display: block;
      min-width: 200px;
      padding-right: 1.25rem;
      padding-left: 0.5rem;
      pointer-events: all;
    }

    &--unscheduled {
      align-self: center;
      color: variables.$gray600;
    }
  }
}

.base-v2-widget:not(.base-v2-widget--inDesign) {
  .base-v2-widget__content {
    &.v2-widget-list {
      pointer-events: none;
    }
  }
}

.base-v2-widget--inDesign {
  .v2-widget-list {
    .item-list__name {
      pointer-events: none;
      color: variables.$slate700;
    }
  }
}

.base-v2-widget--large-width {
  .v2-widget-list {
    .custom-column__schedule-bar-cell {
      min-width: 400px;
    }

    th,
    td {
      &:nth-of-type(6) {
        display: table-cell;
      }
    }
  }
}

.base-v2-widget--small-width {
  .v2-widget-list {
    .custom-column__schedule-bar-cell {
      min-width: 150px;
    }

    th:first-of-type {
      min-width: 125px;
    }

    th,
    td {
      &:nth-of-type(4) {
        display: none;
      }
      &:nth-of-type(5) {
        display: none;
      }
      &:nth-of-type(6) {
        display: none;
      }
    }
  }
}
