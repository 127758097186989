@use 'style/mixins';

.lp-custom-field {
  &-text {
    @include mixins.marginRight('sm', !important);
  }

  &-value {
    &__multi {
      @include mixins.marginRight('lg', !important);
    }

    &__button-group {
      @include mixins.marginRight('xs', !important);

      &-button {
        height: 1.8rem !important;
        padding: 0.2rem 0.4rem !important;
        display: flex !important;
        align-items: center;
      }
    }
  }
  &__picklist-icon {
    @include mixins.marginRight('sm');
  }
}
