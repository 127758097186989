@use 'style/mixins';
@use 'style/variables';

.academy-track {
  height: 100%;
  overflow: auto;
  padding: 1rem;

  &-header {
    background: variables.$gray200;
    padding: 1rem;
    max-width: 70rem;
    margin-bottom: 2.5rem;

    h2 {
      margin: 0;
      font-weight: variables.$weightSemiBold;
    }

    &-content {
      display: grid;
      grid-template-columns: minmax(0, 32rem) auto;
      column-gap: 1rem;
    }
  }

  &-related {
    &-lessons {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      align-items: start;
      gap: 2.5rem;
    }

    &-lesson {
      display: grid;
      row-gap: 0.5rem;
      background: variables.$gray300;
      padding: 0.5rem 0.75rem;

      &__image {
        height: 100px;
        width: 180px;
        background: variables.$gray600;
      }

      &__title {
        color: variables.$gray800;
      }
    }
  }
}

.academy-track-card-preview {
  // until the admin-tool has a deterrent for this we rely on this promised aspect ration
  --productsPromisedAspectRatio: 600 / 304;
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;

  &-no-image.ui.image {
    background: variables.$gray600;
  }

  &-image.ui.image {
    grid-row-start: 1;
    grid-column-start: 1;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: calc(100% / (var(--productsPromisedAspectRatio)));
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.academy-lesson-list-item {
  display: grid;
  grid-template-areas: 'status title';
  grid-template-columns: 2rem 1fr;
  column-gap: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid variables.$gray300;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &__status {
    grid-area: status;
    color: variables.$gray600;
    display: flex;
  }
  &__title {
    grid-area: title;
    margin: 0 !important;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  &__number {
    margin-right: 0.5rem;
  }
}

.academy-track-card {
  display: grid;
  row-gap: 0.5rem;
  background: variables.$gray300;
  padding: 0.5rem 0.75rem;

  &__image {
    height: 100px;
    width: 200px;
    background: variables.$gray600;
  }

  &__title {
    color: variables.$gray800;
  }
}
