@use 'style/variables';

.ui.button.delete-button {
  height: unset !important;
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1.2rem;

  .disabled {
    color: variables.$gray500;
  }
}
