@use 'style/mixins';
@use 'style/variables';

.lp-unauthenticated {
  background: variables.$slate300;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__container {
    display: grid;
    justify-content: center;
    min-height: 100%;
    overflow: hidden auto;
    padding: 1rem;
  }

  &__content {
    display: grid;
    padding: 1rem;
    grid-template-rows: 1fr min-content;
    align-items: center;
    height: 100%;
  }

  .lp-legal-terms-footer {
    margin-top: 1rem;
  }
}
