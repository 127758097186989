@use 'style/variables';

.icon.notice {
  &.ui.label.alert {
    padding: 0.2rem 0.3rem !important;
    height: 1.3rem;
    border-radius: 3px;
    margin-right: 0;
    margin-left: 0.3rem;
  }

  &.ui.label.asap {
    cursor: default;
    margin-left: 0.3rem;
    margin-right: 0;
    font-size: 0.75rem;
    padding: 0.25rem 0.3rem 0.25rem 0.4rem;
    height: 1.3rem;
    border-radius: 3px;
    &.asap-clickable {
      cursor: pointer;
    }
  }

  &.ui.label.source-system {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    margin-left: 0.3rem;
    margin-right: 0;
    padding: 0.2rem 0.25rem 0.25rem 0.25rem;
    border-radius: 3px;
  }

  &.ui.label.scheduling-limits,
  &.ui.label.dependency {
    background: variables.$blue100 !important;
    margin-left: 0.3rem;
    margin-right: 0;
    padding: 0.2rem 0.3rem;
    height: 1.3rem;
    border-radius: 3px;
  }

  &.ui.label.waiting {
    background: variables.$gray600 !important;
    margin-left: 0.3rem;
    margin-right: 0;
    padding: 0.2rem 0.3rem;
    height: 1.3rem;
    border-radius: 3px;
  }
}

.asap-blue {
  background-color: variables.$blue300 !important;
  color: variables.$lpBrandWhite !important;
}

.asap-red {
  background-color: variables.$red600 !important;
  color: variables.$lpBrandWhite !important;
}

.asap-orange {
  background-color: variables.$lpOrange !important;
  color: variables.$lpBrandWhite !important;
}

.iteration-label {
  &.ui.basic.label {
    background-color: unset !important;
    border: none;
    margin-right: 0 !important;
  }
  &__notice {
    &.label {
      background-color: unset !important;
    }

    &-item-panel {
      svg {
        color: variables.$lpBrandWhite;
      }
    }
  }
}
