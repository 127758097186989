@use 'style/variables';

.lp-legal-terms-footer {
  color: variables.$slate700;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__tos {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}
