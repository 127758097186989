@use 'style/variables';

.task-board-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0 !important;

  &__left-menu {
    .item {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &.menu::after {
    display: none !important;
  }

  .item {
    padding-left: 0 !important;
  }

  .ui.secondary.menu &__left-menu {
    margin-bottom: 1rem;

    .item {
      padding-right: 0;
    }

    &-help-text {
      margin-left: 1rem !important;
      padding-left: 0;
    }
  }

  .ui.secondary.menu &__right-menu {
    margin-left: 0.5rem;
    margin-bottom: 1rem;

    .item {
      margin: 0 1rem 0 0;
      padding: 0;

      &:last-child {
        margin-right: 0.4rem !important;
      }
    }
  }
}
