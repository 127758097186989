@use 'style/variables';

.widget-note-click-through {
  padding: 1rem;
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: hidden;

  &__body {
    outline: 1px solid variables.$gray400;
    max-width: 1200px;
    background-color: variables.$lpBrandWhite;
    padding: 1rem;
    overflow: auto;
    height: 100%;
  }
}
