@use 'style/mixins';
@use 'style/variables';

.user-menu {
  &__trigger.ui.image.label {
    padding: 0;
    background: variables.$slate600;
    color: variables.$lpBrandWhite;
    display: flex;
    height: 2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      .user-menu__trigger-user-name {
        background: variables.$slate500;
      }

      .user-menu__trigger-icon {
        background: variables.$slate700;
      }
    }

    .user-menu__trigger-user-name {
      padding: 0.5rem 1rem;
      font-weight: 400;
      max-width: 10rem;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .user-menu__trigger-icon {
      background: variables.$slate800;
      padding: 5px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      width: 1.5rem;
    }
  }

  &__table-item {
    line-height: 1.3 !important;
    @include mixins.clickable();
    svg {
      color: variables.$trueBlack;
      opacity: 0.6;
    }
  }

  &__table.ui.vertical.text.menu {
    .header.user-menu__table-header {
      font-weight: 600;
      font-size: 1.1rem;

      &:not(:first-child) {
        margin-top: 1.5rem;
        margin-bottom: 0.3rem;
      }
    }

    .user-menu__table-item {
      margin: 0;
      padding-bottom: 0;
      display: grid;
      column-gap: 0.5rem;
      grid-template-columns: auto 1fr;

      > svg {
        align-self: center;
      }
    }
  }
  &__table-logo {
    display: block;
    margin-top: 1.5rem;
  }
}
