@use 'style/mixins';
@use 'style/variables';

.file-icon-and-name {
  display: flex;
  align-items: center;
  column-gap: 0.3rem;

  &__file-icon {
    @include mixins.marginRight('xs');
    margin-right: 0;
  }

  &__file-name--deleted,
  &__file-deleted {
    color: variables.$red500;
  }
}
