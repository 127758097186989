@use 'style/mixins';
@use 'style/variables';

.users-invite {
  overflow: hidden;
  display: grid;
  grid-template-areas: 'unconfirmedEmail unconfirmedEmail' 'inviteForm sidebar';
  grid-template-columns: minmax(0, 1fr) minmax(min-content, max-content);
  grid-template-rows: auto 1fr;
  column-gap: 1rem;
  height: 100%;

  &__unconfirmed-email {
    grid-area: unconfirmedEmail;
  }

  &__workspace-access-display-message {
    color: variables.$slate500;
    font-style: italic;
  }

  &__invitation-form {
    max-width: mixins.calculateRemRounded(600px);
    grid-area: inviteForm;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: fit-content;

    &--overflow-hidden {
      overflow: hidden;
    }

    &-content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      min-height: 5rem;
    }
  }

  &__loading-overlay {
    height: mixins.calculateRemRounded(180px);
  }

  .sidebar {
    grid-area: sidebar;
    padding: 1.5rem 0.75rem;
    background: variables.$gray400;
  }
}

.confirm-invite {
  @include mixins.mainContentStyle();
  padding: 0;

  &__scroll-element {
    overflow: auto;
    margin-bottom: 1rem;
  }
}

.users-invite,
.confirm-invite {
  .ui.form &__input-field.field {
    min-height: 7rem;
    margin-bottom: 0;
  }

  &__input-field,
  &__input-field .ui.label.lp-form-error {
    max-width: 32rem;
  }

  &__error.ui {
    &.message {
      min-height: unset;
    }
    &.label {
      margin-bottom: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__footer {
    text-align: right;

    .ui.button {
      @include mixins.marginLeft('sm');
    }
  }

  &__table.ui.table {
    td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  &__current-workspace-logged-in {
    cursor: pointer;
  }

  &__workspace-checkbox-wrapper.ui.right.labeled {
    align-items: center;
    width: 1rem !important;
  }

  &__workspace-checkbox-wrapper.ui.checkbox {
    margin: 0.5rem 0 0.5rem 0;
  }

  &__sent {
    max-width: mixins.calculateRemDeprecated(600px);
  }
}

.pending-invite {
  grid-area: users-table;
  height: unset;
  padding-bottom: unset;
  max-width: mixins.calculateRemRounded(1000px);
  overflow-x: hidden;

  &__title {
    max-width: mixins.calculateRemRounded(1000px);

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__include-expired-button {
    &.ui.button {
      margin: 0;
    }

    &--active.ui.button {
      background: variables.$slate700;
      color: variables.$lpBrandWhite;
    }

    &__text {
      display: flex;
    }
  }

  &-table.ui.table {
    margin-bottom: 0;
    color: variables.$gray700;

    tr {
      &:hover {
        @include mixins.tableRowHoverStyles;
      }
      .expired {
        color: variables.$lpRed;
      }
    }

    th {
      @include mixins.stickyHeader;
      color: variables.$gray700;
      font-weight: normal;
    }
  }

  &-not-active {
    color: variables.$riskyRed;
  }

  &-guest-label {
    color: variables.$gray500;
    white-space: nowrap;
    margin-left: 0.25rem;
    svg {
      margin-left: 0.25rem;
    }
  }
}
