@use 'style/mixins';
@use 'style/variables';

.pm-forgot-password {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__steps {
    display: flex;
    justify-content: center;
    width: 100%;

    .steps {
      &__back-action {
        a {
          color: variables.$tempoCharcoal;
          font-size: 1.29rem;
          font-style: normal;
          font-weight: bold;
          line-height: 140%; /* 25.2px */
          letter-spacing: -0.72px;
        }
      }

      &__content {
        max-width: 540px;
        min-width: 430px;
        margin: auto;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
