@use 'style/mixins';
@use 'style/variables';

$defaultDashboardMaxWidth: 1800px;

.v2-dashboards-view {
  display: grid;
  row-gap: 0.75rem;
  align-content: start;
  padding: 1rem;
  background: variables.$lpBrandWhite;
  overflow-y: auto;
  overflow-x: hidden;
  contain: strict;

  &--loader {
    align-content: center;
  }

  &__sub-header {
    display: grid;
    row-gap: 0.5rem;
    max-width: $defaultDashboardMaxWidth;
    padding: 0.75rem;
    border-radius: 0.4rem;

    &-container {
      border-radius: 0.4rem;
      background: variables.$lpBrandWhite;
      color: variables.$gray700;
    }

    &-text {
      display: block;
      max-width: 70ch;
      margin: 0 auto;
      background-color: variables.$gray200;
      padding: 0.75rem;
      border-radius: 0.4rem;
      margin-top: 1rem;
      text-align: center;
    }
  }

  &__header {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: auto min-content;
    align-items: center;
    max-width: $defaultDashboardMaxWidth;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: 0.4rem;
    background-color: variables.$gray200;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

    @media screen and (min-height: 750px) {
      @include mixins.stickyHeader(-17px);
    }

    &--in-design {
      background: variables.$slate400;
    }

    &-info {
      display: flex;
      align-items: center;
      overflow: hidden;
      column-gap: 0.5rem;
    }

    &-icon {
      font-size: 1.25rem;
      border-radius: 0.5rem;
      color: variables.$lpBrandWhite;
      display: flex;
      align-items: center;
      padding: 0.5rem;
    }

    &-title {
      @include mixins.overflowEllipsis();
      margin: 0;
    }

    &-text {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      column-gap: 1rem;
      color: variables.$lpBrandWhite;
      font-style: italic;

      svg {
        padding-right: 0.25rem;
      }

      &-icon {
        margin-left: 0.5rem;
        font-size: 1.4rem;
      }
    }

    &-actions {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      column-gap: 1rem;

      .ui.button {
        display: flex;
        align-items: center;
        height: 2rem !important;
        color: variables.$lpBrandWhite;
        margin: 0;

        &:hover {
          color: variables.$lpBrandWhite;
        }
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: minmax(105px, max-content);
    align-content: start;
    gap: 1rem;
    max-width: $defaultDashboardMaxWidth;
    width: 100%;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__drag-overlay {
    contain: layout size;
    will-change: transform;
  }

  &__footer {
    padding-bottom: 2rem;
  }
}
