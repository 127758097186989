@use 'style/variables';

$numHeight: 1.2rem;

.lp-nub {
  width: 10px;
  height: $numHeight;
  position: relative;

  &__content {
    align-self: end;
    color: variables.$lpBrandWhite;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: $numHeight;

    &--empty {
      height: 2px;
      width: 2px;
      margin-right: 6px;
      margin-left: 4px;
      border-radius: 0px;
      background-color: variables.$gray500;
    }
  }

  &__hover {
    background: variables.$lpBrandWhite;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.4));
    font-size: 0.95rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
}
