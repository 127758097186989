@use 'style/mixins';
@use 'style/variables';

.lp-loader-item-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  font-size: 1.5rem;
  color: variables.$slate600 !important;

  &__grid {
    $gridSize: mixins.calculateRemDeprecated(80px);
    display: inline-block;
    position: relative;
    width: $gridSize;
    height: $gridSize;
    background: transparent;

    &-item {
      position: absolute;
      width: mixins.calculateRemDeprecated(16px);
      height: mixins.calculateRemDeprecated(16px);
      border-radius: 50%;
      background: variables.$lpBrandBlue;
      animation: lp_loader_item_panel 1.2s linear infinite;
    }

    &-item-0 {
      top: mixins.calculateRemDeprecated(8px);
      left: mixins.calculateRemDeprecated(8px);
      animation-delay: 0s;
    }
    &-item-1 {
      top: mixins.calculateRemDeprecated(8px);
      left: mixins.calculateRemDeprecated(32px);
      animation-delay: -0.4s;
    }
    &-item-2 {
      top: mixins.calculateRemDeprecated(8px);
      left: mixins.calculateRemDeprecated(56px);
      animation-delay: -0.8s;
    }
    &-item-3 {
      top: mixins.calculateRemDeprecated(32px);
      left: mixins.calculateRemDeprecated(8px);
      animation-delay: -0.4s;
    }
    &-item-4 {
      top: mixins.calculateRemDeprecated(32px);
      left: mixins.calculateRemDeprecated(32px);
      animation-delay: -0.8s;
    }
    &-item-5 {
      top: mixins.calculateRemDeprecated(32px);
      left: mixins.calculateRemDeprecated(56px);
      animation-delay: -1.2s;
    }
    &-item-6 {
      top: mixins.calculateRemDeprecated(56px);
      left: mixins.calculateRemDeprecated(8px);
      animation-delay: -0.8s;
    }
    &-item-7 {
      top: mixins.calculateRemDeprecated(56px);
      left: mixins.calculateRemDeprecated(32px);
      animation-delay: -1.2s;
    }
    &-item-8 {
      top: mixins.calculateRemDeprecated(56px);
      left: mixins.calculateRemDeprecated(56px);
      animation-delay: -1.6s;
    }

    @keyframes lp_loader_item_panel {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
}

.lp-loader-item-panel::after {
  border-color: variables.$gray600 transparent transparent !important;
}
