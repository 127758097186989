@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.ui.search.selection.dropdown.task-status-dropdown {
  z-index: zindex.$zIndexMenu;

  .item {
    padding-left: mixins.calculateRemDeprecated(5.2px) !important;
  }

  div {
    white-space: nowrap;
    text-overflow: ellipsis;

    .icon {
      @include mixins.marginRight('xs');
    }

    .task-status-dropdown__customize {
      color: variables.$blue400;
    }
  }
}
