@use 'style/mixins';
@use 'style/variables';

.avatar {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  &-size {
    &__xs {
      @include mixins.avatarSizeInRems(1.1, !important);
      font-size: 0.7rem !important;
    }

    &__xs-1-2 {
      @include mixins.avatarSizeInRems(1.2, !important);
      font-size: 0.7rem !important;
    }

    &__small-1-5 {
      @include mixins.avatarSizeInRems(1.5, !important);
      font-size: 0.6rem !important;
    }

    &__small-2 {
      @include mixins.avatarSizeInRems(2, !important);
      font-size: 0.7rem !important;
    }

    &__small-2-4 {
      @include mixins.avatarSizeInRems(2.4, !important);
      font-size: 0.9rem !important;
    }

    &__med {
      @include mixins.avatarSizeInRems(3, !important);
      font-size: 1.2rem !important;

      svg {
        font-size: 1.5rem !important;
      }
    }

    &__med-4 {
      @include mixins.avatarSizeInRems(4, !important);
      font-size: 1.5rem !important;

      svg {
        font-size: 2rem !important;
      }
    }

    &__med-4-5 {
      @include mixins.avatarSizeInRems(4.5, !important);
      font-size: 1.5rem !important;

      svg {
        font-size: 2rem !important;
      }
    }

    &__large-7-5 {
      @include mixins.avatarSizeInRems(7.5, !important);
      font-size: 2.5rem;
    }

    &__large-9 {
      @include mixins.avatarSizeInRems(9, !important);
      font-size: 3rem;
    }

    &__xl {
      @include mixins.avatarSizeInRems(13, !important);
      font-size: 3rem;
    }
  }

  &__round {
    border-radius: 50% !important;
  }

  &__border-radius {
    border-radius: 3px !important;
  }

  &__margin-none {
    margin-right: 0 !important;
  }

  &__margin-right-xs {
    @include mixins.marginRight('xs', !important);
  }
  &__margin-right-sm {
    @include mixins.marginRight('sm', !important);
  }
}
