@use 'style/mixins';
@use 'style/variables';

.insight-progress-bar {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;

  &__percent {
    color: variables.$gray700;
    font-weight: bolder;
  }
}
