@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

:root {
  --clipped-icon-width: 23px;
  --target-start-shadow-width: 23px;
  --dependency-icon-width: 1rem;
  --done-icon-width: 1rem;
  --tick-width: 3px;
  --icon-spacing: 3px;
  --min-scheduled-width: 3px;
}

$animation-time: 0.5s;

@mixin dependencyIcon {
  color: variables.$gray600;
  content: '\22EF'; //midline horizontal ellipsis
  font-weight: 900;
  font-size: 18px;
  position: absolute;
  z-index: zindex.$zIndexBase;
}

.lp-schedule {
  background: variables.$lpBrandWhite;
  border: mixins.calculateRemDeprecated(1px) solid variables.$alphaTableBorder;
  overflow: hidden;
  padding: 1rem 1.7rem;
  width: 100%;

  &--fetching {
    opacity: 0.6;
  }
  &--clipped {
    padding-right: calc(
      var(--clipped-icon-width) + var(--icon-spacing) + var(--tick-width) + var(--min-scheduled-width)
    );
  }

  &__item-panel-constraint {
    max-width: mixins.calculateRemDeprecated(800px);
  }

  &__target-dates {
    $targetDatesCount: 6;
    height: mixins.calculateRemDeprecated(18px);
    position: relative;

    /* This generates height rules, which corresponds to the possible target labels we display.
       The labels are positioned, so we set a height so the schedule bar is moved down the right number of 'lines'.
       1 line per label.
       If we add more target labels, increase this iterator so the schedule section is sized to fit.*/
    @for $i from 1 through $targetDatesCount {
      &--height#{$i} {
        height: mixins.calculateRemDeprecated(18px * $i);
      }
    }

    &-row {
      position: absolute;
      bottom: mixins.calculateRemDeprecated(3px);
      height: 100%;
      &.rollup,
      &.effective {
        color: variables.$gray500;
      }

      /* This is related to the function above, related to how many target dates we display.*/
      @for $i from 1 through $targetDatesCount {
        &:nth-child(#{$i}) {
          $marginTop: mixins.calculateRemDeprecated(16px * ($i - 1));
          margin-top: $marginTop;
          height: calc(100% - #{$marginTop});
        }
      }
    }

    &-start {
      $start: &;
      &--left {
        border-left: mixins.calculateRemDeprecated(1px) solid variables.$gray600;
        padding-left: mixins.calculateRemDeprecated(5px);
        &#{$start}--effective {
          border-left: mixins.calculateRemDeprecated(1px) solid variables.$gray600;
        }
      }
      &--right {
        border-right: mixins.calculateRemDeprecated(1px) solid variables.$gray600;
        padding-right: mixins.calculateRemDeprecated(5px);
        text-align: right;
        &#{$start}--effective {
          border-right: mixins.calculateRemDeprecated(1px) solid variables.$gray600;
        }
      }
    }
    &-finish {
      $finish: &;
      color: variables.$trueBlack;
      &--left {
        border-left: mixins.calculateRemDeprecated(3px) solid variables.$trueBlack;
        padding-left: mixins.calculateRemDeprecated(5px);
        &#{$finish}--effective {
          border-left: mixins.calculateRemDeprecated(1px) solid variables.$trueBlack;
        }
      }

      &--right {
        border-right: mixins.calculateRemDeprecated(3px) solid variables.$trueBlack;
        padding-right: mixins.calculateRemDeprecated(5px);
        text-align: right;
        &#{$finish}--effective {
          border-right: mixins.calculateRemDeprecated(1px) solid variables.$trueBlack;
        }
      }
    }
    &-start,
    &-finish {
      &--rollup,
      &--effective {
        color: variables.$gray500;
        border-color: variables.$gray400;
      }
    }
  }

  &__bar {
    position: relative;
    height: 1.2rem;
    width: 100%;
    display: flex;

    &-element {
      position: absolute;
      align-self: center;
      height: 100%;
      display: flex;
      align-items: center;

      &.on-hold,
      &.item-done {
        width: 1rem;
      }

      &.start-shadow {
        margin-left: calc(var(--target-start-shadow-width) + var(--icon-spacing)) !important;
      }

      &.clipped-effort {
        color: variables.$lpRed;
        z-index: zindex.$zIndexFocus;
        &--inherited {
          color: variables.$red300;
        }
        &--no-finish {
          left: var(--tick-width);
        }
      }

      svg {
        color: variables.$gray600;
        height: 100%;
        width: 100%;
      }

      &--ancestor svg {
        color: variables.$gray700;
      }

      &--late svg {
        color: variables.$lpRed;
      }

      &--predecessor::before,
      &--successor::after {
        @include dependencyIcon;
      }

      &--predecessor::before {
        left: -1.25rem;
      }
      &--successor::after {
        right: -1.25rem;
      }

      &--predecessor-ancestor::before,
      &--successor-ancestor::after {
        color: variables.$gray700;
      }
      &--predecessor-late::before,
      &--successor-late::after {
        color: variables.$lpRed;
      }
    }

    &-range {
      background: variables.$gray600;
      border-radius: 0.2rem;
      transition: width $animation-time ease-in-out, margin-left $animation-time ease-in-out;

      &--ancestor {
        background: variables.$gray700;
      }

      &--not-complete {
        background: variables.$orange500;
      }
    }

    &-asap {
      background: variables.$gray500;
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
    }

    &-late {
      background: variables.$lpRed;
      border-radius: 0.2rem;
    }

    &-risk {
      border: 3px solid transparent;
      min-width: var(--min-scheduled-width);
      transition: width $animation-time ease-in-out, margin-left $animation-time ease-in-out;
      &-inlay {
        background: variables.$lpBrandWhite;
        border-radius: 0.125rem;
        height: 100%;
        width: 100%;
      }
    }

    &-target-line,
    &-effective-line {
      height: 66.7%;
    }

    &-target-line {
      background: variables.$red200;
      &--assignment {
        background: variables.$gray400;
        width: 100%;
      }
    }

    &-effective-line {
      background: variables.$gray400;
      &--start-shadow {
        width: var(--target-start-shadow-width) !important;
        background: linear-gradient(to right, variables.$gray400, transparent);
      }
    }

    &-target-finish {
      height: 140%;
      width: var(--tick-width);
      background-color: variables.$trueBlack;

      &--rollup {
        background-color: variables.$gray400;
      }
    }

    &--debug {
      display: block;
      height: calc(1rem + (2 * (2rem * 0.66))) !important;

      .lp-schedule__bar {
        &-element {
          position: relative;
          height: 2rem;
          &.item-done {
            margin-top: -1rem;
          }
          &.clipped-effort {
            margin-top: -2.4rem;
            display: block;
          }
        }

        &-target-line,
        &-effective-line {
          height: 2rem * 0.66;
          margin-top: -0.5 * (2rem * 0.66);
        }

        &-target-line {
          margin-top: mixins.calculateRemDeprecated(5px);
        }

        &-range {
          margin-top: -0.25 * (2rem * 0.66);
        }

        &-late {
          margin-top: -2rem;
        }

        &-risk {
          height: 2rem;
          margin-top: -2rem;
        }

        &-target-finish {
          height: 125%;
          margin-top: calc(-4rem - #{mixins.calculateRemDeprecated(5px)});
        }
      }
    }

    .assignment-done,
    .assignment-on-hold,
    .assignment-placeholder,
    .assignment-tracking-only,
    .assignment-needs-attention {
      align-self: center;
      color: variables.$gray600;
    }

    .assignment-done {
      &__button.ui.button {
        background: variables.$gray500;
        color: variables.$lpBrandWhite;
        font-size: 1rem;
        height: 2rem;
        padding: 0.5rem 1rem;
        margin-right: 0.7rem;

        &:hover {
          background: variables.$gray600;
        }
      }
    }
  }

  &__labels {
    &-start,
    &-expected,
    &-latest,
    &-done {
      &--left {
        border-left: mixins.calculateRemDeprecated(1px) solid variables.$gray600;
        padding-left: mixins.calculateRemDeprecated(5px);
      }
      &--right {
        border-right: mixins.calculateRemDeprecated(1px) solid variables.$gray600;
        padding-right: mixins.calculateRemDeprecated(5px);
        text-align: right;
      }
    }

    &-start,
    &-done {
      margin-top: mixins.calculateRemDeprecated(5px);
    }

    &-expected {
      padding-top: mixins.calculateRemDeprecated(19px);
      margin-top: mixins.calculateRemDeprecated(-19px);
    }

    &-latest {
      padding-top: mixins.calculateRemDeprecated(39px);
      margin-top: mixins.calculateRemDeprecated(-39px);
    }

    &-expected,
    &-latest {
      &-date {
        @include mixins.marginRight('xs');
        color: variables.$gray700;
        &--late {
          color: variables.$lpRed;
        }
      }
    }
  }
}

.schedule-bar-tooltip {
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: variables.$lpBrandWhite;
  display: flex;
  align-items: center;

  &.normal {
    background-color: variables.$gray600;
  }

  &.not-complete {
    background-color: variables.$orange400;
  }

  &.tracking-only {
    background-color: variables.$gray600;
  }

  &.clipped {
    background-color: variables.$red400;
  }

  &__icon {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }
}

.lp-item-panel__planning-content .lp-schedule__bar {
  height: 2rem;

  &-element.on-hold {
    width: 1.25rem;
  }

  &-element.item-done {
    width: 2rem;
    left: -0.5rem;
  }

  &-element--predecessor::before,
  &-element--successor::after {
    font-size: 1.25rem;
  }

  &-element--predecessor::before {
    left: -1.65rem;
  }
  &-element--successor::after {
    right: -1.65rem;
  }
}

.lp-assignment-list__data-table-header-row-rollup,
.lp-assignment-list__data-table-row-cell-schedule {
  .lp-schedule__bar-target-line {
    height: 100% !important;
  }
}
