
.file-upload-modal {
  .file-upload-content.content {
    height: 150px;

    span {
      display: block;
      text-align: center;
      margin: 20px;
      font-size: 20px;
    }
  }
}