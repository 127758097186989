@use 'style/mixins';

.people-grid {
  &__username-link {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    &--active-link {
      pointer-events: all;
    }

    &--disconnected-user {
      @include mixins.evictedUser();
    }
  }
}
