@use 'style/variables';

.icon.lp-item-icon {
  &.events:not(.custom-color) {
    color: variables.$gray500;
  }
}

.ui.menu:not(.vertical) {
  .ui.button.favorite-button {
    padding: 5px;
  }
}

.icon.lp-item-icon.assignments {
  font-size: 8px;
}
