@use 'style/mixins';
@use 'style/variables';

.pm-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 65px;

    img {
      max-width: 185px;
    }
  }

  &__content {
    flex-grow: 1;
    min-width: min-content;
    max-width: 540px;
    margin: auto;

    &--password-reset {
      display: flex;
      flex-direction: column;
      margin-top: 260px;
      align-items: center;
      gap: 24px;
    }

    .password-reset {
      &__title {
        color: #202020;
        text-align: center;

        /* Headers/H3 */
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 115%; /* 40.25px */
        letter-spacing: -1.4px;
      }
      &__sign-in-link {
        margin-top: 54px;
        text-align: center;

        color: variables.$tempoCharcoal;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.28px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
    }

    .form {
      &__column {
        display: flex;
        gap: 32px;
      }

      &__input-field {
        flex: 1;
        margin: 0 !important;
        color: variables.$tempoCharcoal;

        &:first-child {
          margin-bottom: 32px !important;
        }

        &.error {
          margin: 0;
        }

        .ui.input {
          border: none;
          height: 57px;

          input {
            border: 1px solid variables.$tempoCharcoal;
            border-radius: 0% !important;
            padding: 0;
            padding-left: 24px !important;

            &[aria-invalid='true'],
            &[aria-invalid='true']:focus {
              background: none;
            }
          }
        }
      }

      &__checkbox {
        .ui.checkbox {
          label {
            color: variables.$tempoCharcoal;
            font-size: 1.29rem;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 25.2px */
            letter-spacing: -0.72px;

            &::before {
              width: 18px;
              height: 18px;
              background-color: #ffffff;
              border-radius: 2px;
              border: 1px solid #343434;
            }
          }
        }

        .ui.checked.checkbox {
          label {
            &::before {
              background-color: variables.$tempoCharcoal;
            }
          }
        }
      }

      &__forgot-password {
        text-align: right;
        margin-top: 12px;
      }
      &__forgot-password-link {
        color: variables.$tempoCharcoal;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        letter-spacing: -0.28px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }

      &__actions {
        display: flex;
        flex-direction: column;
        gap: 16px;

        span {
          color: #58595b;
          text-align: center;
          font-size: 1.29rem;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 25.2px */
          letter-spacing: -0.72px;
        }
      }

      &__signup-cta {
        margin-top: 54px;
        text-align: center;

        color: variables.$tempoCharcoal;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.28px;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;

        a {
          color: variables.$tempoCharcoal;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.28px;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }
      }

      &__button {
        margin-top: 16px !important;

        &--secondary {
          display: flex;
          justify-content: center;
          align-content: center;
          background: #fff !important;
          color: variables.$tempoCharcoal !important;
          border: 1px solid variables.$tempoCharcoal;
          margin-top: 0px !important;
          padding: 16px 32px;
          color: variables.$tempoCharcoal;
          font-size: 1.29rem;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 25.2px */
          letter-spacing: -0.72px;

          &::before {
            margin-right: 8px;
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9InRhYmxlci1pY29uLWtleSI+CjxnIGlkPSJWZWN0b3IiPgo8cGF0aCBkPSJNMTYuNTU1IDQuMzQyODFMMjAuMTU3IDcuOTQ0ODFDMjAuNDI0MiA4LjIxMTk3IDIwLjYzNjIgOC41MjkxNCAyMC43ODA4IDguODc4MjNDMjAuOTI1NCA5LjIyNzMxIDIwLjk5OTggOS42MDE0NiAyMC45OTk4IDkuOTc5MzFDMjAuOTk5OCAxMC4zNTcyIDIwLjkyNTQgMTAuNzMxMyAyMC43ODA4IDExLjA4MDRDMjAuNjM2MiAxMS40Mjk1IDIwLjQyNDIgMTEuNzQ2NiAyMC4xNTcgMTIuMDEzOEwxNy41MTQgMTQuNjU2OEMxNy4yNDY4IDE0LjkyNCAxNi45Mjk3IDE1LjEzNiAxNi41ODA2IDE1LjI4MDZDMTYuMjMxNSAxNS40MjUyIDE1Ljg1NzMgMTUuNDk5NiAxNS40Nzk1IDE1LjQ5OTZDMTUuMTAxNyAxNS40OTk2IDE0LjcyNzUgMTUuNDI1MiAxNC4zNzg0IDE1LjI4MDZDMTQuMDI5MyAxNS4xMzYgMTMuNzEyMiAxNC45MjQgMTMuNDQ1IDE0LjY1NjhMMTMuMTQ0IDE0LjM1NThMNi41ODYgMjAuOTEzOEM2LjI1MzcyIDIxLjI0NiA1LjgxNTA3IDIxLjQ1MDcgNS4zNDcgMjEuNDkxOEw1LjE3MiAyMS40OTk4SDRDMy43NTUwNyAyMS40OTk4IDMuNTE4NjYgMjEuNDA5OSAzLjMzNTYzIDIxLjI0NzFDMy4xNTI1OSAyMS4wODQzIDMuMDM1NjYgMjAuODYwMSAzLjAwNyAyMC42MTY4TDMgMjAuNDk5OFYxOS4zMjc4QzMuMDAwMTEgMTguODU4MyAzLjE2NTQzIDE4LjQwMzcgMy40NjcgMTguMDQzOEwzLjU4NiAxNy45MTM4TDQgMTcuNDk5OEg2VjE1LjQ5OThIOFYxMy40OTk4TDEwLjE0NCAxMS4zNTU4TDkuODQzIDExLjA1NDhDOS41NzU4IDEwLjc4NzYgOS4zNjM4NCAxMC40NzA1IDkuMjE5MjMgMTAuMTIxNEM5LjA3NDYyIDkuNzcyMzEgOS4wMDAxOSA5LjM5ODE2IDkuMDAwMTkgOS4wMjAzMUM5LjAwMDE5IDguNjQyNDYgOS4wNzQ2MiA4LjI2ODMxIDkuMjE5MjMgNy45MTkyM0M5LjM2Mzg0IDcuNTcwMTQgOS41NzU4IDcuMjUyOTcgOS44NDMgNi45ODU4MUwxMi40ODYgNC4zNDI4MUMxMi43NTMyIDQuMDc1NjEgMTMuMDcwMyAzLjg2MzY1IDEzLjQxOTQgMy43MTkwNEMxMy43Njg1IDMuNTc0NDMgMTQuMTQyNyAzLjUgMTQuNTIwNSAzLjVDMTQuODk4MyAzLjUgMTUuMjcyNSAzLjU3NDQzIDE1LjYyMTYgMy43MTkwNEMxNS45NzA3IDMuODYzNjUgMTYuMjg3OCA0LjA3NTYxIDE2LjU1NSA0LjM0MjgxWiIgZmlsbD0iIzFEMUQxRCIvPgo8cGF0aCBkPSJNMTUgOS41SDE1LjAxSDE1WiIgZmlsbD0iIzFEMUQxRCIvPgo8L2c+CjxjaXJjbGUgaWQ9IkVsbGlwc2UgNDIwNCIgY3g9IjE1IiBjeT0iOS41IiByPSIxIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPgo=');
          }
        }
      }
    }
  }
}
