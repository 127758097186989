@use 'style/mixins';

.guest-widget-grid {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;

  &__menu.ui.borderless.secondary {
    margin-bottom: 0;
  }

  .left.menu {
    .item {
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .right.menu {
    .item {
      padding-top: 0 !important;
      padding-right: 0 !important;
    }
  }
}
