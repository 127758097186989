@use 'style/variables';
@use 'style/zindex';
@use 'features/dashboards_v2/mixins' as dashboardMixins;

.widget-group {
  display: grid;
  row-gap: 0.75rem;
  align-content: start;
  background: variables.$gray200;
  border-radius: 0.4rem;
  height: 100%;
  padding: 0.75rem;
  z-index: zindex.$zIndexBase;
  will-change: transform;
  transition: all 250ms ease !important;
  transition-property: box-shadow, background-color;

  &--design {
    &:hover {
      background-color: variables.$tempoCellHoverHighlightColor;
    }
  }

  &--hidden .widget-group-body {
    height: 0;
    pointer-events: none;
    contain: strict;
  }

  &--dragging {
    opacity: 0.5 !important;
    z-index: zindex.$zIndexAction;
    background-color: variables.$tempoCellHoverHighlightColor;
  }

  &--overlay {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  &__title-header {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  &-toolbar {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    align-items: center;
    column-gap: 0.75rem;
    contain: content;
    overflow: hidden;
    will-change: transform;
    transition: all 250ms ease !important;

    &-drag-handle {
      cursor: grab;
      user-select: none;
      touch-action: none;
      @include dashboardMixins.buttonReset;

      svg {
        padding-right: 0.5rem;
      }
    }

    &-title .ui.input {
      width: 100%;

      input {
        font-weight: variables.$weightSemiBold;
        line-height: normal;
      }
    }

    &-dropdown-trigger,
    &-filter {
      cursor: pointer;
      @include dashboardMixins.buttonReset;
    }

    &-dropdown {
      svg {
        color: variables.$gray700;
      }
    }

    svg {
      color: variables.$gray700;
    }
  }

  &-body {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }

  &-add-widget {
    @include dashboardMixins.buttonReset;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;
    color: variables.$lpBrandWhite;
    background: variables.$slate600;
    padding: 0.5rem 1rem;
    justify-self: start;
    border-radius: 3px;
    transition: 300ms variables.$decelerateCubicBezier;
    transition-property: background;

    &:hover {
      background: variables.$slate500;
    }
  }
}
