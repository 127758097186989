@use 'style/variables';
@use 'style/zindex';

/* ---------- Profile Header Avatar ----------- */
.avatar-edit-menu {
  &__permission-denied-container,
  &__upload-container {
    height: 13rem;
    width: 13rem;
  }

  &__upload-container:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 13rem;
    width: 13rem;
    border-radius: 3px;
    background-color: rgba(variables.$slate700, 0.7);
    opacity: 0;
    transition: opacity 0.45s ease;
  }

  &__upload-container {
    &:hover {
      &::after {
        opacity: 1;
      }

      .avatar-edit-menu__fa-icon {
        color: white;
        display: block;
        visibility: visible;
        position: absolute;
        top: 0;
      }
    }
  }

  &__dropdown,
  label,
  span {
    flex: 1;
    padding: 1rem 3rem 1rem 0.5rem;
    cursor: pointer;
  }

  &__dropdown,
  label span:first-child {
    padding-left: 0.3rem;
  }

  &__cropper-modal {
    height: auto !important;
    width: auto !important;
    max-height: 60%;
    max-width: 60%;

    .ReactCrop .ReactCrop__child-wrapper img {
      object-fit: contain;
      max-height: 400px;
    }
  }

  &__fa-icon {
    z-index: zindex.$zIndexFocus;
    margin: 60% 0 0 40%;
    position: absolute;
    visibility: hidden;
  }

  .visible.transition {
    display: flex !important;
    flex-direction: column;

    .item {
      display: flex !important;
    }
    div.item {
      padding: 0 !important;
    }
  }
}
