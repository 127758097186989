@use 'style/variables';

.lp-include-done-button {
  width: 9rem;

  &--active {
    background: variables.$slate700 !important;
    color: variables.$lpBrandWhite !important;
  }

  &__text {
    display: flex;
  }
}
