@use 'style/variables';

.lp-custom-data-filter-modal {
  &.ui.modal {
    max-height: 42rem;
    max-width: 86rem;
    width: 80% !important;
    min-width: 50rem;
  }

  .lp-custom-data-filter-modal-content {
    display: grid;
    gap: 1rem;
    grid-auto-columns: minmax(12rem, 23.5rem) auto;
    grid-auto-flow: column;
    background: variables.$gray100;
    padding: 1rem;
    border: 1px solid variables.$gray500;
    box-shadow: inset 0 0 5px variables.$gray300;
  }

  &__actions-section {
    display: flex;
    justify-content: space-between;

    &-cancel-button.ui.button {
      margin-right: 1rem;
    }

    &-apply-button.ui.button {
      margin-right: 0;
    }
  }
}
