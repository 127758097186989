@use 'style/mixins';
@use 'style/variables';

.lp-panel-sections .changes-tabs .ui.bottom.attached.segment.active.tab {
  width: unset;
  height: unset;
}

.lp-change {
  display: flex;
  padding: 0.5rem;

  &:hover {
    background-color: variables.$tempoCellHoverHighlightColor;
    .lp-change__date {
      @include mixins.textWeightBold();
      background: variables.$gray400;
      color: variables.$gray700;
    }
  }

  &__date {
    width: 7rem;
    max-width: 7rem;
    min-width: 7rem;
    background: variables.$gray200;
    margin-right: 1rem;
    padding: 0.5rem;
    color: variables.$gray600;
    line-height: normal;

    &-time {
      font-size: 0.8rem;
    }
  }

  &__card {
    padding: unset !important;
    padding-top: 0.5rem !important;
    margin: unset !important;
    line-height: 1.4rem !important;
    overflow: hidden;

    &-content {
      &-info {
        min-height: 3.1rem;
        margin-left: 4rem !important;
      }

      &-change-description {
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 0.2rem;
        grid-template-columns: max-content max-content auto;
        margin-bottom: 0.3rem;
        @include mixins.textWeightSemiBold;
      }

      &-name {
        display: inline-block;
      }

      &-description {
        color: variables.$gray700 !important;
        margin-left: 0.3rem !important;
        font-size: 1rem !important;
      }

      &-description-info-link,
      &-description-icon {
        margin-left: 0.2rem !important;
        margin-right: 0.2rem !important;
      }

      &-description-info-item {
        &-name {
          font-style: italic;
        }
        padding-left: 3px;
        font-weight: variables.$weightSemiBold;
        @include mixins.overflowEllipsis;

        &:first-child {
          padding-left: 0;
        }
      }

      &-table {
        &-simple-description-cell {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: max-content auto;
          align-items: center;
          column-gap: 0.5rem;
          margin-bottom: 0.6rem;
          @include mixins.textWeightNormal;
        }

        &-update-change-cell {
          display: flex;
          column-gap: 0.5rem;
          align-items: center;
          margin-bottom: 0.6rem;
        }

        .change-property {
          &__name {
            text-transform: uppercase;
            color: variables.$gray600;
          }

          &__description {
            color: variables.$gray800;
            @include mixins.overflowEllipsis;

            &-origin {
              display: grid;
              grid-template-columns: repeat(3, min-content);
              column-gap: 0.3em;
            }
          }

          &__old-description {
            color: variables.$gray600;
            max-width: 70%;

            @include mixins.overflowEllipsis;
          }

          &__new-description {
            color: variables.$gray800;
            max-width: 70%;

            @include mixins.overflowEllipsis;
          }
        }
      }
    }

    .ui.avatar.image.avatar {
      width: 2.4rem;
      height: 2.4rem;
      font-size: 0.8rem;
    }

    &-image {
      margin: 0 !important;
    }
  }

  &__show-more.ui.button {
    margin-left: 2px;
  }
}
