@use 'style/variables';
@use 'style/zindex';
@use 'style/mixins';

.lp-filepond {
  position: relative;
  height: inherit;

  .filepond--drop-label {
    transition: 200ms;

    &:hover {
      background-color: variables.$tempoCellHoverHighlightColor;
    }
  }

  .filepond--panel-top,
  .filepond--panel-bottom,
  .filepond--drop-label,
  .filepond--wrapper {
    border-radius: 3px;
  }

  .filepond--list-scroller {
    background-color: red !important;
  }

  .filepond--label-action {
    @include mixins.textWeightSemiBold();
    text-decoration: none;
    color: variables.$blue400;
  }

  .filepond--wrapper {
    width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px dashed variables.$gray500;
  }

  .filepond--root {
    margin: 0;
  }

  &--warning {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: zindex.$zIndexItemPanelModalAction;
    border-radius: 0.4rem;
    background: variables.$orange500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transform-origin: top center;

    &-text {
      padding: 0.25rem 0.5rem;
      color: variables.$lpBrandWhite;
      font-weight: variables.$weightSemiBold;
      white-space: nowrap;
    }

    &-timer {
      height: 0.3rem;
      border-radius: 0.3rem;
      margin: 0 0.2rem 0.2rem 0.2rem;
      background: variables.$lpBrandWhite;
      transform-origin: left center;
    }
  }
}
