.ui.avatar.image.user-dropdown {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1em;
  display: inline-block;
}

.icon.user-dropdown {
  font-size: 1.5em;
  display: inline-block;
  margin-right: 0.7em;
}

.ui.search.selection.dropdown {
  min-width: 18em;
  height: 2.3rem;
}
