@use 'style/mixins';
@use 'style/variables';

.reset-password {
  background-color: variables.$gray100;
  padding: 2rem;
  @include mixins.baseBoxShadow();

  &__header-block {
    text-align: center;
    margin-bottom: 1rem;
  }

  &__header.ui.header {
    font-size: 1.5rem;
  }

  &__sub-header-text {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  &__initial-fetch-errors {
    font-size: 1.8rem;
  }


}
