@use 'style/mixins';
@use 'style/variables';

@mixin unsetWidgetMaxHeight() {
  .base-v2-widget__body {
    max-height: unset;
  }
}

@mixin widgetPropertiesAndMetrics() {
  &__body {
    display: grid;
    grid-template-columns: minmax(10rem, max-content) auto;
    grid-auto-rows: minmax(0, max-content);
    column-gap: 0.2rem;
    width: 100%;
    background: variables.$gray200;

    &-row {
      &-aside,
      &-content {
        padding: 0.5rem;
        background: variables.$lpBrandWhite;
      }

      &-aside {
        text-align: end;
        color: variables.$gray600;
      }

      &-content {
        white-space: pre-line;
        color: variables.$gray700;
        @include mixins.textWeightSemiBold;

        &--range {
          color: variables.$gray500;
        }
      }
    }
  }
}

@mixin minimumWidthWidgetPropertiesAndMetrics() {
  &__body {
    grid-template-columns: 1fr;
    row-gap: 0rem;
    background: variables.$lpBrandWhite;
    &-row {
      &-aside,
      &-content {
        padding: 0rem 0.5rem;

        svg {
          display: none;
        }
      }

      &-aside {
        border-top: 0.2rem solid variables.$gray200;
        text-align: start;
        padding-top: 0.7rem;
      }
      &-content {
        min-height: 2rem;
        padding-bottom: 0.7rem;
      }
    }
  }
}
