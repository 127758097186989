@use 'style/variables';

.ui.button.manage-columns-button {
  &--modified {
    background: variables.$slate700;
    color: variables.$lpBrandWhite;

    &:hover {
      background: variables.$slate600;
      color: variables.$lpBrandWhite;
    }
  }
}
