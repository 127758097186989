@use 'style/mixins';
@use 'style/variables';

@mixin workloadRailNub {
  height: 0.6rem;
  margin-top: 0.4rem;
  border-radius: 2px;
}

.workload-rail {
  display: flex;
  --workload-rail-left-width: 20;

  &__before-range-arrow {
    margin-right: 4px;
  }

  &__after-range-arrow {
    margin-left: 4px;
  }

  &__on-schedule {
    min-width: var(--workload-rail-left-width);
    &-nub {
      background-color: variables.$gray700;
      @include workloadRailNub();
    }
  }

  &__effective-finish-date {
    padding-left: 4px;
    padding-right: 4px;
  }
  &__schedule-risk {
    flex-grow: 1;
    height: 1rem;
    &-nub {
      background-color: variables.$red500;
      @include workloadRailNub();

      &--effectiveDateAfterEndDate {
        background-color: variables.$gray700;
      }
    }
  }
}
