@use 'style/mixins';
@use 'features/dashboards_v2/widget/common/mixins' as widgetMixins;

.widget-metrics-tally {
  @include widgetMixins.widgetPropertiesAndMetrics();

  &__body-row-content {
    .custom-column__description,
    .custom-column__multiline-text {
      @include mixins.multiLineOverflow(5);
    }
  }
}

.base-v2-widget--small-width .widget-metrics-tally {
  @include widgetMixins.minimumWidthWidgetPropertiesAndMetrics();
}

.v2-widget-metrics-tally {
  @include widgetMixins.unsetWidgetMaxHeight;
}

.v2-widget-settings-metrics-tally {
  .metrics-select-actions {
    column-gap: 0.5rem;
    display: flex;
  }
}
