@use 'style/variables';

.pm-legal-terms-footer {
  margin-top: auto;
  @media screen and (max-height: 900px) {
    & {
      margin-top: 54px;
    }
  }
  color: variables.$tempoCharcoal;

  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
  letter-spacing: -0.28px;

  &__link {
    color: variables.$tempoCharcoal;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.28px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}
