@use 'style/zindex';
@use 'style/variables';

.lp-portal-dropdown-trigger {
  &.ui.dropdown,
  .ui.dropdown {
    border: none !important;
    box-shadow: none !important;

    &:focus-within {
      outline: 2px solid variables.$blue200 !important;
      outline-offset: -2px;
    }
  }
}

.lp-portal-dropdown {
  z-index: zindex.$zIndexItemPanelModalAction;

  &.simple {
    .divider.text,
    .dropdown.icon {
      display: none;
    }

    .ui.dropdown {
      background: transparent;
      .visible.menu {
        margin: 24px 0;
      }
    }
  }

  &.upward .ui.dropdown {
    flex-direction: column-reverse;

    .visible.menu.transition {
      flex-direction: column-reverse;
    }
  }

  .ui.dropdown {
    display: flex !important;
    flex-direction: column;
    height: unset !important;
    border: none !important;
    box-shadow: none !important;
    min-width: unset !important;

    .visible.menu.transition {
      display: flex !important;
      flex-direction: column;
      top: 0;
      border: none;
      position: relative;
      overflow-y: auto;
      box-shadow: 0 2px 5px 2px rgb(34 36 38 / 20%) !important;

      .item {
        flex: 1 0 auto;
      }
    }
  }
}
