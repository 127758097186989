@use 'style/variables';

.disconnect-modal {
  &.ui.small.modal {
    width: 28rem;
  }

  &__error {
    margin-top: 1rem;
    color: variables.$riskyRed;
  }
}
