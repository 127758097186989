@use 'style/zindex';

.lp-schedule-directive-dropdown {
  &.ui.selection.dropdown {
    &.visible {
      z-index: zindex.$zIndexMenu;
    }

    .ui.horizontal.label {
      width: 4rem;
    }

    .dropdown.icon {
      padding-top: 0.5rem;
    }

    .menu > .item {
      padding: 0.4rem 0.3rem !important;
    }
  }
}
