@use 'style/mixins';
@use 'style/variables';

.item-picker-modal {
  &__header {
    @include mixins.overflowEllipsis;
    font-weight: normal;
    font-size: 1.2rem;
    color: variables.$gray700;

    &-action {
      font-weight: variables.$weightSemiBold;
      @include mixins.marginRight('sm');
    }

    &-item-name,
    &-icon {
      font-size: 1rem;
    }

    &-item-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      @include mixins.marginRight('xs');
    }
  }

  &__content {
    &-parent {
      &.ui.basic.disabled.button {
        margin-top: 1rem;
        opacity: 1 !important;
        border: 1px solid variables.$gray300;
        border-bottom: none;
        text-align: left;
        height: 2.5rem;
        padding-left: 0.5rem;
        background: variables.$gray200 !important;
        white-space: nowrap;
        overflow: hidden;
        color: variables.$lpGray800 !important;
        text-overflow: ellipsis;

        span {
          color: variables.$gray700;

          &:first-child {
            margin-right: 0.2rem;
            font-weight: bold;
          }
        }

        .lp-item-icon {
          margin-right: 0 !important;
        }
      }

      &-folder-status-icon {
        margin-right: 0.2rem !important;
      }
    }

    &-controls {
      display: grid;
      grid-template-columns: min-content 1fr min-content;
      column-gap: 1rem;

      &-move-up-tree {
        background: variables.$slate700 !important;
        color: variables.$lpBrandWhite !important;
        min-width: 3rem;
      }

      &-search {
        width: 100%;
      }

      .checkbox {
        padding-top: 1.5rem;
        min-width: 11rem !important;
      }

      &-include-done span {
        white-space: nowrap;
      }
    }

    &-error {
      color: variables.$gray700;
      float: left;
      font-style: italic !important;
    }

    &-move-targets {
      display: block;
      border: 1px solid variables.$gray300;
      background: variables.$lpBrandWhite;
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 16.5rem;
      max-height: 15rem;

      button {
        @include mixins.overflowEllipsis;
        margin-left: 2.5rem !important;
        width: 94% !important;
        text-align: left !important;
        color: variables.$gray300 !important;

        svg {
          margin-right: 0.3rem !important;
        }
      }
    }
  }

  &__actions {
    padding-top: 1rem;

    &-single-button {
      &.ui.button {
        margin-right: 0;
      }
    }

    &-group-buttons {
      &.ui.buttons {
        margin-left: 0.7rem;
      }
    }
  }
}
