@use 'style/mixins';
@use 'style/variables';

.assignees_column {
  &--complete {
    color: variables.$gray500;
    font-style: italic;
  }

  &--evicted-user {
    @include mixins.evictedUser();
  }
}
