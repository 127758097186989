.slack-integration-configuration {
  display: grid;
  gap: 2rem;
  grid-template-columns: 100%;
  grid-auto-rows: min-content;
  overflow: auto;
  padding: 2rem;

  &__connect-to-slack-section {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-gap: 0.5rem;
    align-items: center;
  }

  &__connect-to-slack-button {
    &,
    &.ui.button:not(.dropdown) {
      align-items: center;
      display: inline-flex;
      justify-content: space-between;
      height: auto;
    }

    &__icon,
    &.ui.button:not(.icon) &__icon {
      margin-right: 1rem;
    }
  }

  &__disable-modal-text {
    &--italic {
      font-style: italic;
    }
  }

  &__notification-settings-form {
    align-items: start;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto;
    grid-template-rows: min-content 1fr;
    width: fit-content;
    column-gap: 3rem;

    &__heading {
      margin-top: 0;
    }

    &__checkbox-group {
      list-style: none;
      margin-top: 0;
      padding-left: 1rem;
    }
  }

  &__notices {
    list-style-position: inside;
    margin-top: 0;
    padding-left: 0;
  }
}
