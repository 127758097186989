@use 'style/mixins';
@use 'style/variables';

.custom-field-formatter {
  &__empty {
    color: variables.$gray400;
  }

  &__picklist-icon {
    @include mixins.marginRight('sm');
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &--inherited {
    opacity: variables.$readOnlyOpacity;
  }
}
