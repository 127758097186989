@use 'style/mixins';
@use 'style/variables';

.lp-top-nav {
  background-color: variables.$lpBrandWhite;
  border: none;
  box-shadow: 0 1px 2px 0 variables.$alphaTableBorder;
  display: grid;
  grid-template-areas: 'avatar favorite breadcrumb user-menu' 'avatar menu menu menu';
  grid-template-columns: auto auto 1fr auto;
  padding-bottom: 0.85rem;
  padding-left: 1rem;
  padding-top: 0.5rem;

  &__avatar {
    grid-area: avatar;

    .avatar {
      margin-left: 0;
    }
  }

  &__favorite {
    grid-area: favorite;
    display: grid;
    align-items: center;

    .ui.button {
      height: auto !important;
      margin: 0;

      &:not(.icon) .svg-inline--fa {
        margin-right: 0;
        padding-left: 0.25rem;
        padding-right: 0.5rem;
      }
    }
  }

  &__breadcrumb {
    grid-area: breadcrumb;
    display: grid;
    align-items: center;
    @include mixins.overflowBreadcrumbGradient();

    .ui.breadcrumb {
      .divider {
        margin-right: 5px;
      }
      .switch-org {
        margin-left: 0.5rem;
        font-size: 1rem;
        .clickable {
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }
      }
    }
  }

  &__user-menu {
    grid-area: user-menu;
    display: grid;
    grid-auto-flow: column;
    column-gap: 0.5rem;
    align-items: center;
    margin-right: 1rem;

    .ui.button {
      display: flex;
      align-items: center;
      height: 2rem !important;
      background-color: variables.$slate600;
      color: variables.$lpBrandWhite;
      margin: 0;

      &:hover {
        background: variables.$slate500;
        color: variables.$lpBrandWhite;
      }
    }
  }
  &__menu {
    grid-area: menu;

    &.ui.secondary.pointing.menu {
      border-bottom: none;
      margin: 0;
      margin-left: 0.2rem;
      margin-bottom: -0.75rem;
      min-height: unset;

      .item {
        margin-left: 0;
        margin-right: 1.5rem;
      }
      .fitted.item:first-child {
        margin-left: 0;
      }
    }
  }
}
