@use 'style/mixins';
@use 'style/variables';

.go-back-to-dashboard-button {
  max-width: 20rem;

  &__text {
    line-height: 1.4rem;
    @include mixins.overflowEllipsis;
  }

  .item &.ui.button {
    display: flex;
    align-items: center;
    background-color: variables.$orange500;
    padding-left: 9px; // product wants this exact px count to center over the status filter button in the table below

    &:hover {
      background-color: variables.$orange400;
    }
  }

  .menu .item .ui.button &__icon {
    color: variables.$lpBrandWhite;
    margin-right: 0.5rem;
  }
}
