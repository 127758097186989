.story-point-dropdown {
  &__option {

    &--archived {
      span.text {
        font-style: italic;

        &:after {
          padding-left: .5rem;
          content: "Archived";
          font-size: 0.8em;
        }
      }
    }

    &--previous-scheme {
      span.text {
        font-style: italic;

        &:after {
          padding-left: .5rem;
          content: "Previous Scheme";
          font-size: 0.8em;
        }
      }
    }

  }
}