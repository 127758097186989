.password-form {
   &__api-error.ui.error.message {
    margin-top: 1rem;
  }

  &__form {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
