@use 'style/variables';

.v2-widget-workload {
  &__sub-header {
    font-size: 0.9rem;
    color: variables.$gray600;
    font-weight: 400;
  }

  .ui.basic.table {
    border: none;
  }

  .ui.table tbody tr,
  .ui.table tbody tr td {
    border: none !important;
  }

  .ui.definition.table tr > td {
    font-weight: 400 !important;
    color: variables.$slate800 !important;
  }

  .ui.table &__row {
    td {
      border-top: none;
    }

    &-nubs {
      border-left: none !important;
    }
  }
}
