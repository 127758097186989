#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

/*------------------------------------------------------------------------------
  Width
------------------------------------------------------------------------------*/

.l_width-auto {
  &.ui.table {
    width: auto;
  }
}

/*------------------------------------------------------------------------------
  Floating
------------------------------------------------------------------------------*/

.l_float-right {
  float: right;
}

/*------------------------------------------------------------------------------
  Margin / Padding
------------------------------------------------------------------------------*/

.l_no-margin {
  margin: 0 !important;
}

/*------------------------------------------------------------------------------
  Text transforms
------------------------------------------------------------------------------*/

.l_capitalize {
  text-transform: capitalize;
}

/*------------------------------------------------------------------------------
  Flex Layout
------------------------------------------------------------------------------*/

.l_flex-horizontal-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.l_flex-horizontal-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.l_vertical-spread {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.l_horizontal-spread {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.l_horizontal-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.l_horizontal-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l_flex-justify {
  display: flex;
  justify-content: stretch;
  flex: 1;
}

.l_no-grow {
  flex: 0;
}

.l_grow {
  flex: 1;
}

.l_vertical-center {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > *,
  *.ui {
    margin-top: 0;
    margin-bottom: 0;
  }
}
