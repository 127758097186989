@use 'style/variables';

.dashboard-passport {
  width: 70rem;
  margin: 1rem;
  overflow-y: auto;
  overflow-x: hidden;

  .lp-search-input-icon-container {
    width: 25rem;
  }

  &-table.ui.table.compact {
    margin-bottom: 1rem;

    thead.dashboard-passport-table__organization-header {
      tr th {
        background-color: variables.$slate600;
        color: variables.$lpBrandWhite;
      }
    }

    thead tr th.dashboard-passport-table__workspace-header {
      padding-left: 1.5rem;
    }

    td.dashboard-passport-table__dashboard-name {
      padding-left: 2.5rem;
    }
    & .lp-action-ellipsis {
      width: unset;
    }
  }
}
