.unathenticated {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 65px;

    img {
      max-width: 185px;
    }

    .header {
      &__pager {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
