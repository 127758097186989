@use 'style/mixins';
@use 'style/variables';
@use './src/features/dashboards_v2/mixins' as dashboards;

@mixin clickArea {
  cursor: pointer;
  width: 100%;
  border: 1px solid variables.$gray300;
  border-radius: 0.3rem;
  padding: 0.3rem 0.5rem;
}

.intake-widget-user-form-section {
  display: grid;
  row-gap: 1rem;
  grid-auto-rows: min-content;

  &__sub-section {
    display: grid;
    row-gap: 1rem;
    grid-auto-rows: min-content;

    &-header {
      display: grid;
      row-gap: 0.5rem;

      &-title {
        @include mixins.textWeightSemiBold;
        font-size: 1.1rem;
        color: variables.$gray700;
      }

      &-description {
        @include mixins.helpTextColorAndFontStyle();
      }
    }

    &-table.ui.table {
      margin: 0;

      .disabled.field {
        opacity: unset;
      }

      &.definition {
        tr td {
          padding: 0.25rem 0.5rem;

          &:first-child:not(.ignored) {
            width: 15rem;
            @include mixins.textWeightNormal;
            color: variables.$gray700;
          }

          .intake-form-item-picker {
            display: flex;
            column-gap: 1rem;

            &__selected-item-click-area {
              @include clickArea;
            }
          }

          .intake-form-custom-field-multi {
            display: flex;
            align-items: center;
            column-gap: 0.3rem;

            .field {
              margin-bottom: 0;
            }

            &__selected-item-click-area {
              @include clickArea;
            }

            &__icon {
              cursor: pointer;

              &--margin-left {
                margin-left: auto;
              }

              &--disabled {
                opacity: 0.3;
              }
            }

            &__picklist,
            &__user,
            &__item,
            &__text {
              display: grid;
              row-gap: 0.5rem;
            }

            &__picklist,
            &__text,
            &__user {
              grid-template-columns: 20rem 20rem 20rem;
              column-gap: 0.7rem;
              align-items: center;
              justify-items: start;

              &--display-only {
                column-gap: 0;
                grid-template-columns: 19rem 19rem 19rem;
              }
            }

            &__text {
              .intake-form-custom-field-input {
                width: 18rem;
              }
            }

            &__item-select {
              width: 5rem;
            }
          }

          .intake-form-custom-field-input {
            width: 100%;
          }

          .lp-schedule-directive-dropdown,
          .work-limit-input {
            width: 100%;
          }
        }
      }
    }

    &-table-tinymce {
      padding: 0 !important;

      &--disabled {
        opacity: 0.4;
        border-top: 1px solid variables.$gray500 !important;
      }

      &--error {
        padding: 1rem !important;
        background: variables.$red100 !important;

        .tox.tox-tinymce {
          border: 1px solid variables.$gray500 !important;
        }
      }

      .tox.tox-tinymce {
        border: none;
      }
    }

    &-table-clear-button.ui.button {
      @include dashboards.buttonReset;
    }

    &-table-clear-icon {
      margin-right: 0 !important;
    }
  }

  &__field-date {
    &[invalid] {
      outline: 1px solid variables.$red500;
    }
  }

  &__field-link {
    width: 18rem;
  }

  &__field-dropdown {
    .ui.dropdown,
    &.ui.dropdown {
      background: variables.$gray200;
      max-width: 18rem;

      &:not(.visible) {
        border: 1px solid rgba(34, 36, 38, 0.15) !important;
      }

      &.error {
        outline: 1px solid variables.$red500;
        background: variables.$red50;

        .default.text {
          color: variables.$red500;
          opacity: 0.2;
        }
      }
    }
    &.error > .ui.dropdown {
      outline: 1px solid variables.$red500;
      background: variables.$red50;

      .default.text {
        color: variables.$red500;
        opacity: 0.2;
      }
    }
  }
}
