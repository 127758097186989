.item-list-row {
  display: grid;
  grid-template-columns: minmax(1rem, 100%) max-content;

  &__name {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
  }
  &__labels {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
  }
}
