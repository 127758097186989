@use 'style/variables';

// Extra-specific selector to override the default styles in
// lp_dark_theme/site/elements/button.overrides
.lp-call-to-action.ui.button:not(.dropdown) {
  color: variables.$lpBrandWhite;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: variables.$weightSemiBold;
  height: auto;
  margin: 0;
  padding: 0.6rem 1rem;
  text-align: center;

  &:hover {
    color: variables.$lpBrandWhite;
  }
}
