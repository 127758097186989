@use 'style/mixins';
@use 'style/variables';

.plan-gadget {
  &.ui.table {
    border: 0;
    border-collapse: collapse;
  }

  &.ui.table td {
    padding: 0.5rem;
    border: 0;
  }

  &__sidebar {
    grid-area: sidebar;
    padding: 1.5rem 0.75rem;
    background: variables.$slate500;
    p {
      font-style: italic;
      color: variables.$lpBrandWhite;

      a {
        color: variables.$lpBrandWhite;
        text-decoration: underline;
        @include mixins.textWeightBold();
      }
    }
  }

  &__header {
    background-color: variables.$slate700;
    color: variables.$lpBrandWhite;
  }

  &.ui.table td#{&}__icon-cell {
    text-align: center;
    width: 2rem;
    padding: 0.5rem 0;
  }

  &.ui.table &__description-cell {
    padding-left: 0;
  }
}
