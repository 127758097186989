@use 'style/mixins';
@use 'style/variables';
@use 'features/nova/nova_variables';

.nova-top-nav {
  border: none;
  display: grid;
  grid-template-areas:
        "breadcrumb breadcrumb"
        "title buttons";
  grid-template-columns:  1fr auto;
  gap: 0.5rem;
  padding: 2.25rem 4.5rem 0.85rem 2.75rem;
  background: nova_variables.$grey1;

  &__breadcrumb {
    grid-area: breadcrumb;
    display: grid;
    align-items: center;

    .ui.breadcrumb {
      .divider {
        margin-right: 5px;
      }
      .switch-org {
        margin-left: 0.5rem;
        font-size: 1rem;
        .clickable {
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }
      }
    }
  }
  &__buttons {
    grid-area: buttons;
    display: grid;
    column-gap: 0.5rem;
    align-items: center;
    margin-right: 1rem;
  }
  &__title-section {
    grid-area: title;
    font-size: 20px;
    color: variables.$tempoCharcoal;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    &-divider {
      color: #e4e4e4;
    }

    &-details {
      color: #757574;
      display: flex;
      gap: 0.5rem;
    }
  }
}
