@use 'style/mixins';
@use 'style/variables';

@mixin manageAccountButton {
  background-color: variables.$orange500;
  color: variables.$lpBrandWhite;

  &:hover {
    background-color: variables.$orange400;
    color: variables.$lpBrandWhite;
  }
}

@mixin manageAccountButtonDowngrade {
  background-color: variables.$slate700;
  color: variables.$lpBrandWhite;

  &:hover {
    background-color: variables.$slate500;
    color: variables.$lpBrandWhite;
  }
}

@keyframes priceChangeColorFlash {
  0% {
    background: transparent;
  }
  50% {
    background: variables.$orange200;
  }
  100% {
    background: transparent;
  }
}

.manage-account {
  @include mixins.mainContentStyle;
  background-color: variables.$gray200;
  padding: 1rem;

  &--max-width {
    @include mixins.maxWidth('sm');
    position: relative;

    .ui.active.dimmer {
      opacity: 0.6;
      cursor: wait;
    }
  }

  &__change-times {
    @include mixins.helpTextColorAndFontStyle();

    &-icon {
      @include mixins.marginRight('sm');
    }
  }

  &__plan {
    display: grid;
    column-gap: 1rem;
    grid-auto-columns: 1fr min-content;
    grid-auto-flow: column;
    margin-bottom: 1rem;

    &_section {
      background-color: variables.$gray300;
      padding: 1rem;

      &-details {
        &.ui.table {
          border: 0;
          margin-top: 0;
          tr td {
            border: 1px solid variables.$gray400;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
            min-width: 10rem;

            &:first-of-type {
              background-color: variables.$gray200;
              width: 10rem;
            }
            &:last-of-type {
              text-align: center;
            }
          }
        }

        &-row-description {
          display: flex;
          align-items: center;
        }

        &-row-icon {
          font-size: 1.2rem;
          @include mixins.marginRight('xs');
        }
      }

      &-title {
        display: inline-block;
        font-size: mixins.calculateRemRounded(22px);
        margin-bottom: 0.5rem;
      }
    }

    &-call-to-action {
      width: 15rem;
      text-align: center;
      background-color: variables.$slate800;
      color: variables.$lpBrandWhite;
      padding: 1rem;

      &-icon {
        margin-bottom: 1rem;
      }

      &-text {
        margin-bottom: 0.5rem;
        padding: 0 1rem;
      }

      &-button.ui.button {
        width: 100%;
        background-color: variables.$blue300;
        color: variables.$lpBrandWhite;

        &:hover {
          background-color: variables.$blue200;
          color: variables.$lpBrandWhite;
        }
      }
    }
  }

  &__rates {
    background-color: variables.$gray300;
    padding: 1rem;
    margin-bottom: 1em;

    &-header {
      &-label {
        font-size: mixins.calculateRemRounded(22px);
        text-transform: uppercase;
        display: inline;
      }
      &-link {
        float: right;
        max-width: 22%;
      }
      &-btns {
        column-gap: 0.5rem;
        display: grid;
        float: right;
        grid-auto-flow: column;
      }
    }
    &-footer {
      ul {
        margin-bottom: 0;
      }
    }
    &-details {
      &.ui.table {
        border: none;

        th {
          background-color: variables.$slate500;
          border: 1px solid variables.$lpBrandWhite;
          color: variables.$lpBrandWhite;
          text-align: center;
          text-transform: uppercase;
        }

        td {
          text-align: center;

          .lighter {
            color: variables.$slate500;
          }
        }
      }
      &-features {
        td {
          padding-left: 5rem !important;
          text-align: left !important;
          vertical-align: top;
          p {
            font-weight: variables.$weightSemiBold;
          }
          .fa-check,
          .fa-plus {
            margin-right: 0.5rem;
          }
        }
      }
      &-not-available {
        color: variables.$gray600;
      }
      &-counts,
      &-price {
        font-weight: variables.$weightSemiBold;
      }
      &-savings {
        color: variables.$orange400;
        display: block;
        font-style: italic;
        font-weight: variables.$weightSemiBold;
      }
    }

    &-upgrade-btn.ui.button {
      @include manageAccountButton();
    }

    &-downgrade-btn.ui.button {
      @include manageAccountButtonDowngrade();
    }
  }

  &-change-plan {
    @include mixins.mainContentStyle;
    padding: 1rem;

    &--max-width {
      @include mixins.maxWidth('xs');
      position: relative;

      .ui.active.dimmer {
        opacity: 0.6;
        cursor: wait;
      }
    }

    &__header {
      background-color: variables.$orange500;
      color: variables.$lpBrandWhite;
      column-gap: 1rem;
      display: grid;
      grid-template-columns: 50% auto;
      padding: 1rem;
      row-gap: 1rem;
      &--downgrade {
        background-color: variables.$slate700;
      }
      &-title {
        align-items: center;
        display: flex;
        span {
          @include mixins.marginLeft('sm');
          font-weight: variables.$weightSemiBold;
        }
      }
      &-disclaimer {
        font-weight: normal;
        font-style: italic;
        align-self: center;
        justify-self: end;
      }
    }

    &-form {
      background-color: variables.$gray300;
      column-gap: 1rem;
      display: grid;
      grid-template-columns: 30% 30% auto;
      padding: 1rem;
      row-gap: 1rem;
      margin-bottom: 1rem;

      .price {
        font-weight: variables.$weightSemiBold;
      }
      &__separator {
        align-self: center;
        grid-column: span 3;
        height: 2px;
        width: 100%;
        background-color: variables.$gray400;
        border: none;
      }

      &__current-plan-label,
      &__new-plan-label,
      &__current-license-label,
      &__new-license-label {
        align-self: center;
        justify-self: end;
        font-weight: variables.$weightSemiBold;
      }
      &__current-plan-family,
      &__current-plan-billing-period,
      &__new-plan-family,
      &__new-plan-billing-period {
        .ui.selection.dropdown {
          max-width: 100%;
        }
      }
      &__current-license-input,
      &__new-license-input {
        align-self: center;
        grid-column: span 2;

        .ui.input {
          width: mixins.calculateRemDeprecated(80px);
          margin-right: 1rem;
        }
      }

      &__new-license-input {
        align-items: center;
        column-gap: 1rem;
        display: grid;
        grid-template-columns: mixins.calculateRemDeprecated(80px) auto;
        &--disabled {
          grid-template-columns: auto;
        }
      }

      &__info-area {
        color: variables.$gray700;
        grid-column: 2 / span 2;
        display: grid;
        grid-template-rows: 1.5rem;
      }

      &__info-snippet {
        display: grid;
        column-gap: 1rem;
        grid-template-columns: 1rem auto;
        font-style: italic;
      }

      &__disclaimer {
        grid-column: span 2;
        align-self: center;
        &--downgrade {
          color: variables.$orange500;
          font-weight: variables.$weightSemiBold;
        }
      }

      &__button.ui.button {
        @include manageAccountButton();
        padding-left: 2rem;
        padding-right: 2rem;
        font-weight: variables.$weightSemiBold;
        align-self: center;
        justify-self: end;
        grid-column: 3;
      }
      &__button--downgrade.ui.button {
        @include manageAccountButtonDowngrade();
      }
    }

    &__resend-email-banner {
      margin: 0 0 1rem 0;
    }
  }
}
