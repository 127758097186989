.error-page {
  &__unknown-error.ui.message {
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: 2rem;
  }

  &__unknown-error-icon {
    height: 5rem;
    display: flex;
  }

  &__unknown-error-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.ui.grid > .error-page {
  &__column.column:not(.row) {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
}
