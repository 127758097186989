@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

$spacing: 1rem;
$maxLeftTabWidthSmallScreen: 8rem;
$maxLeftTabWidth: mixins.calculateRemDeprecated(220px);
$minLeftTabWidth: mixins.calculateRemDeprecated(150px);
$minContentWidth: mixins.calculateRemDeprecated(680px);

.tabbed-modal {
  &.ui.modal {
    display: flex !important;
    flex-direction: column;
    height: 85% !important;
    width: 95% !important;
    min-width: calc(#{$minLeftTabWidth} + #{$minContentWidth});
    max-width: mixins.calculateRemDeprecated(1500px);

    @include mixins.minSizeMediaQuery {
      height: 100% !important;
      width: 100% !important;
      max-width: unset;
      margin: 0 !important;
      border-radius: 0;
    }

    > .header,
    > .content,
    > .actions {
      padding: 0;
    }

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $spacing;
      background: variables.$gray100;

      // this is a semantic override for modal headers.
      &:not(.ui) {
        font-weight: 400;
      }
    }

    > .content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      background: variables.$gray100;
      overflow: auto;
    }

    > .actions {
      display: flex;
      align-items: center;
      padding: 0.75rem $spacing;
      background: variables.$gray100;
    }

    .ui.grid {
      margin: 0;
      height: 100%;
      flex-wrap: nowrap;

      .four.wide.column {
        padding: 0;
        background: variables.$slate700;
        max-width: $maxLeftTabWidth;
        min-width: $minLeftTabWidth;
        overflow-y: auto;

        .ui.fluid.vertical.menu {
          border-radius: 0;
          background: variables.$slate700;
          box-shadow: 0 0 0 0;
          border-bottom: 1px solid rgba(208, 208, 208, 0.1);
          border-top: none;
          border-left: none;
          border-right: none;

          svg {
            margin: 0;
          }

          .item {
            @include mixins.overflowEllipsis;
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 0.3rem;

            &::before,
            &::after {
              background: rgba(208, 208, 208, 0.1);
            }

            &:hover {
              background: variables.$slate500;
            }

            &.active {
              background: variables.$slate600;
            }

            .planning-labels,
            .scheduling-labels {
              display: flex;
            }
          }

          a {
            color: variables.$lpBrandWhite;
            border-radius: 0;
          }
        }
      }

      .stretched.twelve.wide.column {
        padding: 0;
        height: 100%;
        border-bottom: 1px solid rgba(variables.$tableBorder, 0.25);
        flex: 1;
      }
    }

    .parent {
      color: variables.$lpBrandWhite;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 5px 0 8px 15px;
    }
  }
}
