@use 'style/mixins';
@use 'style/variables';

.lp-navigation-buttons {
  display: flex;
  flex-wrap: wrap;

  &__button {
    &.ui.button {
      transition: 300ms variables.$defaultCubicBezier;
      transition-property: filter;
      filter: none;

      &:not(.dropdown) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        margin: 0 1rem 1rem 0;
        background-color: variables.$slate700;
        color: variables.$lpBrandWhite;
        height: 8rem;
        width: 14rem;
      }
      &:hover {
        filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.3)) saturate(1.1) opacity(0.9);
      }
    }
  }

  &__icon {
    margin-bottom: 0.5rem;
  }

  &__text {
    word-spacing: 0.2rem;
    font-size: 0.9rem;
    line-height: 1.5;
    width: 80%;
    margin: 0 auto;
  }

  &__sub-text {
    font-weight: 600;
  }

  &__button-percentage {
    display: inline-block;
    width: 80%;
    margin-top: 1rem;

    &-bar {
      padding: 0.2rem !important;
      background: variables.$lpBrandWhite !important;
      font-size: 0.6rem !important;
    }
  }
}
