@use 'style/mixins';
@use 'style/variables';

@font-face {
  font-family: 'Messina Sans';
  src: url('static/fonts/messina-sans-variable.woff2') format('woff2');
}

@font-face {
  font-family: 'Messina Serif';
  src: url('static/fonts/messina-serif-variable.woff2') format('woff2');
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Messina Sans', 'Messina Serif', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
}

.pm-unauthenticated {
  background: variables.$lpBrandWhite;

  &__container {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr min-content;
  }

  &__content {
    min-width: 380px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    padding: 24px 46px;

    & > div {
      flex-grow: 1;
    }
  }

  &__aside {
    @media screen and (max-width: 780px) {
      & {
        display: none;
      }
    }
    img {
      max-height: 100vh;
    }
  }

  .lp-legal-terms-footer {
    margin-top: 1rem;
  }

  // Overrides for semantic-react
  .form {
    &.ui {
      input[type='text'],
      input[type='tel'],
      input[type='email'],
      input[type='password'] {
        font-size: 1.21rem;
        font-family: 'Messina Sans', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;
      }

      .field.error {
        .menu {
          border-color: variables.$tempoCharcoal !important;
        }
      }
    }
    .field {
      > label {
        color: variables.$tempoCharcoal !important;
        font-size: 1.29rem !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 140% !important; /* 25.2px */
        letter-spacing: -0.72px !important;
        margin-bottom: 8px !important;
      }
    }
    .error {
      margin: 0;
    }

    .ui.input {
      border: none;
      height: 57px;
      padding-left: 0 !important;

      input {
        color: variables.$tempoCharcoal !important;
        border: 1px solid variables.$tempoCharcoal !important;
        border-radius: 0% !important;
        padding: 0;
        padding-left: 24px !important;
        font-family: 'Messina Sans', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif !important;

        &[aria-invalid='true'],
        &[aria-invalid='true']:focus {
          background: none !important;
        }
      }
    }

    i.dropdown.icon {
      top: 30%;

      &::before {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNoZXZyb24iPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNNiA5TDEyIDE1TDE4IDkiIHN0cm9rZT0iIzFEMUQxQiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPC9nPgo8L3N2Zz4K');
      }
    }

    .visible.menu.transition {
      border: 1px solid variables.$tempoCharcoal;
      & .item {
        border: none;
        background: none !important;

        span {
          background: none !important;
          color: variables.$tempoCharcoal !important;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.03) !important;
        }
      }
    }

    .ui.search.dropdown > .text {
      background: none !important;
      font-size: 1.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 18.7px */
      letter-spacing: -0.34px;
      top: 25%;
      left: 24px;
      padding-left: 0;
      color: variables.$tempoCharcoal !important;

      &.default {
        color: #bfbfbf !important;
      }
    }
    .ui.search.selection.dropdown {
      height: 57px;
      max-width: 100%;
      border: 1px solid variables.$tempoCharcoal;
      border-radius: 0% !important;
      padding: 0;

      input {
        height: 57px;
        border: 1px solid variables.$tempoCharcoal;
        border-radius: 0% !important;
        left: 0;
        padding-left: 24px !important;

        &[aria-invalid='true'],
        &[aria-invalid='true']:focus {
          background: none;
        }
      }

      &[aria-invalid='true'] {
        .text.default {
          color: #f1abab !important;
        }

        &:hover {
          border: 1px solid variables.$tempoCharcoal !important;
        }
      }
    }

    .ui.button {
      height: auto;
      width: 100%;
      background: variables.$tempoCharcoal;
      color: #fff;
      padding: 16px 32px;
      gap: 8px;
      font-size: 1.29rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      letter-spacing: -0.72px;
    }
    .ui.search.selection.dropdown[aria-invalid='true'] {
      background: none !important;
      border-color: variables.$tempoCharcoal !important;
    }
  }

  .ui.pointing.above.prompt.label {
    height: 0;
    display: flex;
    gap: 8px;
    margin: 6px 0 0;
    padding: 0 !important;
    white-space: normal;
    background: none !important;
    border: none !important;
    color: #f54d42;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
    &::before {
      content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1IiBmaWxsPSJub25lIj4KPGcgaWQ9IkZyYW1lIDE1MzMyMTI4NDIiPgo8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjE0IiBoZWlnaHQ9IjE0IiByeD0iNyIgc3Ryb2tlPSIjRjU0RDQyIi8+CjxwYXRoIGlkPSJpIiBkPSJNOC4xMTYgMTAuNDRMOC4xMTYgMTEuNjRIN0w3IDEwLjQ0SDguMTE2Wk04LjA1NiAzTDguMDU2IDkuMDcySDcuMDZMNy4wNiAzTDguMDU2IDNaIiBmaWxsPSIjRjU0RDQyIi8+CjwvZz4KPC9zdmc+');
      border: none;
      transform: none;
      position: initial;
      width: auto;
      height: auto;
    }
  }
}
