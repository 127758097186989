@use 'style/mixins';
@use 'style/variables';

.pm-login-sso {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__steps {
    display: flex;
    justify-content: center;
    width: 100%;

    .steps {
      &__back-action {
        a {
          color: variables.$tempoCharcoal;
          font-size: 1.29rem;
          font-style: normal;
          font-weight: bold;
          line-height: 140%; /* 25.2px */
          letter-spacing: -0.72px;
        }
      }

      &__content {
        max-width: 540px;
        min-width: 430px;
        margin: auto;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__signup-cta {
    text-align: center;

    color: variables.$tempoCharcoal;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.28px;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;

    a {
      color: variables.$tempoCharcoal;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.28px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
}
