@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.lp-main {
  align-content: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__container {
    display: flex;
    flex: auto;
    overflow: hidden;
  }

  &__content {
    display: grid;
    grid-auto-rows: min-content min-content 1fr;
    background: variables.$gray100;
  }

  &__organization-local-error {
    z-index: zindex.$zIndexItemPanelModalActionError;
    max-width: 30rem;
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-header {
      margin: 0;
    }
  }

  .lp-vertical-menu {
    margin: 1rem;
    display: block;
    overflow: hidden;
    height: 100%;

    &__tabs {
      &.ui.pointing.vertical.floated {
        background: variables.$slate700;

        .item {
          color: white;

          &:hover {
            background: variables.$slate500;
          }
        }

        .active.item {
          background: variables.$slate600;

          &:hover {
            color: white;
            background: variables.$slate500;
          }

          &:after {
            background: variables.$slate600;
          }
        }

        .active.item {
          &:after {
            background: variables.$slate600;
            zoom: 1.8;
            border: 0;
          }

          &:hover::after {
            background: variables.$slate500;
          }
        }
      }
    }
  }
}
