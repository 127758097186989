@use 'style/mixins';
@use 'style/variables';

$defaultBorder: 1px solid rgba(variables.$tableBorder, 0.15);
$minNameColumnWidth: 500px;
$maxNameColumnWidth: 1000px;
$minNubsColumnWidth: 200px;
$remainingColumnWidth: 80px;
$availabilityUsedColumnWidth: 100px;
$defaultWorkloadHeaderHeight: 40px;

@mixin workloadViewHeaderCell {
  color: variables.$gray700 !important;
  background-color: variables.$gray300;
}

.workload-view {
  margin-top: 0 !important;
  display: grid;
  grid-template-rows: 1fr;
  flex: 1 1 auto;

  &__sticky-elements-container {
    &-sticky-column,
    &-workload-grid {
      top: calc(var(--workload-header-height, $defaultWorkloadHeaderHeight) + 5px);
    }

    &-sticky-column {
      position: sticky;
      left: 0;
      z-index: 1;
      height: 100%;

      .workload-view__section {
        &-header-name,
        &-row-table-cell-name {
          position: absolute;
          border-right: 1px solid variables.$gray300;
        }

        &-row-table-cell-name--no-three-dot {
          padding-right: 0.5rem;
        }
      }
    }
    &-workload-grid {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__header {
    display: inline-flex;
    position: sticky;
    top: 0;
    z-index: 2;

    &-workload-rail-cell {
      @include workloadViewHeaderCell();
      padding: 0.2rem 0.2rem 0.2rem 0;
    }

    &-cell {
      display: flex;
      align-items: center;
      font-weight: normal !important;
      @include workloadViewHeaderCell();
      padding: 0.2rem !important;

      &-days-from-now {
        font-weight: variables.$weightSemiBold !important;
      }

      &-today {
        padding-left: 0.5rem;
      }

      &-toggle {
        color: variables.$gray700;
        margin-right: 0.5rem;
      }

      &:nth-child(1) {
        width: var(--lp-workload-name-column-width, $minNameColumnWidth);
        max-width: $maxNameColumnWidth;
        padding-left: 0.4rem !important;
        position: sticky;
        left: 0;
        border-right: 1px solid variables.$gray400;
      }

      &:nth-child(2) {
        text-align: left !important;
      }

      &:nth-child(3) {
        width: $remainingColumnWidth;
        text-align: center !important;
        padding-left: 0 !important;
        padding-right: 0.2em !important;
      }

      &:nth-child(4) {
        width: $availabilityUsedColumnWidth;
        text-align: center !important;
        padding-right: 12px !important;
      }
    }
  }

  &__section {
    &-header,
    &-row {
      &-nub {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-nubs {
        display: flex;
        align-items: center;
      }

      &-availability,
      &-remaining {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-header {
      &-name,
      &-nubs,
      &-nub,
      &-remaining,
      &-availability {
        background-color: variables.$gray200;
        font-weight: variables.$weightSemiBold !important;
      }

      &-toggle-area {
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-grow: 2;
        gap: 0.3rem;

        &-link {
          display: flex;
          align-items: center;
        }
      }

      &-name {
        display: flex;
        align-items: center;
      }

      &-name-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 100%;
      }

      .lp-toggle-button {
        padding-left: 0.5rem;

        svg {
          font-size: 1.2rem !important;
          margin: 0 mixins.calculateRemDeprecated(4px);
        }
      }
    }

    &-row {
      &-table-cell {
        &-remaining,
        &-availability {
          justify-content: center;
        }
        &-name,
        &-nubs,
        &-remaining,
        &-availability {
          display: flex;
          align-items: center;
          background-color: variables.$lpBrandWhite;
          transition: background-color 200ms ease-in-out;

          @include mixins.workloadViewRowHover();
        }

        &-name-text {
          @include mixins.overflowEllipsis;
          display: flex;
          flex-grow: 2;

          &-link {
            margin-right: 0.3rem;
          }
        }
      }
      &-nub {
        background-color: variables.$lpBrandWhite;
      }
    }
  }

  &__average-availability {
    color: variables.$blue300;
    font-weight: variables.$weightRegular;

    &-paren {
      color: variables.$gray700;
    }
  }

  &__task-status-icon {
    display: flex;
    margin-left: 2.5rem;
    margin-right: 1.3rem;
  }
}
