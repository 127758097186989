@use 'style/mixins';
@use 'style/variables';

.organization-picker {
  .ui.celled.table {
    height: min-content;
  }

  &__table-scroll {
    display: flex;
    overflow: auto;
    height: 100%;
    max-height: 20rem;

    th {
      @include mixins.stickyHeader;
    }
  }

  &__links {
    cursor: pointer;
    margin-left: 0.5rem;
  }
}
