@use 'style/mixins';
@use 'style/variables';

.status-options-list {
  &__header {
    display: flex;
    text-align: center;
    margin-bottom: 0.5rem;
    align-items: center;
  }

  &__header-name {
    font-size: 1.3rem;
    @include mixins.textWeightBold();
  }

  &__header-filled-by {
    margin-left: 0.8rem;
    font-size: 0.8rem;
    @include mixins.helpTextColorAndFontStyle();
  }

  &__scroll-container {
    width: 100%;
    height: 26rem;
    overflow: auto;
    display: inline-block;
    border: 1px solid variables.$gray500;
  }

  &__row {
    background: variables.$lpBrandWhite;
    border-color: variables.$slate700;
    padding: 0 !important;

    &-cell {
      display: flex;
      padding: 0.6rem 0.4rem !important;

      &-name {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    &:last-child {
      border-bottom: 1px solid variables.$gray400;
    }

    .ui.list &-cell-info {
      background: variables.$gray300;
      padding: 0.4rem !important;
      @include mixins.stickyHeader();
      @include mixins.textWeightBold();
    }

    &-cell-checkbox {
      @include mixins.marginRight('sm');
    }

    &-cell-icon {
      @include mixins.marginRight('xs');
    }
  }
}
