@use 'style/variables';

.intake-form {
  &__menu,
  &__body-image,
  &__body-form-section,
  &__body-footer {
    max-width: 1200px;
  }

  padding: 1rem 1rem 7rem;
  display: grid;
  background-color: variables.$lpBrandWhite;
  grid-template-rows: min-content 1fr;
  overflow-y: auto;

  &__menu {
    &.ui.secondary.menu {
      margin-left: 0;
      margin-right: 0;
    }

    &-nav-to-settings {
      &.ui.button {
        margin-right: 0;
      }
    }
  }

  &__body {
    display: grid;
    row-gap: 1rem;
    grid-template-rows: min-content auto min-content;
    align-content: start;
    height: auto;

    &-image {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-height: 8rem;
      padding-bottom: 0.5rem;
      border-bottom: 5px solid variables.$gray500;

      &-section {
        max-height: 7rem;
        align-self: end;
      }

      &-buttons {
        align-self: end;
        justify-self: end;
        display: flex;
        column-gap: 1rem;

        .ui.button {
          margin: 0;
        }
      }
    }

    &-form-section {
      height: 100%;
    }

    &-errors {
      display: flex;
      align-items: center;
      column-gap: 0.3rem;

      &-text {
        color: variables.$red500;
      }
    }

    &-buttons {
      display: flex;
      justify-self: end;
      column-gap: 1rem;

      .ui.button {
        margin: 0;
      }
    }

    &-footer {
      display: grid;
      grid-template-columns: 1fr max-content;
    }
  }
}
