@use 'style/mixins';
@use 'style/variables';

.read-only-panel {
  @include mixins.readonlyNotesScss();

  &--placeholder-text {
    color: variables.$slate300;
    font-style: italic;
  }
}

.note-editing-modal {
  &.ui.modal {
    display: flex !important;
    flex-direction: column;
    height: 85% !important;
    width: 95% !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    max-width: mixins.calculateRemDeprecated(1500px);

    .header {
      .ui.breadcrumb {
        grid-column: 1 / -1;
        padding-left: 0 !important;

        .divider {
          font-weight: 900;
        }

        .section {
          cursor: default !important;
          font-weight: normal;
          color: variables.$slate700 !important;
        }
      }
    }

    .content {
      flex-direction: column;
      height: 100%;
    }
  }

  &-section {
    height: 100%;
    padding-top: 0 !important;

    &--error {
      .tox-tinymce {
        box-shadow: 0 0 0 2px variables.$lpRed, 1px 1px 2px 3px hsla(0, 0%, 0%, 0.25) !important;
        border-color: transparent !important;
      }
    }

    .tox-tinymce {
      margin-top: 0.2rem;
      box-shadow: 0 0 0 2px variables.$relightBlue, 1px 1px 2px 3px hsla(0, 0%, 0%, 0.25) !important;
      border-color: variables.$relightBlue !important;

      .tox-toolbar__group button[aria-label='Fonts'] {
        width: 175px;
        justify-content: flex-start;

        .tox-tbtn__select-label {
          width: unset;
        }
      }
    }
  }

  .actions {
    .ui.button {
      @include mixins.modalActionButtonSpacing(!important);
    }
  }
  &--save-error {
    color: variables.$lpRed;
    padding: 0.25rem;
    min-height: 14px;
  }
}
