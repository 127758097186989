@use 'style/variables';
@use 'style/mixins';

.left-nav-menu {
  &__beta {
    display: flex;
    align-items: center;
    gap: 1rem;
    &-label {
      @include mixins.fontSize(10px);
      @include mixins.textWeightSemiBold();
      color: variables.$slate800;
      background: variables.$lpBrandWhite;
      border-radius: 2px;
      padding: 0.25rem;
    }
  }
}
