@use 'style/mixins';
@use 'style/variables';

.clickable-text {
  @include mixins.overflowEllipsis;
  display: inline;
  cursor: pointer;
  margin-bottom: 0;

  &--blue {
    color: variables.$blue400;
  }
}
