@use 'style/mixins';
@use 'style/variables';

.organization-directory__users {
  @include mixins.mainContentStyle;
  overflow-x: hidden;
  margin: 1rem;

  tr:hover {
    @include mixins.tableRowHoverStyles;
  }

  .admin-icon {
    @include mixins.marginRight('xs');
    color: variables.$teal600;
  }

  &-grid {
    overflow: hidden;
    display: grid;
    grid-template-areas: '. sidebar' '. sidebar' 'users-table sidebar';
    grid-template-columns: minmax(0, 1fr) minmax(min-content, max-content);
    grid-template-rows: auto auto 1fr;
    column-gap: 1rem;
    height: 100%;
  }
}
