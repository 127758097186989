@use 'style/variables';

.lp-loader {
  &__fade-in {
    animation: fadeIn 500ms;
  }
}

.async-download-loader.ui.loader {
  background: variables.$lpBrandWhite;
  border-radius: 0.25rem;
  border: 1px solid variables.$gray400;
  padding: 4rem 2rem 2rem 2rem !important;
  &:before,
  &:after {
    margin-top: 2rem !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
