@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

.intake_file_section {
  display: grid;
  grid-template-rows: 5rem 1fr;
  min-height: 5rem;

  .filepond--wrapper {
    z-index: zindex.$zIndexFocus;
  }

  &__upload {
    width: 300px;
    height: 100%;

    &--error {
      .filepond--wrapper {
        border: 1px dashed variables.$red500;

        .filepond--drop-label {
          background: variables.$red50;
        }
      }
    }
  }

  &__files-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;

    &--has-files {
      margin-top: 1rem;
    }

    &-file {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;

      &-name {
        border-radius: 0.3rem;
        background: variables.$gray300;
        padding: 0.3rem 0.5rem;
      }
    }
  }
}
