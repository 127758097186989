@use 'style/variables';

.lp-breadcrumb.ui.breadcrumb {
  .divider {
    color: variables.$gray700;
    font-weight: variables.$weightBold;
  }
  .section:not(a) {
    color: variables.$gray700;
  }
}
