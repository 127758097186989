@use 'style/variables';

.lp-progress-bar {
  &.ui.progress,
  &.ui.tiny.progress,
  &.ui.small.progress,
  &.ui.medium.progress,
  &.ui.large.progress {
    background-color: variables.$gray300;
  }

  &.ui.progress {
    border-radius: 2rem;

    .bar {
      background-color: variables.$orange500;
      border-radius: 2rem;

      .progress {
        color: variables.$lpBrandWhite;
        font-size: 1rem;
        font-weight: 400;
      }
    }
    &.high .bar {
      background-color: variables.$green500;
    }
  }

  &--academy.ui.progress {
    background-color: variables.$gray100 !important;

    .bar {
      background-color: variables.$orange500;
    }
    &.high .bar {
      background-color: variables.$slate700;
    }
  }

  &--manage-account-plan.ui.progress {
    .bar {
      background-color: variables.$slate600;
    }
    &.high .bar {
      background-color: variables.$orange500;
    }
  }

  &--inverted.ui.progress {
    .bar {
      background-color: variables.$green500;
    }
    &.high .bar {
      background-color: variables.$orange500;
    }
  }
}
