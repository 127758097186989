.widget-loading {
  background-color: white;
  grid-column: span 2;
  padding: 1rem;
  border-radius: .5rem;
  min-height: 31.25rem;

  .header {
    display: flex;
    justify-content: space-between;

    .icons {
      display: flex;
      gap: 0.625rem;
    }
  }

  .body {
    height: 100%;
    position: relative;
    padding-bottom: 3rem;
  }

  .skeleton-title {
    height: 1.3rem;
    padding-left: 75%;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }

  .skeleton-icon {
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
  }

  .skeleton-body {
    width: 100%;
    height: 100%;
    margin-top: 0.6rem;
    border-radius: 0.25rem;
  }

  &.half {
    grid-column: span 1;
  }

  &.schedule, &.dashboardNote, &.linkedNote {
    min-height: 9.56rem;
  }

  &.workload {
    min-height: 15.25rem;
  }

  &.taskStatusDistribution {
    min-height: 11.125rem;
  }

  &.insights, &.list {
    min-height: 16.625rem;
  }

  &.intake {
    min-height: 5.3125rem;
  }

  &.properties, &.metricsTally {
    min-height: 12.625rem;
  }

}
